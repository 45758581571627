import { useEffect } from 'react';
import { ExploreCoordinate } from '../hooks/useExplore';
import useMoveMap from '../hooks/useMoveMap';

type MapControlProps = {
    coordinates: ExploreCoordinate;
};

const MapControl = ({ coordinates }: MapControlProps) => {
    const moveMap = useMoveMap();

    useEffect(() => {
        moveMap(
            coordinates.latitude,
            coordinates.longitude,
            (coordinates.radius * 14) / 45
        );
    }, [coordinates]);

    return null;
};

export default MapControl;
