import React, { useState } from 'react';
import ContractPrekSetting from '../../interfaces/ContractPrekSetting';
import PrekFormContext from '../../utils/rjsf/PrekFormContext';
import PrekSchemaQuestionSelector from '../../utils/rjsf/PrekSchemaQuestionSelector';
import useSubmitContractPrekSettings from './useSubmitContractPrekSettings';
import ModuleSchema from '../../interfaces/ModuleSchema';
import styled from 'styled-components';
import { SaveButton } from 'react-admin';

const FlexContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    margin: 2em 0;
`;

type ContractPrekSettingsFormProps = {
    contractPrekSettings: ContractPrekSetting;
    moduleSchemas: ModuleSchema[];
};

const ContractPrekSettingsForm = ({
    contractPrekSettings,
    moduleSchemas,
}: ContractPrekSettingsFormProps): React.ReactElement => {
    const [recordValue, setRecordValue] = useState<Record<string, boolean>>(
        contractPrekSettings.value
    );

    const { submit, submitting } = useSubmitContractPrekSettings(
        contractPrekSettings?.id
    );

    const onClick = () => {
        submit(recordValue);
    };

    const initialValue = {
        recordValue: recordValue,
        setRecordValue: setRecordValue,
    };

    return (
        <FlexContainer>
            <PrekFormContext.Provider value={initialValue}>
                {moduleSchemas.map((moduleSchema) => {
                    return (
                        <PrekSchemaQuestionSelector
                            key={moduleSchema.schema.$id}
                            schema={moduleSchema.schema}
                            uiSchema={moduleSchema.uiSchema}
                        />
                    );
                })}
                <ButtonContainer>
                    <SaveButton
                        disabled={submitting}
                        saving={submitting}
                        handleSubmitWithRedirect={onClick}
                    />
                </ButtonContainer>
            </PrekFormContext.Provider>
        </FlexContainer>
    );
};

export default ContractPrekSettingsForm;
