import React, { useEffect, useState } from 'react';
import { useQuery, useNotify } from 'react-admin';
import PrequalificationAnalyseRepresentation from '../representations/PrequalificationAnalyseRepresentation';
import AnalysisKey from '../enums/AnalysisKey';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { analysisMessages } from '../constants/analysis';

type AnalysisDetailProps = {
    open?: boolean;
    prequalificationId: string;
    expectedValue: number;
    keys: AnalysisKey[];
};

const useStyles = makeStyles({
    container: {
        width: '300px',
        padding: '10px',
    },

    list: {
        paddingLeft: '20px',
        margin: '5px 0',
        width: '100%',
        fontSize: '12px',
    },
});

const AnalysisDetail = ({
    prequalificationId,
    open,
    expectedValue = 1,
    keys,
}: AnalysisDetailProps) => {
    const [analysis, setAnalysis] = useState<
        PrequalificationAnalyseRepresentation[]
    >([]);
    const notify = useNotify();
    const styles = useStyles();
    const { data, loading, error } = useQuery({
        type: 'getPrequalificationsAnalysis',
        payload: { prequalificationId },
    });

    if (error) {
        notify('Error : getPrequalificationsAnalysis failed', 'warning');
    }

    useEffect(() => {
        if (!data) {
            return;
        }

        if (!open) {
            return;
        }

        setAnalysis(
            data.filter(
                (analyse: PrequalificationAnalyseRepresentation) =>
                    keys.includes(analyse.ruleKey) &&
                    analyse.value === expectedValue
            )
        );
    }, [data, expectedValue, keys, open]);

    if (loading) {
        return (
            <div className={styles.container}>
                <LinearProgress variant="query" />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <ul className={styles.list}>
                {analysis.map((analyse) => (
                    <li key={analyse.id}>
                        {analysisMessages[analyse.ruleKey]}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AnalysisDetail;
