import React from 'react';
import { Tab, TabProps } from 'react-admin';
import ProjectEdit from '../ProjectEdit';

const ProjectSummaryTab = (props: typeof TabProps) => {
    const { hasCreate, hasEdit, hasShow, hasList, ...rest } = props;

    return (
        <Tab label="Summary" {...rest}>
            <ProjectEdit {...rest} />
        </Tab>
    );
};

export default ProjectSummaryTab;
