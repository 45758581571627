import { Box, styled } from '@material-ui/core';
import React from 'react';
import { required, SelectInput, TextInput } from 'react-admin';
import SiteStatus from './enums/SiteStatus';
import PhoneNumberInput from '../ui/atoms/inputs/PhoneNumberInput';
import validatePhoneNumber from '../utils/validatePhoneNumber';

type SiteFieldProps = {
    disabled?: boolean;
    record?: any;
};

const Container = styled(Box)(() => ({
    '&': {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const statusChoices = [
    {
        id: SiteStatus.CLOSED,
        name: SiteStatus.CLOSED,
    },
    {
        id: SiteStatus.ACTIVE,
        name: SiteStatus.ACTIVE,
    },
    {
        id: SiteStatus.INACTIVE,
        name: SiteStatus.INACTIVE,
    },
];

const isHeadquarterChoices = [
    {
        id: false,
        name: 'No',
    },
    {
        id: true,
        name: 'Yes',
    },
];

const SiteFields = ({ disabled }: SiteFieldProps): React.ReactElement => {
    return (
        <Container>
            <TextInput
                source="street"
                label="Street"
                validate={required()}
                disabled={disabled}
            />
            <TextInput
                source="postalCode"
                label="Postal code"
                validate={required()}
                disabled={disabled}
            />
            <TextInput
                source="city"
                label="City"
                validate={required()}
                disabled={disabled}
            />
            <TextInput
                source="registrationCountry.code"
                label="RegistrationCountry"
                disabled
            />
            <PhoneNumberInput
                source="phone"
                label="Phone number"
                disabled={disabled}
                validate={validatePhoneNumber}
            />
            <SelectInput
                source="status"
                label="Status"
                validate={required()}
                disabled={disabled}
                choices={statusChoices}
            />
            <SelectInput
                source="isHeadquarter"
                label="Is headquarter"
                disabled
                choices={isHeadquarterChoices}
            />
        </Container>
    );
};

export default SiteFields;
