import React, { useMemo } from 'react';
import ProjectParticipationItem from '../../../interfaces/ProjectParticipationItem';
import { useQueryWithStore } from 'react-admin';
import { OrderedBundle } from '../bundles/BundleAutoComplete';
import {
    Chip,
    createStyles,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Theme,
} from '@material-ui/core';
import ParticipationListByBundle from './ParticipationListByBundle';
import { makeStyles } from '@material-ui/core/styles';
import { useParticipationContext } from './ParticipationProvider';

type ContractorParticipationListProps = {
    participations: ProjectParticipationItem[];
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        avatar: {
            minWidth: 0,
            marginRight: '5px',
        },
        bundle: {
            paddingLeft: 0,
        },
    })
);

const ContractorParticipationList = ({
    participations,
}: ContractorParticipationListProps) => {
    const classes = useStyles();

    const { projectId } = useParticipationContext();
    const { loaded, data } = useQueryWithStore({
        type: 'getList',
        resource: `projects/${projectId}/bundles`,
    });

    const orderedBundles = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.sort((a: OrderedBundle, b: OrderedBundle) =>
            a.order > b.order ? 1 : -1
        );
    }, [data]);

    if (!loaded) {
        return <div>loading</div>;
    }

    return (
        <div className={classes.root}>
            {orderedBundles.map((orderedBundle: OrderedBundle) => (
                <List
                    key={orderedBundle.id}
                    component="div"
                    className={classes.root}
                    disablePadding
                    subheader={
                        <ListSubheader component="div">
                            <ListItem className={classes.bundle}>
                                <ListItemIcon className={classes.avatar}>
                                    <Chip label={orderedBundle.order} />
                                </ListItemIcon>
                                <ListItemText primary={orderedBundle.name} />
                            </ListItem>
                        </ListSubheader>
                    }
                >
                    <ParticipationListByBundle
                        participations={participations}
                        bundle={orderedBundle}
                    />
                </List>
            ))}
        </div>
    );
};

export default ContractorParticipationList;
