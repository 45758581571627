import { Visibility } from '@material-ui/icons';
import React from 'react';
import {
    useRecordContext,
    Button,
    useNotify,
    useRedirect,
    useRefresh,
} from 'react-admin';
import EditableShow, { EditableShowProps } from '../../components/EditableShow';
import { ResourceName } from '../../ResourceName';
import SiteFields from '../SiteFields';

const SiteEdit = (props: EditableShowProps) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect(`/${ResourceName.SITES}/${props.id}/show`);
        refresh();
    };

    const transformRecord = (record: Record<string, any>) => ({
        ...record,
        company: undefined,
        references: undefined,
        mainReference: undefined,
        registrationCountry: undefined,
    });

    const { company } = useRecordContext();

    const parentReference = {
        url: `/${ResourceName.COMPANIES}/${company.id}/show`,
        label: `${company.mainReference.value} - ${company.name}`,
    };

    return (
        <EditableShow
            {...props}
            transform={transformRecord}
            onSuccess={onSuccess}
            additionalToolbarButtons={
                <Button
                    startIcon={<Visibility />}
                    variant="text"
                    onClick={() => redirect(parentReference.url)}
                    label={parentReference.label}
                />
            }
        >
            <SiteFields />
        </EditableShow>
    );
};

export default SiteEdit;
