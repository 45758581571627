import React, { createContext, useContext } from 'react';

type ParticipationContextType = {
    projectId: string;
    refresh: () => Promise<void>;
};

const ParticipationContext = createContext<ParticipationContextType>({
    projectId: '',
    refresh: () => Promise.resolve(),
});

type ParticipationProviderProps = ParticipationContextType & {
    children: React.ReactElement;
};

const ParticipationProvider = ({
    children,
    refresh,
    projectId,
}: ParticipationProviderProps) => {
    return (
        <ParticipationContext.Provider
            value={{
                refresh,
                projectId,
            }}
        >
            {children}
        </ParticipationContext.Provider>
    );
};

export const useParticipationContext = (): ParticipationContextType =>
    useContext(ParticipationContext);

export default ParticipationProvider;
