import React from 'react';
import { Tab, TabProps, ShowProps, TextInput, TextField } from 'react-admin';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SubResource from '../../utils/SubResource';

const InvitationContent = ({ resource, record }: typeof TabProps) => (
    <SubResource
        target="participation-invitations"
        resource={resource}
        record={record}
        filters={[
            <TextInput label="Name or reference" source="search" alwaysOn />,
        ]}
    >
        <TextField
            sortable={false}
            source="invitedSite.company.name"
            label="Name"
        />
        <TextField
            sortable={false}
            source="invitedSite.mainReference.value"
            label="Reference"
        />
        <TextField sortable={false} source="email" label="Email" />
        <TextField sortable={false} source="role" label="Role" />
        <TextField sortable={false} source="bundle.name" label="Bundle" />
    </SubResource>
);

const ParticipationInvitationTab = (props: typeof ShowProps) => {
    return (
        <Tab
            label="Invitations"
            path="invitations"
            icon={<GroupAddIcon />}
            {...props}
        >
            <InvitationContent
                resource={props.resource}
                record={props.record}
            />
        </Tab>
    );
};

export default ParticipationInvitationTab;
