import React from 'react';
import { MapContainer as DefaultMapContainer, TileLayer } from 'react-leaflet';
import { styled } from '@material-ui/core';
import MapMarkers from './MapMarkers';
import { ExploreCoordinate, ExploreProject } from '../hooks/useExplore';
import MapControl from './MapControl';
import MapEvents from './MapEvents';
import { MAX_ZOOM } from '../hooks/useMoveMap';
import theme, { breakpoint } from '../../../themes/utils/theme';

const MapContainer = styled(DefaultMapContainer)({
    width: '100%',
    height: '300px',

    [`@media (min-width:${breakpoint.medium}px)`]: {
        height: '100%',
    },

    '& .leaflet-popup-content-wrapper': {
        borderRadius: theme.radius,
    },

    '& .leaflet-popup-content': {
        height: 'fit-content',
        width: '322px !important',
        padding: '16px',
        margin: 0,
    },
});

type MapProps = {
    results: ExploreProject[];
    coordinates: ExploreCoordinate;
    onChange: (coordinates: ExploreCoordinate) => void;
};

const Map = ({ results, coordinates, onChange }: MapProps) => {
    return (
        <MapContainer
            center={[coordinates.latitude, coordinates.longitude]}
            zoom={13}
            maxZoom={MAX_ZOOM}
            scrollWheelZoom={false}
            preferCanvas
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MapMarkers results={results} />
            <MapControl coordinates={coordinates} />
            <MapEvents onChange={onChange} />
        </MapContainer>
    );
};

export default Map;
