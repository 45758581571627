import React from 'react';
import { FieldProps, useRecordContext } from 'react-admin';
import { LinearProgress } from '@material-ui/core';
import NotCalculatedYet from './NotCalculatedYet';
import { Li, Ul } from './style';

const PreprocessNameField: React.FC<typeof FieldProps> = (props) => {
    const record = useRecordContext(props);

    const value = record[props.source];

    if (props.isLoading) {
        return <LinearProgress />;
    }

    if (!value) {
        return <NotCalculatedYet />;
    }

    return (
        <Ul>
            {value.map((entry: string) => (
                <Li key={entry}>{entry}</Li>
            ))}
        </Ul>
    );
};

export default PreprocessNameField;
