import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import React from 'react';

const Tooltip = ({ title, children }: TooltipProps) => (
    <MuiTooltip title={title} disableFocusListener interactive>
        {children}
    </MuiTooltip>
);

export default Tooltip;
