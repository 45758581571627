import React from 'react';
import { TabProps, TextField, Tab, TextInput } from 'react-admin';
import SubResource from '../../utils/SubResource';
import ReferenceUpdate from '../../references/ReferenceUpdate';
import ReferenceInput from '../../references/ReferenceInput';

const CompanyReferencesTab = ({
    ...props
}: typeof TabProps): React.ReactElement => {
    const { resource, record } = props;

    return (
        <Tab {...props} label="References">
            <SubResource
                target="references"
                name="References"
                resource={resource}
                record={record}
                deleteButton
                filters={[
                    <TextInput
                        label="Value"
                        source="value"
                        size="small"
                        alwaysOn
                        resettable
                    />,
                ]}
                addInput={
                    <ReferenceInput
                        registrationCountryId={record?.registrationCountry.id}
                        resourceId={record?.id}
                        resourceType="company"
                    />
                }
            >
                <TextField sortable={false} source="id" label="Id" />
                <TextField sortable={false} source="value" label="Value" />
                <TextField sortable={false} source="type" label="Type" />
                <ReferenceUpdate
                    registrationCountryId={record?.registrationCountry?.id}
                    resourceType="company"
                />
            </SubResource>
        </Tab>
    );
};

export default CompanyReferencesTab;
