import React from 'react';
import { ExploreProject } from './hooks/useExplore';
import { FixedSizeList } from 'react-window';
import { styled } from '@material-ui/core';
import DefaultProjectCard from './ProjectCard';
import theme, { breakpoint } from '../../themes/utils/theme';
import useWindowSize from '../../utils/hooks/useWindowSize';

const CELL_SIZE = 250;
const CARD_SIZE = 220;
const BEFORE_LIST_HEIGHT_SIZE = 312;
const MOBILE_MAP_SIZE = 300;

const H3 = styled('h3')(() => ({
    width: '100%',
    padding: 0,
    textAlign: 'left',
}));

const CardsContainer = styled('div')(({ loading }: { loading: boolean }) => ({
    width: '100%',
    opacity: loading ? 0.3 : 1,
    height: '100%',
    transition: `opacity ${theme.transition} linear`,
    cursor: loading ? 'not-allowed' : 'pointer',

    '&.loading': {
        pointerEvents: 'none',
    },
}));

const ProjectCard = styled(DefaultProjectCard)(() => ({
    width: '322px',
    padding: '16px',
    border: `1px solid ${theme.colors.grayer}`,
    cursor: 'pointer',
    transition: `box-shadow ease ${theme.transition}`,
    top: 0,
    position: 'relative',
    height: `${CARD_SIZE}px`,

    '&:hover': {
        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.75)',
    },
}));

const ProjectCardWrapper = styled('div')(({ style }: any) => ({
    ...style,
    overflow: 'auto',
}));

type ListProps = {
    results: ExploreProject[];
    loading: boolean;
};

const List = ({ results, loading }: ListProps) => {
    const windowSize = useWindowSize();

    const onClick = (project: ExploreProject) => () => {
        if (project.ref.current) {
            (project.ref.current as any).selectedOnList?.();
        }
    };

    return (
        <>
            <H3>
                {`${results.length}  ${
                    results.length <= 1 ? 'result' : 'results'
                }`}
            </H3>
            <CardsContainer
                loading={loading}
                className={loading ? 'loading' : ''}
            >
                <FixedSizeList
                    height={
                        windowSize.height -
                        (BEFORE_LIST_HEIGHT_SIZE +
                            (windowSize.width < breakpoint.medium
                                ? MOBILE_MAP_SIZE
                                : 0))
                    }
                    width={322}
                    itemSize={CELL_SIZE}
                    itemCount={results.length}
                    overscanCount={4}
                    itemData={results}
                >
                    {({ data, index, style }) => {
                        const item = data[index];

                        return (
                            <ProjectCardWrapper style={style}>
                                <ProjectCard
                                    key={item.exploreId}
                                    data={item}
                                    onClick={loading ? () => {} : onClick(item)}
                                ></ProjectCard>
                            </ProjectCardWrapper>
                        );
                    }}
                </FixedSizeList>
            </CardsContainer>
        </>
    );
};

export default List;
