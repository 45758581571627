type Point = {
    lat: number;
    lon?: number;
};

const distanceBetweensTwoPoints = (point1: Point, point2: Point) => {
    if (point1.lat === point2.lat && point1.lon === point2.lon) return 0;

    const radlat1 = (Math.PI * point1.lat) / 180;
    const radlat2 = (Math.PI * point2.lat) / 180;
    const theta = point1.lat - point2.lat;
    const radtheta = (Math.PI * theta) / 180;

    let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) {
        dist = 1;
    }

    return ((Math.acos(dist) * 180) / Math.PI) * 60 * 1.1515 * 1.609344;
};

export default distanceBetweensTwoPoints;
