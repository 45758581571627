import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    BooleanField,
    TextField,
    ReferenceField,
    TabbedShowLayoutProps,
} from 'react-admin';
import ContractTitle from './ContractTitle';
import ContractSites from './ContractSites';
import { ResourceName } from '../ResourceName';
import ContractProjects from './ContractProjects';
import ContractType from '../interfaces/ContractType';
import ContractPrekSettings from './contractPrekSettings/ContractPrekSettings';

const ShowContent = (props: typeof TabbedShowLayoutProps) => {
    const shouldDisplayPrekSettings =
        props.record?.type === ContractType.PREQUALIFICATION &&
        props.record?.apiJson === true;

    return (
        <TabbedShowLayout {...props}>
            <Tab label="Summary">
                <TextField disabled label="Id" source="id" />
                <TextField source="name" />
                <TextField source="type" />
                <ReferenceField
                    source="sourceCompanyId"
                    reference={ResourceName.COMPANIES}
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                    label="Referent user"
                    source="referentId"
                    reference={ResourceName.USERS}
                >
                    <TextField source="email" />
                </ReferenceField>
                <BooleanField source="isActive" />
                <TextField source="sso" />
                <BooleanField source="prequalificationUpdate" />
                <BooleanField source="carbonPath" />
                <BooleanField source="apiJson" />
                <BooleanField source="apiHtml" />
                <BooleanField source="apiPdf" />
            </Tab>
            <Tab label="Sites">
                <ContractSites />
            </Tab>
            {props.record?.type === ContractType.TOTEM ? (
                <Tab label="Projects">
                    <ContractProjects />
                </Tab>
            ) : null}
            {shouldDisplayPrekSettings ? (
                <Tab label="Prequalification Settings">
                    <ContractPrekSettings />
                </Tab>
            ) : null}
        </TabbedShowLayout>
    );
};

const ContractShow: React.FC = (props) => (
    <Show title={<ContractTitle {...props} />} {...props}>
        <ShowContent />
    </Show>
);

export default ContractShow;
