import React, { ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

export type TimeSlotValue = {
    from: string;
    to: string;
};

export const generateEmptyTimeSlotValue = (): TimeSlotValue => ({
    from: '',
    to: '',
});

type WorkingTimeSlotProps = {
    timeSlot: TimeSlotValue;
    onChange: (timeSlot: TimeSlotValue) => void;
    onDelete: () => void;
    disabled?: boolean;
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',
    },
});

const WorkingTimeSlot = ({
    timeSlot,
    onChange,
    onDelete,
    disabled,
}: WorkingTimeSlotProps) => {
    const classes = useStyles();

    const onFromChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
            from: e.target.value,
            to: timeSlot.to,
        });
    };
    const onToChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
            from: timeSlot.from,
            to: e.target.value,
        });
    };

    return (
        <div className={classes.container}>
            <TextField
                id="from"
                label="From"
                type="time"
                value={timeSlot.from}
                onChange={onFromChange}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={disabled}
            />
            <TextField
                id="to"
                label="To"
                type="time"
                value={timeSlot.to}
                onChange={onToChange}
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={disabled}
            />
            <IconButton
                aria-label="delete"
                onClick={onDelete}
                size="small"
                disabled={disabled}
            >
                <DeleteIcon />
            </IconButton>
        </div>
    );
};

export default WorkingTimeSlot;
