import ProjectParticipationItem from '../../../interfaces/ProjectParticipationItem';
import { useMemo } from 'react';
import ProjectParticipationRole, {
    orderedRoles,
} from '../../../interfaces/ProjectParticipationRole';

export type ParticipationByRole = Array<{
    role: ProjectParticipationRole;
    participations: ProjectParticipationItem[];
}>;

const useParticipationByRole = (
    data: ProjectParticipationItem[]
): ParticipationByRole =>
    useMemo(() => {
        const listByRole: ParticipationByRole = orderedRoles.map((role) => ({
            participations: [],
            role,
        }));

        data.forEach((participation) => {
            const indexed = listByRole.find(
                (p) => p.role === participation.role
            );
            if (!indexed) {
                throw new Error(`Role ${participation.role} not found`);
            }
            indexed.participations.push(participation);
        });

        return listByRole;
    }, [data]);

export default useParticipationByRole;
