export enum ProjectStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
}

export enum NatureOfWork {
    NEW_CONSTRUCTION = 'NEW_CONSTRUCTION',
    REHABILITATION = 'REHABILITATION',
    DEMOLITION = 'DEMOLITION',
}

export enum NatureOfMarket {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
}

interface Project {
    id: string;
    name: string;
    description: string;
    street: string;
    postalCode: string;
    poBox: string;
    businessPostCode: string;
    city: string;
    countryCode: string;
    latitude: string;
    longitude: string;
    picture?: {
        rawFile?: File;
    };
    natureOfMarket: NatureOfMarket; // back boolean
    natureOfWork: NatureOfWork;
    workDestination: string[];
    surface: number;
    DOC: string;
    DOS: string;
    estimatedDeliveryDate: string;
    estimatedWorkforce: number;
    amountHT: number;
    amountTTC: number;
    projectBuildingTags?: string[];
    projectSiteTags?: string[];
    bimAtDesign: boolean;
    bimAtExecution: boolean;
    firstName: string;
    lastName: string;
    operatingDaysAndHour?: string;
    email: string;
    phone: string;
    exploreId?: string;
}

export default Project;
