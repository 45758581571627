import React from 'react';
import { Datagrid, TextField, ReferenceField, DateField } from 'react-admin';
import { ResourceName } from '../../ResourceName';
import List from '../../ui/atoms/List';

interface Props {
    record?: { [k: string]: any };
}

const InvitationReminded: React.FC<Props> = ({ record }) => {
    const reminded = record?.remindedAt !== null;

    return <p> {reminded ? 'reminded' : 'not reminded'} </p>;
};

const InvitationList: React.FC = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <DateField source="remindedAt" />
            <TextField source="prequalificationType" />
            <ReferenceField
                source="senderId"
                reference={ResourceName.USERS}
                link="show"
            >
                <TextField source="username" />
            </ReferenceField>
            <ReferenceField
                source="invitingSiteId"
                reference={ResourceName.SITES}
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                source="invitedSiteId"
                reference={ResourceName.SITES}
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <InvitationReminded {...props} />
        </Datagrid>
    </List>
);

export default InvitationList;
