import React from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexGrow: 0,
        alignItems: 'center',
    },
});

const CompanyReferenceInput = (
    props: any & { onCreate: () => void; isNew: boolean }
) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ReferenceInput
                filterToQuery={(search: string) => ({ search })}
                {...props}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        </div>
    );
};

export default CompanyReferenceInput;
