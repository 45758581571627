import SiteCreate from './SiteCreate';
import SiteIcon from '@material-ui/icons/BusinessTwoTone';
import SiteList from './SiteList';
import SiteShow from './SiteShow';

export default {
    list: SiteList,
    create: SiteCreate,
    icon: SiteIcon,
    show: SiteShow,
};
