import React from 'react';
import { Edit, TextInput, BooleanInput, SimpleForm } from 'react-admin';
import ContractTitle from './ContractTitle';
import SelectContractTypeInput from './SelectContractTypeInput';
import SourceCompanyInput from './SourceCompanyInput';
import AutocompleteUserByEmail from '../utils/AutocompleteUserByEmail';

const ContractEdit: React.FC = (props) => (
    <Edit title={<ContractTitle {...props} />} {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" fullWidth />
            <TextInput source="name" fullWidth />
            <SelectContractTypeInput disabled fullWidth />
            <SourceCompanyInput
                label="Source organisation"
                disabled
                fullWidth
            />
            <BooleanInput source="isActive" fullWidth />
            <AutocompleteUserByEmail
                source="referentId"
                label="Référent"
                fullWidth
            />
            <TextInput source="sso" fullWidth />
            <BooleanInput source="prequalificationUpdate" fullWidth />
            <BooleanInput source="carbonPath" fullWidth />
            <BooleanInput source="apiJson" fullWidth />
            <BooleanInput source="apiHtml" fullWidth />
            <BooleanInput source="apiPdf" fullWidth />
        </SimpleForm>
    </Edit>
);

export default ContractEdit;
