import React from 'react';
import { Filter, TextInput } from 'react-admin';

const Search = (props: any) => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn resettable />
    </Filter>
);

export default Search;
