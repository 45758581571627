import React from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    TextInput,
    SelectInput,
} from 'react-admin';
import List from '../ui/atoms/List';
import { makeStyles } from '@material-ui/core/styles';
import StatusField from '../ui/atoms/fields/organisations/StatusField';
import PartnershipsListActionsButton from './components/PartnershipsListActionsButton';
import { PartnershipStatus } from './enums/PartnershipStatus';
import PartnershipTypes from './enums/PartnershipTypes';

const useStyles = makeStyles({
    bold: { fontWeight: 'bold' },
});

const emptyText = '-';

const partnershipStatusChoices = Object.values(PartnershipStatus).map(
    (type) => ({
        id: type,
        name: type,
    })
);

const partnershipTypesChoices = Object.values(PartnershipTypes).map((type) => ({
    id: type,
    name: type,
}));

const transform = (data: any) => ({
    ...data,
    provider: data.provider,
});

const PrequalificationList: React.FC = (props) => {
    const classes = useStyles();

    return (
        <List
            filters={[
                <TextInput
                    label="Search initiator"
                    source="initiator"
                    alwaysOn
                    resettable
                />,
                <TextInput
                    label="Search provider"
                    source="provider"
                    alwaysOn
                    resettable
                />,
                <SelectInput
                    source="status"
                    label="Status"
                    resettable
                    alwaysOn
                    choices={partnershipStatusChoices}
                />,
                <SelectInput
                    source="type"
                    label="Role"
                    resettable
                    alwaysOn
                    choices={partnershipTypesChoices}
                />,
            ]}
            transform={transform}
            {...props}
        >
            <Datagrid optimized>
                <TextField
                    source="initiator.reference"
                    label="Initiator reference"
                    sortable={false}
                />
                <TextField
                    source="initiator.name"
                    label="Initiator name"
                    className={classes.bold}
                    sortable={false}
                />
                <StatusField
                    source="initiator.status"
                    label="Initiator status"
                    sortable={false}
                />
                <TextField
                    source="provider.reference"
                    label="Provider reference"
                    sortable={false}
                />
                <TextField
                    source="provider.name"
                    label="Provider name"
                    className={classes.bold}
                    sortable={false}
                />
                <StatusField
                    source="provider.status"
                    label="Provider status"
                    sortable={false}
                />
                <TextField source="type" label="Role" sortable={false} />
                <DateField
                    source="createdAt"
                    emptyText={emptyText}
                    label="Created date"
                    sortable={false}
                />
                <DateField
                    source="updatedAt"
                    emptyText={emptyText}
                    label="Updated date"
                    sortable={false}
                />
                <TextField source="status" label="Status" sortable={false} />
                <PartnershipsListActionsButton label="Actions" />
            </Datagrid>
        </List>
    );
};

export default PrequalificationList;
