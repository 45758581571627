import React from 'react';
import { List as RAList } from 'react-admin';
import WidePagination from '../../utils/WidePagination';
import OverflowX from '../icons/OverflowX';

const PER_PAGE = 25;

const List = (props: any) => (
    <RAList
        {...props}
        exporter={false}
        bulkActionButtons={false}
        perPage={PER_PAGE}
        pagination={<WidePagination />}
    >
        <OverflowX>{props.children}</OverflowX>
    </RAList>
);

export default List;
