import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import {
    Toolbar as DefaultToolbar,
    required,
    useRedirect,
    useNotify,
    TextInput,
    SimpleForm,
    Edit,
    SaveButton,
} from 'react-admin';
import { ResourceName } from '../ResourceName';

const Toolbar = styled(DefaultToolbar)`
    display: flex;
    justify-content: space-between;
`;

const CancelButton = (props: any): React.ReactElement => {
    const redirect = useRedirect();

    return (
        <Button
            variant="contained"
            startIcon={<Cancel />}
            onClick={() => redirect(`/${ResourceName.WORKPACKAGES}`)}
        >
            Cancel
        </Button>
    );
};

const EditToolbar = (props: any): React.ReactElement => (
    <Toolbar {...props}>
        <SaveButton label="Rename" />
        <CancelButton />
    </Toolbar>
);

const WorkpackagesEdit = (props: any): React.ReactElement => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Workpackage name modified. Refresh indexes to see changes.`);
        redirect(`/${ResourceName.WORKPACKAGES}`);
    };

    const onFailure = (error: any) => {
        notify(error.message, 'warning');
    };

    return (
        <Edit
            {...props}
            title="Rename workpackage"
            mutationMode="pessimistic"
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <SimpleForm toolbar={<EditToolbar />}>
                <TextInput source="name" validate={[required()]} fullWidth />
            </SimpleForm>
        </Edit>
    );
};

export default WorkpackagesEdit;
