import { styled } from '@material-ui/core';
import { ImageInput as DefaultImageInput } from 'react-admin';
import theme from '../../../themes/utils/theme';

const ImageInput = styled(DefaultImageInput)(
    ({ disabled }: { disabled?: boolean }) => ({
        '& div[class^=RaFileInput-dropZone]': {
            padding: '14px 0',
            cursor: disabled ? 'default' : 'pointer',
            color: disabled ? theme.colors.gray : theme.colors.graydark,
        },
    })
);

export default ImageInput;
