import React from 'react';
import {
    AutocompleteInput,
    ReferenceInput as MuiReferenceInput,
    required,
    TextInput,
} from 'react-admin';
import Reference from '../interfaces/Reference';
import EditDialog from '../components/EditDialog';
import { ResourceName } from '../ResourceName';
import { capitalize } from 'lodash';
import { siteOrCompany } from './ReferenceInput';

type ReferenceUpdateProps = {
    record?: Reference;
    resource?: string;
    registrationCountryId?: string;
    resourceId?: string;
    resourceType: siteOrCompany;
};

const optionText = ({ name }: any) => name;
const ReferenceUpdate = ({
    record,
    registrationCountryId,
    resourceType,
}: ReferenceUpdateProps) => {
    if (!record) {
        return null;
    }

    return (
        <EditDialog record={record} resource="references" label="References">
            <MuiReferenceInput
                label="ReferenceType"
                source="referenceTypeId"
                reference={ResourceName.REFERENCE_TYPES}
                filterToQuery={optionText}
                filter={{
                    [`is${capitalize(resourceType)}`]: true,
                    registrationCountryId,
                }}
                fullWidth
                isRequired
                validate={required()}
            >
                <AutocompleteInput />
            </MuiReferenceInput>
            <TextInput
                label="Value"
                source="value"
                fullWidth
                isRequired
                validate={required()}
            />
        </EditDialog>
    );
};

export default ReferenceUpdate;
