import { styled } from '@material-ui/core';
import React from 'react';

const Container = styled('svg')(() => ({
    transition: 'transform 300ms',
}));

type SVGProps = {
    size?: string;
    viewBox: string;
    children: any;
    className?: string;
};

export type IconProps = {
    size?: string;
    color?: string;
    className?: string;
};

const SVG: React.FC<SVGProps> = ({
    size = '14px',
    viewBox,
    children,
    className,
}: SVGProps): React.ReactElement => (
    <Container
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        viewBox={viewBox}
    >
        {children}
    </Container>
);

export default SVG;
