import { styled } from '@material-ui/core';

export type TextShortenedProps = {
    maxWidth?: string;
};

const TextShortened = styled('span')(() => ({
    whiteSpace: 'nowrap',
    maxWidth: `${(props: TextShortenedProps) => props.maxWidth || '100%'}`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
}));

export default TextShortened;
