import queryString from 'query-string';
import { apiUrl, httpClientJson } from '../../../dataProvider';

export interface ExploreProject {
    ref: any;
    address: string;
    city: string;
    country: string;
    exploreConcatId: string;
    exploreId: string;
    exploreType: string;
    geolocation: {
        lat: number;
        lon: number;
    };
    name: string;
    postalCode: string;
    projectManager: string[];
    projectOwner: string[];
}

export type ExploreCoordinate = {
    latitude: number;
    longitude: number;
    radius: number;
};

const useExplore = () => {
    const getExploreProjects = async (
        coordinate: ExploreCoordinate
    ): Promise<ExploreProject[]> => {
        return httpClientJson(
            `${apiUrl}/v1/proxy/explore/projects?${queryString.stringify(
                coordinate
            )}`
        ).then(({ json }: { json?: ExploreProject[] }) => {
            return json ?? [];
        });
    };

    return getExploreProjects;
};

export default useExplore;
