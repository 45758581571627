import { TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    Datagrid,
    RecordContextProvider,
    DatagridBodyProps,
    useDatagridStyles,
    DatagridRowProps,
    DatagridProps,
} from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import { ItemInterface, ReactSortable } from 'react-sortablejs';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import classnames from 'classnames';
import { UnfoldMore } from '@material-ui/icons';
import styled from 'styled-components';

const MyDatagridRow = ({
    record,
    id,
    children,
    rowIndex,
    className,
    classes,
}: typeof DatagridRowProps) => (
    <RecordContextProvider value={record}>
        <TableRow hover className={className}>
            {React.Children.map(children, (field) => (
                <TableCell
                    key={`${id}-${field.props.source}`}
                    className={classnames(
                        `column-${(field.props as any).source}`,
                        classes.rowCell
                    )}
                >
                    {React.cloneElement(field, { rowIndex, record })}
                </TableCell>
            ))}
        </TableRow>
    </RecordContextProvider>
);

const orderData = (data: Record<string, unknown>): ItemInterface[] => {
    return (Object.values(data) as ItemInterface[]).sort((a, b) =>
        a.order > b.order ? 1 : -1
    );
};

const MyDatagridBody: React.FC<
    typeof DatagridBodyProps & { onChange: (items: any[]) => void }
> = React.forwardRef((props, ref) => {
    const {
        children,
        className,
        data,
        expand,
        hasBulkActions,
        hover,
        onToggleItem,
        resource,
        row,
        rowClick,
        rowStyle,
        selectedIds,
        isRowSelectable,
        onChange,
        ...rest
    } = props;
    const classes = useDatagridStyles(props);

    const [dndData, setDndData] = useState<ItemInterface[]>(orderData(data));

    useEffect(() => {
        setDndData(orderData(data));
    }, [data]);

    const onInternalChange = (items: ItemInterface[]): void => {
        setDndData(items);
        onChange(items);
    };

    return (
        <ReactSortable
            list={dndData}
            setList={onInternalChange}
            tag={TableBody}
            ref={ref}
            {...rest}
        >
            {dndData.map((record: any, rowIndex: number) => (
                <MyDatagridRow
                    classes={classes}
                    className={classnames(classes.row, {
                        [classes.rowEven]: rowIndex % 2 === 0,
                        [classes.rowOdd]: rowIndex % 2 !== 0,
                        [classes.clickableRow]: rowClick,
                    })}
                    expand={expand}
                    hasBulkActions={hasBulkActions && !!selectedIds}
                    hover
                    rowIndex={rowIndex}
                    id={record.id ?? `row${rowIndex}`}
                    key={record.id ?? `row${rowIndex}`}
                    onToggleItem={onToggleItem}
                    record={record}
                    resource={resource}
                    rowClick={rowClick}
                    selectable={!isRowSelectable || isRowSelectable(record)}
                    selected={selectedIds?.includes(record.id)}
                    style={rowStyle ? rowStyle(record, rowIndex) : null}
                >
                    {children}
                </MyDatagridRow>
            ))}
        </ReactSortable>
    );
});

const MyDatagridHeader = ({ children }: typeof DatagridProps) => (
    <TableHead>
        <TableRow>
            <TableCell>patate</TableCell>
            {React.Children.map(children, (child) => (
                <TableCell key={child.props.source}>
                    {child.props.source}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);

const DatagriDragNDropable = (props: typeof DatagridProps) => {
    return (
        <Datagrid
            {...props}
            body={<MyDatagridBody onChange={props.onChange} />}
            header={<MyDatagridHeader />}
        />
    );
};

const DragNDropableHandleContainer = styled.span`
    :hover {
        height: 100%;
        display: inline-block;
        width: 100%;
        cursor: grab;
    }
`;

export const DragNDropableHandle = () => (
    <DragNDropableHandleContainer>
        <UnfoldMore />
    </DragNDropableHandleContainer>
);

export default DatagriDragNDropable;
