import React, { useMemo, useState } from 'react';
import IconContentAdd from '@material-ui/icons/Add';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    SaveContextProvider,
    SelectInput,
    BooleanInput,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SitesInput from '../../../Contract/SitesInput';
import ProjectParticipationRole, {
    orderedRoles,
} from '../../../interfaces/ProjectParticipationRole';
import { useFormState } from 'react-final-form';
import ProjectBundleInput from './ProjectBundleInput';
import ParticipationParentInput from './ParticipationParentInput';
import { useParticipationContext } from './ParticipationProvider';

type ConditionalInputProps = {
    condition: (value: Record<string, unknown>) => boolean;
    children: React.ReactElement;
};

const ConditionalInput = ({ condition, children }: ConditionalInputProps) => {
    const { values } = useFormState();

    if (!condition(values)) {
        return null;
    }

    return <>{children}</>;
};

const AddParticipationButton = () => {
    const { projectId, refresh } = useParticipationContext();
    const [showDialog, setShowDialog] = useState(false);
    const [roleDisabled, setRoleDisabled] = useState(false);
    const [create, { loading }] = useCreate(
        `projects/${projectId}/participations`
    );
    const notify = useNotify();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async (values: Record<string, unknown>) => {
        delete values.isSubContractor;
        create(
            { payload: { data: values } },
            {
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                },
                onFailure: ({ error }: { error: Error }) => {
                    notify(error.message, 'error');
                },
            }
        );
    };

    const saveContext = useMemo(
        () => ({
            save: () => {},
            setOnFailure: () => {},
            saving: loading,
        }),
        [loading]
    );

    return (
        <>
            <Button onClick={handleClick} label="Add a participation">
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Add a participation"
            >
                <DialogTitle>Add a participation</DialogTitle>
                <SaveContextProvider value={saveContext}>
                    <FormWithRedirect
                        resource="posts"
                        save={handleSubmit}
                        render={({
                            handleSubmitWithRedirect,
                            pristine,
                            saving,
                            form,
                        }: any) => {
                            return (
                                <>
                                    <DialogContent>
                                        <SitesInput
                                            source="siteId"
                                            label="Site"
                                            validate={required()}
                                            fullWidth
                                        />
                                        <BooleanInput
                                            label="Is subcontractor"
                                            source="isSubContractor"
                                            onChange={(value: boolean) => {
                                                setRoleDisabled(value);
                                                form.change(
                                                    'role',
                                                    value
                                                        ? ProjectParticipationRole.CONTRACTOR
                                                        : undefined
                                                );
                                                if (!value) {
                                                    form.change(
                                                        'parentId',
                                                        undefined
                                                    );
                                                }
                                            }}
                                        />
                                        <SelectInput
                                            source="role"
                                            label="Role"
                                            fullWidth
                                            disabled={roleDisabled}
                                            validate={required()}
                                            isRequired
                                            choices={orderedRoles.map(
                                                (role) => ({
                                                    name: role,
                                                    id: role,
                                                })
                                            )}
                                            onChange={(event: any) => {
                                                if (
                                                    event.target?.value !==
                                                    ProjectParticipationRole.CONTRACTOR
                                                ) {
                                                    form.change(
                                                        'bundleId',
                                                        undefined
                                                    );
                                                }
                                            }}
                                        />
                                        <ConditionalInput
                                            condition={(
                                                values: Record<string, unknown>
                                            ) =>
                                                values?.role ===
                                                ProjectParticipationRole.CONTRACTOR
                                            }
                                        >
                                            <ProjectBundleInput
                                                projectId={projectId}
                                                source="bundleId"
                                                label="Bundle"
                                                fullWidth
                                                validate={required()}
                                            />
                                        </ConditionalInput>
                                        <ConditionalInput
                                            condition={(values) =>
                                                values?.isSubContractor as boolean
                                            }
                                        >
                                            <ParticipationParentInput
                                                projectId={projectId}
                                                source="parentId"
                                                label="Is subcontractor of"
                                                fullWidth
                                                validate={required()}
                                            />
                                        </ConditionalInput>
                                        <TextInput
                                            multiline
                                            source="details"
                                            label="Detail"
                                            fullWidth
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            label="ra.action.cancel"
                                            onClick={handleCloseClick}
                                            disabled={loading}
                                        >
                                            <IconCancel />
                                        </Button>
                                        <SaveButton
                                            handleSubmitWithRedirect={
                                                handleSubmitWithRedirect
                                            }
                                            pristine={pristine}
                                            saving={saving}
                                            disabled={loading}
                                        />
                                    </DialogActions>
                                </>
                            );
                        }}
                    />
                </SaveContextProvider>
            </Dialog>
        </>
    );
};

export default AddParticipationButton;
