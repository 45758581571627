import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
    Datagrid,
    ReferenceManyField,
    TopToolbar,
    SimpleForm,
} from 'react-admin';
import WidePagination from '../utils/WidePagination';
import AddSubResource from './AddSubResource';
import DeleteButtonField from './DeleteSubResourceButton';
import useDebounce from './useDebounce';
import { styled } from '@material-ui/core';
import AllOrNothing from './types/AllOrNothing';
import Alert from '@material-ui/lab/Alert';
import OverflowX from '../ui/icons/OverflowX';
import { makeStyles } from '@material-ui/core/styles';

type SubResourceProps<T> = AllOrNothing<
    {
        target: string;
        resource: string;
        record: T;
        children: React.ReactElement[];
        addInput?: React.ReactElement;
        name?: string;
        deleteButton?: boolean;
        deleteIdentificator?: keyof T;
        filters?: React.ReactElement | React.ReactElement[];
        pagination?: boolean;
        CustomDatagrid: React.FC;
        onChange: (items: T[]) => void;
        rowClick?: (id: string, basePath: string, record: T) => string;
        disabledDelete?: (data: Record<string, T>, record: T) => boolean;
    },
    'CustomDatagrid' | 'onChange'
>;

type HeadProps<T> = {
    filters?: React.ReactElement | React.ReactElement[];
    record: T;
    name?: string;
    target: string;
    resource: string;
    data: Record<string, T>;
    addInput?: React.ReactElement;
    setFilterValue: (
        cb: (filter: Record<string, string>) => Record<string, string>
    ) => void;
};

const HeadContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});

const useStyles = makeStyles({
    root: {
        '& > div': {
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
        },
    },
    container: {
        '& p.MuiTypography-root:not(.MuiTablePagination-caption)': {
            display: 'none',
        },
    },
});

function Head<T>({
    filters,
    resource,
    target,
    name,
    record,
    addInput,
    setFilterValue,
    data,
}: HeadProps<T>) {
    const classes = useStyles();
    const onFilterChange = (source: string, value: string) => {
        setFilterValue((f: Record<string, string>) => ({
            ...f,
            [source]: value,
        }));
    };

    return (
        <HeadContainer>
            {filters ? (
                <SimpleForm toolbar={false} className={classes.root}>
                    {React.Children.map(
                        filters,
                        (child: React.ReactElement) => {
                            return React.cloneElement(child, {
                                onChange: (
                                    event: ChangeEvent<HTMLInputElement>
                                ) => {
                                    onFilterChange(
                                        child.props.source,
                                        event.target?.value
                                    );
                                },
                            });
                        }
                    )}
                </SimpleForm>
            ) : null}
            {addInput ? (
                <TopToolbar style={filters ? {} : { width: '100%' }}>
                    <AddSubResource
                        name={name}
                        data={data}
                        resource={resource}
                        target={target}
                        parentRecord={record}
                    >
                        {addInput}
                    </AddSubResource>
                </TopToolbar>
            ) : null}
        </HeadContainer>
    );
}

const DeleteButton = ({
    disabledDelete,
    parentRecord,
    record,
    deleteButton,
    name,
    target,
    resource,
    deleteIdentificator,
}: any) => {
    const shouldDisableDelete = useMemo(() => {
        return disabledDelete ? disabledDelete(parentRecord, record) : false;
    }, [disabledDelete, parentRecord]);

    if (!deleteButton) {
        return null;
    }

    return (
        <DeleteButtonField
            disabled={shouldDisableDelete}
            name={name ?? target}
            target={target}
            resource={resource}
            parentRecord={parentRecord}
            record={record}
            identificator={deleteIdentificator}
        />
    );
};

function SubResource<T extends { id: string }>({
    addInput,
    deleteButton = false,
    resource,
    target,
    record,
    children,
    name,
    filters,
    deleteIdentificator,
    pagination = true,
    CustomDatagrid = Datagrid,
    onChange,
    rowClick,
    disabledDelete,
}: SubResourceProps<T>) {
    const [data, setData] = useState<Record<string, T>>({});
    const [filterValue, setFilterValue] = useState<Record<string, string>>({});
    const debouncedFilterValues = useDebounce(filterValue);

    const classes = useStyles();

    return (
        <>
            <Head
                resource={resource}
                name={name}
                target={target}
                record={record}
                addInput={addInput}
                filters={filters}
                setFilterValue={setFilterValue}
                data={data}
            />
            <div className={classes.container}>
                <ReferenceManyField
                    record={record}
                    reference={resource}
                    target={target}
                    filter={debouncedFilterValues}
                    pagination={pagination ? <WidePagination /> : null}
                >
                    {/* @ts-ignore */}
                    <DatagridWrapper
                        name={name}
                        CustomDatagrid={CustomDatagrid}
                        onData={(newData?: Record<string, T>) =>
                            setData(newData ?? {})
                        }
                        onChange={onChange}
                        rowClick={rowClick}
                    >
                        {children}
                        <DeleteButton
                            name={name ?? target}
                            target={target}
                            resource={resource}
                            parentRecord={record}
                            deleteIdentificator={deleteIdentificator}
                            deleteButton={deleteButton}
                            disabledDelete={disabledDelete}
                        />
                    </DatagridWrapper>
                </ReferenceManyField>
            </div>
        </>
    );
}

const DatagridWrapper = ({
    CustomDatagrid,
    children,
    onData,

    ...props
}: any) => {
    useEffect(() => {
        onData(props.data);
    }, [props.data]);

    return (
        <OverflowX>
            <CustomDatagrid
                {...props}
                empty={
                    <Alert severity="info">
                        No {props.name ?? props.target} (yet ?)
                    </Alert>
                }
            >
                {children}
            </CustomDatagrid>
        </OverflowX>
    );
};

export default SubResource;
