import { fetchUtils } from 'react-admin';
import {
    CreateParams,
    DataProvider,
    GetListParams,
    GetManyParams,
    GetOneParams,
    PaginationPayload,
} from 'ra-core';
import queryString from 'query-string';
import ContractSubscriptionParams from './Contract/interfaces/ContractSubscriptionParams.interface';
import { getObjectDiff } from './utils/helper';
import { ResourceName } from './ResourceName';
import { formatRangeHeader } from './formatRangeHeader';
import { toFormData } from './utils/toFormData';
import CryptoJS from 'crypto-js';

export const apiUrl = process.env.REACT_APP_VIACO_API_URL;
const apiKey = process.env.REACT_APP_VIACO_API_KEY;

interface Options {
    [k: string]: any;
}

interface Params {
    [k: string]: any;
}

const formDataResources: string[] = [
    ResourceName.PROJECTS,
    ResourceName.COMPANIES,
];

const isFormData = (resource: string) => {
    return formDataResources.includes(resource);
};

const createHttpOption = async (
    options: Options,
    url: string
): Promise<Options> => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No JWT');
    }

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    if (options.maxRange) {
        options.headers.set('Range', `${options.minRange}-${options.maxRange}`);
    }

    const jwk = CryptoJS.AES.encrypt(
        JSON.stringify({ token: token?.split('.').pop(), url }),
        process.env.REACT_APP_JWK_SECRET as string
    );
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('Viaco-Application-ID', 'Admin');
    options.headers.set('x-api-key', apiKey);
    options.headers.set('jwk', jwk.toString());
    options.headers.set('client-id', 'react-app');

    return options;
};

export const httpClient = (url: string, options: Options = {}) =>
    createHttpOption(options, url).then((options) => fetch(url, options));

export const httpClientJson = (url: string, options: Options = {}): any =>
    createHttpOption(options, url).then((options) =>
        fetchUtils.fetchJson(url, options)
    );

const formatRangeQuery = (resource: string, pagination?: PaginationPayload) => {
    if (resource.includes('v1')) {
        return {};
    }

    const minRange =
        (pagination?.perPage ?? 20) * ((pagination?.page ?? 1) - 1);
    const maxRange = (pagination?.perPage ?? 20) * (pagination?.page ?? 1);

    return { range: `${minRange}-${maxRange}` };
};

const formatResponse = (
    resource: string,
    headers: any,
    json: Record<string, unknown>
) =>
    !resource.includes('v1')
        ? { data: json.list, total: json.totalCount }
        : {
              data: json,
              total:
                  Number(headers.get('content-range')?.split('/')?.pop()) ||
                  150,
          };

function getPrequalification(payload: {
    prequalificationId: string;
    accept: 'application/json';
}): Promise<Record<string, unknown>>;
function getPrequalification(payload: {
    prequalificationId: string;
    accept: 'text/html';
}): Promise<{ data: string }>;
function getPrequalification(payload: {
    prequalificationId: string;
    accept: 'application/pdf';
}): Promise<Blob>;

function getPrequalification(payload: {
    prequalificationId: string;
    accept: 'application/json' | 'application/pdf' | 'text/html';
}): Promise<{ data: string } | Record<string, unknown> | Blob> {
    if (payload.accept === 'application/json') {
        return httpClientJson(
            `${apiUrl}/prequalifications/${payload.prequalificationId}`,
            {
                method: 'GET',
            }
        ).then(({ json }: any) => ({
            data: json,
        }));
    }

    if (payload.accept === 'application/pdf') {
        return httpClient(
            `${apiUrl}/prequalifications/${payload.prequalificationId}`,
            {
                method: 'GET',
                headers: new Headers({ accept: payload.accept }),
            }
        ).then((response) => {
            return response.blob();
        });
    }

    return httpClient(
        `${apiUrl}/prequalifications/${payload.prequalificationId}`,
        {
            method: 'GET',
            headers: new Headers({ accept: payload.accept }),
        }
    )
        .then((response) => {
            return response.text();
        })
        .then((text: string) => ({
            data: text,
        }));
}

const dataProvider: Partial<DataProvider> = {
    httpClient: httpClient,
    httpClientJson: httpClientJson,
    getList: (resource: string, params?: GetListParams) => {
        if (!resource) {
            return Promise.resolve();
        }
        const rangeHeader = formatRangeHeader(resource, params?.pagination);
        const stringified = queryString.stringify({
            ...(params?.filter || {}),
            ...(params?.sort
                ? { order: `${params.sort.field},${params.sort.order}` }
                : {}),
            ...formatRangeQuery(resource, params?.pagination),
        });
        const queryParams = stringified ? `?${stringified}` : '';

        return httpClientJson(
            `${apiUrl}/${resource}${queryParams}`,
            rangeHeader
        ).then(({ headers, json }: any) =>
            formatResponse(resource, headers, json)
        );
    },

    getMany: (resource: string, params: GetManyParams) => {
        if (params.ids.length === 1) {
            return dataProvider
                ?.getOne?.(resource, { id: params.ids[0] })
                .then(({ data }) => ({ data: [data] }));
        }
        const stringified = queryString.stringify({ id: params.ids });
        const queryParams = stringified ? `?${stringified}` : '';

        return httpClientJson(`${apiUrl}/${resource}${queryParams}`).then(
            ({ json, headers }: any) => formatResponse(resource, headers, json)
        );
    },

    getOne: (resource: string, params: GetOneParams) =>
        httpClientJson(`${apiUrl}/${resource}/${params.id}`).then(
            ({ json }: any) => {
                return {
                    data: json,
                };
            }
        ),

    getContractSubscriptionList: async (id: string) => {
        const { json } = await httpClientJson(
            `${apiUrl}/v1/contractsSubscriptions/${id}`
        );

        return {
            data: json,
        };
    },

    create: (resource: string, params: CreateParams) => {
        if (resource === ResourceName.ORGANISATIONS) {
            const status = params.data.status;
            delete params.data.status;

            // cant send status in org body
            return httpClientJson(`${apiUrl}/${resource}?status=${status}`, {
                method: 'POST',
                body: isFormData(resource)
                    ? toFormData(params.data)
                    : JSON.stringify(params.data),
            }).then(({ json }: any) => ({
                data: json,
            }));
        } else {
            return httpClientJson(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: isFormData(resource)
                    ? toFormData(params.data)
                    : JSON.stringify(params.data),
            }).then(({ json }: any) => ({
                data: json,
            }));
        }
    },

    update: (resource, params) => {
        const diff = params.previousData
            ? getObjectDiff(params.data, params.previousData)
            : params.data;
        if (resource === ResourceName.ORGANISATIONS) {
            delete diff.parent;
            delete diff.members;
            delete diff.contract;
        }

        return httpClientJson(
            params.id
                ? `${apiUrl}/${resource}/${params.id}`
                : `${apiUrl}/${resource}`,
            {
                method: 'PUT',
                body: isFormData(resource)
                    ? toFormData(diff)
                    : JSON.stringify(diff),
            }
        ).then(({ json }: any) => ({
            data: json,
        }));
    },

    delete: (resource, params) => {
        return httpClientJson(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(() => ({
            data: params,
        }));
    },

    updateSubResource: (resource: string, body: Record<string, unknown>) => {
        return httpClientJson(`${apiUrl}/${resource}`, {
            method: 'PUT',
            body: JSON.stringify(body),
        }).then(({ json }: any) => ({
            data: json,
        }));
    },

    addUserToSite: (params: Params) => {
        return httpClientJson(
            `${apiUrl}/v1/organisations/${params.data.id}/users/${params.data.users}`,
            {
                method: 'POST',
                body: JSON.stringify(params.data),
            }
        ).then(({ json }: any) => ({
            data: json,
        }));
    },

    updateMembership: ({
        organisationId,
        userId,
        data = {},
    }: {
        organisationId: string;
        userId: string;
        data?: Params;
    }) => {
        return httpClientJson(
            `${apiUrl}/v1/organisations/${organisationId}/users/${userId}`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
            }
        );
    },

    removeUserFromSite: ({
        organisationId,
        userId,
        forceRemove = false,
    }: {
        organisationId: string;
        userId: string;
        forceRemove?: boolean;
    }) =>
        httpClientJson(
            `${apiUrl}/v1/organisations/${organisationId}/users/${userId}`,
            {
                method: 'DELETE',
                body: JSON.stringify({
                    ...(forceRemove && { forceRemove }),
                }),
            }
        ).then(({ json }: any) => ({
            data: json,
        })),

    unsubscribeSiteFromContract: ({
        contractId,
        siteId,
    }: ContractSubscriptionParams) =>
        httpClientJson(
            `${apiUrl}/v1/contracts/${contractId}/organisations/${siteId}`,
            {
                method: 'DELETE',
            }
        ).then(() => ({
            data: [],
        })),

    subscribeSiteToContract: ({
        contractId,
        siteId,
    }: ContractSubscriptionParams) =>
        httpClientJson(
            `${apiUrl}/v1/contracts/${contractId}/organisations/${siteId}`,
            {
                method: 'POST',
            }
        ).then(({ json }: any) => ({
            data: json,
        })),

    exportInvitations: () => httpClientJson(`${apiUrl}/v1/export/invitations`),

    activateSite: (id: string) => {
        // dataProvider does not support 204 response code on PATCH, so then return an empty data object
        return httpClientJson(`${apiUrl}/v1/organisations/${id}/activate`, {
            method: 'PATCH',
        }).then(() => ({
            data: [],
        }));
    },

    uploadUserProvisioningCsv: (params: CreateParams) => {
        return httpClientJson(
            `${apiUrl}/${ResourceName.USER_PROVISIONING_CSV}`,
            {
                method: 'POST',
                body: toFormData(params.data),
            }
        ).then(() => ({
            data: [],
        }));
    },

    duplicateProjectBundle: (projectId: string, params: Params) => {
        return httpClientJson(
            `${apiUrl}/${ResourceName.PROJECTS}/${projectId}/${ResourceName.WORKPACKAGES}/duplicate`,
            {
                method: 'POST',
                body: JSON.stringify(params),
            }
        ).then((response: Response) => {
            if (response.status === 204) {
                return { data: [] }; // Return an empty array or some default value
            }

            // Otherwise, return the actual data from the response
            return {
                data: response.json ? response.json() : [],
            };
        });
    },

    anonymizeUser: (userId: string) => {
        return httpClientJson(`${apiUrl}/users/${userId}/anonymize`, {
            method: 'POST',
        }).then((response: Response) => {
            if (response.status === 204) {
                return { data: [] };
            }

            return {
                data: response.json ? response.json() : [],
            };
        });
    },

    performActionOnAssociation: (resource: string, params: Params) =>
        httpClientJson(
            `${apiUrl}/${resource}/${params.id}/${params.nestedResource}/${params.nestedResourceId}/${params.action}`,
            {
                method: 'PATCH',
            }
        ).then(({ json }: any) => ({
            data: json,
        })),

    archiveLastValidPrequalification: (companyId: string) =>
        httpClientJson(
            `${apiUrl}/v1/organisations/${companyId}/archive-last-valid-prequalification`,
            { method: 'PATCH' }
        ),

    getPrequalification: getPrequalification,

    getPrequalificationsAnalysis: (payload: { prequalificationId: string }) =>
        httpClientJson(
            `${apiUrl}/prequalifications/${payload.prequalificationId}/analysis`,
            { method: 'GET' }
        ).then(({ json, headers }: any) =>
            formatResponse('prequalificationAnalyse', headers, json)
        ),

    getManyReference: (resource, { target, id, pagination, filter, sort }) => {
        const url = `${apiUrl}/${resource}/${id}/${target}`;

        const stringified = queryString.stringify({
            ...(filter || {}),
            ...(sort ? { order: `${sort.field},${sort.order}` } : {}),
            ...formatRangeQuery(resource, pagination),
        });
        const queryParams = stringified ? `?${stringified}` : '';

        return httpClientJson(`${url}${queryParams}`).then(
            ({ headers, json }: any) => formatResponse(resource, headers, json)
        );
    },
};

export default dataProvider;
