import React from 'react';
import TextShortened from './TextShortened';
import Tooltip from './Tooltip';

type AddressProps = {
    street: string;
    postalCode: string;
    city: string;
    className: string;
};

const Address = ({
    street,
    postalCode,
    city,
    className,
}: AddressProps): React.ReactElement => {
    const address = (
        <>
            {`${street},`}
            <br />
            {`${postalCode} ${city}`}
        </>
    );

    return (
        <Tooltip title={address}>
            <TextShortened className={className}>{address}</TextShortened>
        </Tooltip>
    );
};

export default Address;
