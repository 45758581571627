import React, { useMemo, useState } from 'react';
import {
    Button,
    FormWithRedirect,
    required,
    SaveButton,
    SaveContextProvider,
    useDataProvider,
    useNotify,
    useRedirect,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { ResourceName } from '../../../ResourceName';
import DialogContent from '@material-ui/core/DialogContent';
import ProjectsInput from '../../ProjectsInput';
import IconCancel from '@material-ui/icons/Cancel';

const DuplicateProjectWorkPackagesButton = ({
    projectId,
}: {
    projectId: string;
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async (values: Record<string, unknown>) => {
        setLoading(true);
        await dataProvider
            .duplicateProjectBundle(projectId, {
                targetProjectId: values.projectId,
            })
            .then(() => {
                notify('Bundles duplicated', 'success');
                redirect(`/${ResourceName.PROJECTS}/${values.projectId}/show`);
                setShowDialog(false);
            })
            .catch(() => {
                notify('Error: cant duplicate bundles', 'warning');
                redirect(`/${ResourceName.PROJECTS}/${projectId}/show`);
                setShowDialog(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const saveContext = useMemo(
        () => ({
            save: () => {},
            setOnFailure: () => {},
            saving: loading,
        }),
        [loading]
    );

    return (
        <>
            <Button onClick={handleClick} label="Duplicate">
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Duplicate Project WorkPackages"
            >
                <DialogTitle>Duplicate Project WorkPackages</DialogTitle>
                <SaveContextProvider value={saveContext}>
                    <FormWithRedirect
                        resource={ResourceName.PROJECTS}
                        save={handleSubmit}
                        render={({
                            handleSubmitWithRedirect,
                            saving,
                        }: {
                            handleSubmitWithRedirect: () => void;
                            pristine: () => void;
                            saving: () => void;
                        }) => (
                            <>
                                <DialogContent>
                                    <ProjectsInput
                                        label="Project"
                                        source="projectId"
                                        validate={required()}
                                        fullWidth
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        label="ra.action.cancel"
                                        onClick={handleCloseClick}
                                    >
                                        <IconCancel />
                                    </Button>
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        saving={saving}
                                    />
                                </DialogActions>
                            </>
                        )}
                    />
                </SaveContextProvider>
            </Dialog>
        </>
    );
};

export default DuplicateProjectWorkPackagesButton;
