import React, { useState, useEffect } from 'react';
import { FunctionField, useDataProvider } from 'react-admin';

const styles = {
    a: {
        color: '#3f51b5',
        textDecoration: 'none',
    },
};

interface Props {
    record?: { [k: string]: any };
    label: string;
}

const Recipient: React.FC<Props> = ({ record }) => {
    const dataProvider = useDataProvider();
    const [recipient, setRecipient] = useState<{ [k: string]: any }>();

    useEffect(() => {
        if (!record?.recipientEmail) {
            return;
        }

        dataProvider
            .getList('users', {
                filter: { email: record.recipientEmail },
                pagination: {
                    perPage: 1,
                    page: 1,
                },
            })
            .then((res: any) => {
                const user = res.data.find(
                    (u: any) => u.email === record.recipientEmail
                );
                setRecipient(user);
            });
    }, [dataProvider, record]);

    return (
        <FunctionField
            render={() =>
                recipient ? (
                    <a style={styles.a} href={`#/users/${recipient.id}/show`}>
                        {recipient.email}
                    </a>
                ) : (
                    record?.recipientEmail
                )
            }
        />
    );
};

export default Recipient;
