import React from 'react';
import {
    Button as MuiButton,
    makeStyles,
    ButtonProps as MuiButtonProps,
} from '@material-ui/core';
import clsx from 'clsx';
import theme from '../../../themes/utils/theme';

const useStyles = makeStyles({
    button: {
        color: theme.colors.graydark,
        border: `solid 1px ${theme.colors.grayer}`,
        backgroundColor: theme.colors.graylighter,
        fontWeight: 'bold',
        margin: 1,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        padding: '3px 7px',
    },
});

type ButtonProps = {
    onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
    disabled: boolean;
    className?: string;
    children: any;
};

const Button = (
    { onClick, disabled, children, className }: ButtonProps,
    props: MuiButtonProps
) => {
    const classes = useStyles();

    return (
        <MuiButton
            onClick={onClick}
            disabled={disabled}
            className={clsx(className, classes.button)}
            fullWidth
            {...props}
        >
            {children}
        </MuiButton>
    );
};

export default Button;
