import React from 'react';
import useIconColor from './hooks/useIconColor';
import SVG, { IconProps } from './SVG';

const Work: React.FC<IconProps> = ({
    size,
    color,
}: IconProps): React.ReactElement => {
    const iconColor = useIconColor(color);

    return (
        <SVG size={size} viewBox="0 0 24 24">
            <g fill="none">
                <path
                    d="M0 0L24 0 24 24 0 24z"
                    transform="translate(-48 -452) translate(32 64) translate(0 100) translate(0 164) translate(16 118) translate(0 6)"
                />
                <path
                    fill={color || iconColor}
                    d="M9 15c2.67 0 8 1.34 8 4v2H1v-2c0-2.66 5.33-4 8-4zm9-5c.06 0 .12.04.14.1l.09.66c.15.06.29.14.42.24l.62-.25c.05-.02.12 0 .15.06l.5.87c.02.06.01.13-.04.17l-.53.41c.01.07.02.16.02.24 0 .08-.01.16-.02.24l.52.41c.05.04.06.1.03.16l-.5.87c-.03.06-.09.08-.15.06l-.62-.25c-.13.09-.27.18-.42.24l-.09.66c0 .07-.06.11-.12.11h-1c-.06 0-.11-.04-.12-.11l-.09-.66c-.15-.06-.29-.14-.42-.24l-.62.25c-.05.02-.12 0-.15-.06l-.5-.87c-.03-.05-.02-.12.03-.16l.53-.41c-.01-.07-.02-.16-.02-.24 0-.08.01-.16.02-.24l-.53-.41c-.05-.04-.06-.1-.03-.16l.5-.87c.03-.06.09-.08.15-.06l.62.25c.13-.09.27-.18.42-.24l.09-.66c.01-.07.06-.11.12-.11zm-.5 1.67c-.45 0-.83.37-.83.83 0 .45.37.83.83.83.46 0 .83-.37.83-.83 0-.45-.38-.83-.83-.83zM12.86 10c-.45 1.72-2 3-3.86 3-1.86 0-3.41-1.28-3.86-3zm7.34-7c.09 0 .16.06.18.15l.13.93c.21.09.41.2.59.34l.86-.35c.07-.02.17 0 .21.08l.7 1.21c.04.07.03.17-.04.22l-.74.58c.02.11.03.22.03.34 0 .12-.01.23-.02.34l.73.58c.07.05.08.14.04.22l-.7 1.21c-.04.08-.13.11-.21.08l-.87-.35c-.18.13-.38.25-.59.34l-.13.93c-.01.09-.08.15-.17.15h-1.4c-.09 0-.16-.06-.17-.15l-.13-.93c-.21-.09-.41-.2-.59-.34l-.87.35c-.07.02-.17 0-.21-.08l-.7-1.21c-.04-.07-.03-.17.04-.22l.74-.58c-.02-.11-.03-.23-.03-.34 0-.11.01-.23.03-.34l-.74-.58c-.07-.05-.08-.14-.04-.22l.7-1.21c.04-.08.13-.11.21-.08l.87.35c.18-.13.38-.25.59-.34l.13-.93c.01-.09.08-.15.17-.15zM9 4c.35 0 .68.06 1 .14V5.5c0 .28.22.5.5.5s.5-.22.5-.5v-.95c1.19.7 2 1.97 2 3.45h.27c.27 0 .49.22.49.49v.02c0 .27-.22.49-.49.49H4.74c-.27 0-.49-.22-.49-.48v-.03c0-.27.22-.49.49-.49H5c0-1.48.81-2.75 2-3.45v.95c0 .28.22.5.5.5s.5-.22.5-.5V4.14c.32-.08.65-.14 1-.14zm10.5 1.25c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25z"
                    transform="translate(-48 -452) translate(32 64) translate(0 100) translate(0 164) translate(16 118) translate(0 6)"
                />
            </g>
        </SVG>
    );
};

export default Work;
