import React, { useState } from 'react';
import ProjectParticipationItem from '../../../interfaces/ProjectParticipationItem';
import ProjectParticipationRole from '../../../interfaces/ProjectParticipationRole';
import { createStyles, Typography, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ParticipationList from './ParticipationList';

type ParticipationRoleProps = {
    participations: ProjectParticipationItem[];
    role: ProjectParticipationRole;
};

const Accordion = withStyles({
    root: {
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: 0,
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) =>
    createStyles({
        secondaryHeading: {
            color: theme.palette.text.secondary,
            marginLeft: 'auto',
        },
    })
);

const ParticipationRole = ({
    participations,
    role,
}: ParticipationRoleProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);

    const onChange = () => setOpen((oldValue) => !oldValue);

    return (
        <Accordion
            expanded={open}
            onChange={onChange}
            disabled={!participations.length}
        >
            <AccordionSummary
                aria-controls={role}
                id={role}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography>{role}</Typography>
                <Typography className={classes.secondaryHeading}>
                    {participations.length}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ParticipationList
                    participations={participations}
                    role={role}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default ParticipationRole;
