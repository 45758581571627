import React from 'react';
import {
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    DateField,
    TopToolbar,
    sanitizeListRestProps,
    Button,
    downloadCSV,
} from 'react-admin';
import IconDownload from '@material-ui/icons/GetApp';
import dataProvider from '../../dataProvider';
import Recipient from './Recipient';
import List from '../../ui/atoms/List';
import { ResourceName } from '../../ResourceName';

interface Props {
    currentSort?: unknown;
    className?: string;
    resource?: unknown;
    filters?: unknown;
    displayedFilters?: unknown;
    exporter?: unknown;
    filterValues?: unknown;
    permanentFilter?: unknown;
    hasCreate?: unknown;
    basePath?: unknown;
    selectedIds?: unknown;
    onUnselectItems?: unknown;
    showFilter?: unknown;
    maxResults?: unknown;
    total?: unknown;
}

const ListActions: React.FC<Props> = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter,
    filterValues,
    permanentFilter,
    hasCreate,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) => (
    <TopToolbar
        className={className}
        {...sanitizeListRestProps(rest)}
        exporter={null}
    >
        <Button
            onClick={() => {
                dataProvider.exportInvitations().then((response: any) => {
                    downloadCSV(response.body, 'invitations');
                });
            }}
            label="Export"
        >
            <IconDownload />
        </Button>
    </TopToolbar>
);

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

const InvitationList: React.FC = (props) => (
    <List
        {...props}
        actions={<ListActions />}
        filter={{ includes: 'reminders' }}
    >
        <Datagrid>
            <TextField source="id" />
            <TextField source="prequalificationType" />
            <DateField source="createdAt" />
            <ReferenceField
                source="senderId"
                reference={ResourceName.USERS}
                link="show"
            >
                <TextField source="username" />
            </ReferenceField>
            <ReferenceField
                source="invitingSiteId"
                reference={ResourceName.SITES}
                link="show"
            >
                <TextField source="reference" />
            </ReferenceField>
            <ReferenceField
                source="invitingSiteId"
                reference={ResourceName.SITES}
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <Recipient {...props} label="Invited" />
            <ReferenceField
                source="invitedSiteId"
                reference={ResourceName.SITES}
                link="show"
            >
                <TextField source="reference" />
            </ReferenceField>
            <ReferenceField
                source="invitedSiteId"
                reference={ResourceName.SITES}
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <DateField source="remindedAt" />
            <NumberField source="reminders.length" label="Reminders count" />
        </Datagrid>
    </List>
);

export default InvitationList;
