import {
    FormWithRedirect,
    SaveButton,
    SaveContextProvider,
    TextField,
    useDataProvider,
    useNotify,
    useRedirect,
    Button,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ResourceName } from '../../ResourceName';
import DialogContent from '@material-ui/core/DialogContent';
import WorkpackagesAutocompleteInput from './WorkpackagesAutocompleteInput';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import React, { useMemo, useState } from 'react';

type WorkpackagesConvertModalProps = {
    open: boolean;
    onClose: (boolean: boolean) => void;
    record?: {
        id: string;
        workpackage: {
            name: string;
            id: string;
        };
    };
};
const WorkpackagesConvertModal = (props: WorkpackagesConvertModalProps) => {
    const [loading, setLoading] = useState(false);
    const { open, onClose } = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleSubmit = async (values: any) => {
        const params = {
            data: {
                customWorkpackageId: props.record?.id,
                referentialWorkpackageId: values.bundleId,
            },
        };

        setLoading(true);

        await dataProvider
            .create('bundles-transform', params)
            .then(() => {
                notify('Convert in progress');
                redirect('/bundles');
                onClose(false);
            })
            .catch(() => {
                notify(
                    'Error: Cannot convert custom workpackage to referential workpackage',
                    'warning'
                );
                redirect('/bundles');
                onClose(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const saveContext = useMemo(
        () => ({
            save: () => {},
            setOnFailure: () => {},
            saving: loading,
        }),
        [loading]
    );

    return (
        <Dialog fullWidth open={open}>
            <DialogTitle>Convert custom workpackage</DialogTitle>
            <SaveContextProvider value={saveContext}>
                <FormWithRedirect
                    resource={ResourceName.WORKPACKAGES}
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        saving,
                    }: {
                        handleSubmitWithRedirect: () => void;
                        saving: () => void;
                    }) => (
                        <>
                            <DialogContent>
                                <TextField
                                    label="Custom workpackage"
                                    source="workpackage.name"
                                />
                                <WorkpackagesAutocompleteInput
                                    source="bundleId"
                                    label="Referential Workpackage"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => onClose(false)}
                                    variant="text"
                                    size="medium"
                                    label="ra.action.cancel"
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    saving={saving}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </SaveContextProvider>
        </Dialog>
    );
};

export default WorkpackagesConvertModal;
