import React from 'react';

interface Props {
    record?: { [k: string]: any };
}

const UserTitle: React.FC<Props> = ({ record: user }) => {
    return (
        <span>User - {user ? `${user.firstName} ${user.lastName}` : ''}</span>
    );
};

export default UserTitle;
