import React from 'react';
import { Pagination } from 'react-admin';

const ROWS_PER_PAGE = [25, 50, 100];

const WidePagination: React.FC = (props) => (
    <Pagination
        onPageChange={() => {}}
        {...props}
        rowsPerPageOptions={ROWS_PER_PAGE}
    />
);

export default WidePagination;
