import React, { useEffect } from 'react';
import { ExploreProject } from '../hooks/useExplore';
import { Marker, Popup } from 'react-leaflet';
import useMoveMap, { POPUP_DELAY } from '../hooks/useMoveMap';
import ProjectCard from '../ProjectCard';
import { Icon } from 'leaflet';
import blackMarkerIcon from '../../../ui/icons/black-marker.svg';

const MarkerIcon = new Icon({
    iconUrl: blackMarkerIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 30],
    popupAnchor: [-0.5, -30],
});

type MapMarkerProps = {
    project: ExploreProject;
};

const MapMarker = ({ project }: MapMarkerProps) => {
    const { ref } = project;

    const moveMap = useMoveMap(() => {
        if (!ref.current?._icon) {
            setTimeout(() => {
                ref.current?.__parent?.spiderfy?.();
                ref.current?.openPopup?.();
            }, POPUP_DELAY);
        } else {
            ref.current?.openPopup?.();
        }
    });

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        ref.current.selectedOnList = () => {
            const { lon, lat } = project.geolocation;

            // Only move the map if the coordinates are correct
            if (lat !== 0 && lon !== 0) {
                moveMap(project.geolocation.lat, project.geolocation.lon, 18);
            }
        };
    }, [ref]);

    return (
        <Marker
            ref={project.ref}
            key={project.exploreId}
            position={[project.geolocation.lat, project.geolocation.lon]}
            icon={MarkerIcon}
        >
            <Popup closeButton={false} autoPan={false}>
                <ProjectCard data={project} />
            </Popup>
        </Marker>
    );
};

export default MapMarker;
