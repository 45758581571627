import {
    CountryCode,
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from 'libphonenumber-js';

const validatePhoneNumber = (phone: string, country?: CountryCode) => {
    return !phone ||
        phone?.length === 0 ||
        (country
            ? isValidPhoneNumber(phone.toString(), country)
            : isPossiblePhoneNumber(phone))
        ? undefined
        : 'Phone number is not valid';
};

export default validatePhoneNumber;
