import {
    Button,
    CreateButton,
    TopToolbar,
    useDataProvider,
    useNotify,
    useRefresh,
} from 'react-admin';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useState, useEffect } from 'react';
import { apiUrl, httpClientJson } from '../dataProvider';

const WorkpackageListActions = () => {
    const notify = useNotify();
    const [disabled, setDisabled] = useState(false);
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    useEffect(() => {
        httpClientJson(
            `${apiUrl}/workpackage-recommendations/recommendations/status`,
            {
                method: 'GET',
            }
        )
            .then((response: any) => {
                setDisabled(response.json.upToDate ?? false);
            })
            .catch(() => {
                notify('Cannot get status', 'warning');
                setDisabled(false);
            });
    }, []);

    const refreshWorkPackageRecommendations = () => {
        setDisabled(true);
        notify('Recalculate in progress');
        dataProvider
            .httpClient(
                `${apiUrl}/workpackage-recommendations/recommendations`,
                {
                    method: 'POST',
                }
            )
            .then((response: Response) => {
                if (response.ok) {
                    notify('Recalculate done');
                    refresh();
                } else {
                    notify('Recalculate failed', 'warning');
                }
            })
            .catch(() => {
                notify('Recalculate failed', 'warning');
            })
            .finally(() => {
                setDisabled(false);
            });
    };

    return (
        <TopToolbar>
            <Button
                disabled={disabled}
                onClick={refreshWorkPackageRecommendations}
                label="Recalculate recommendations"
            >
                <RefreshIcon />
            </Button>
            <CreateButton />
        </TopToolbar>
    );
};

export default WorkpackageListActions;
