import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { useQueryWithStore } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';

const filter = createFilterOptions();

const RenderInput = ({
    loading,
    onInternalValueChange,
    ...props
}: AutocompleteRenderInputParams & {
    loading: boolean;
    onInternalValueChange: (value: string) => void;
}) => {
    // bad typing from autocomplete
    // @ts-ignore
    const renderInputValue = props.inputProps.value;

    useEffect(() => {
        onInternalValueChange(renderInputValue);
    }, [onInternalValueChange, renderInputValue]);

    return (
        <TextField
            {...props}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <React.Fragment>
                        {loading ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {props.InputProps.endAdornment}
                    </React.Fragment>
                ),
            }}
            label="Add a bundle"
            variant="filled"
        />
    );
};

export type Bundle = {
    id: string;
    name: string;
    code: string;
};

export type OrderedBundle = Bundle & { order: number };

type BundleAutoCompleteProps = {
    onNewValue: (bundle: Bundle) => void;
};

export default function BundleAutoComplete({
    onNewValue,
}: BundleAutoCompleteProps) {
    const [queryParam, setQueryParam] = React.useState<string>('');
    const [inputValue, setInputValue] = React.useState('');

    const { data, loading } = useQueryWithStore({
        type: 'getList',
        resource: 'bundles',
        payload: {
            filter: { name: queryParam },
        },
    });

    const onInternalValueChange = (internalValue: string) => {
        setQueryParam(internalValue);
    };

    return (
        <Autocomplete
            onChange={(event, newValue: any) => {
                setInputValue('');
                if (!newValue || typeof newValue === 'string') {
                    return;
                }
                if ('inputValue' in newValue) {
                    onNewValue({
                        name: inputValue,
                        id: '',
                        code: 'CUS',
                    });
                } else {
                    onNewValue(newValue as Bundle);
                }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue, reason) => {
                setInputValue(reason === 'reset' ? '' : newInputValue);
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="bundle-auto-complete"
            options={data ?? []}
            getOptionLabel={(option: any) => {
                if (option.inputValue) {
                    return option.inputValue;
                }

                // Regular option
                return option.name;
            }}
            renderOption={(option) => option.name}
            freeSolo
            fullWidth
            renderInput={(props) => (
                <RenderInput
                    {...props}
                    onInternalValueChange={onInternalValueChange}
                    loading={loading}
                />
            )}
        />
    );
}
