import React, { useState } from 'react';
import {
    Button,
    Confirm,
    useDataProvider,
    useNotify,
    useRefresh,
} from 'react-admin';
import { Delete } from '@material-ui/icons';

type DeleteSubResourceButtonProps<T> = {
    record?: any;
    target: string;
    resource: string;
    parentRecord: T;
    name: string;
    identificator?: keyof T;
    disabled?: boolean;
};

function DeleteSubResourceButton<T extends { id: string }>({
    record,
    parentRecord,
    name,
    target,
    resource,
    identificator = 'id',
    disabled = false,
}: DeleteSubResourceButtonProps<T>) {
    const [open, setIsOpen] = useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    if (!record) {
        return null;
    }

    const handleDelete = () => {
        if (disabled) {
            return;
        }
        dataProvider
            .delete(`${resource}/${parentRecord.id}/${target}`, {
                id: record[identificator],
            })
            .then(() => {
                notify(`${name} Deleted`, 'success');
                refresh();
            })
            .catch(() => {
                notify(`Error: ${name} not deleted`, 'warning');
            });
    };

    const handleClick = (event: Event) => {
        event.stopPropagation();
        setIsOpen(true);
    };

    return (
        <>
            <Confirm
                isOpen={open}
                title={`Delete ${name}`}
                content={`${name} "${
                    record.name ?? record.value ?? record.id
                }" will be deleted`}
                onClose={() => setIsOpen(false)}
                onConfirm={() => handleDelete()}
            />
            <Button label={'Delete'} disabled={disabled} onClick={handleClick}>
                <Delete />
            </Button>
        </>
    );
}

export default DeleteSubResourceButton;
