import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import SelectContractTypeInput from './SelectContractTypeInput';
import SourceCompanyInput from './SourceCompanyInput';
import AutocompleteUserByEmail from '../utils/AutocompleteUserByEmail';

const ContractCreate: React.FC = (props) => (
    <Create {...props} record={{ isActive: true }}>
        <SimpleForm>
            <TextInput source="name" fullWidth />
            <SelectContractTypeInput fullWidth />
            <SourceCompanyInput label="Source Organisation" fullWidth />
            <BooleanInput source="isActive" fullWidth />
            <AutocompleteUserByEmail
                source="referentId"
                label="Référent"
                fullWidth
            />
            <TextInput source="sso" fullWidth />
            <BooleanInput source="prequalificationUpdate" fullWidth />
            <BooleanInput source="carbonPath" fullWidth />
            <BooleanInput source="apiJson" fullWidth />
            <BooleanInput source="apiHtml" fullWidth />
            <BooleanInput source="apiPdf" fullWidth />
        </SimpleForm>
    </Create>
);

export default ContractCreate;
