import React from 'react';
import {
    Datagrid,
    TextField,
    TextInput,
    DateField,
    NumberField,
    useRecordContext,
} from 'react-admin';
import List from '../ui/atoms/List';
import { makeStyles } from '@material-ui/core/styles';
import ListActions from './ProjectListActions';
import EmptyListPage from '../components/EmptyListPage';

const useStyles = makeStyles({
    bold: { fontWeight: 'bold' },
});

const ParticipantsField = (props: any) => {
    const record = useRecordContext(props);

    return (
        <span>{`${record.actifParticipants} / ${record.totalParticipants}`}</span>
    );
};

const ProjectsList: React.FC = (props) => {
    const classes = useStyles();

    return (
        <List
            actions={<ListActions />}
            filters={[
                <TextInput label="Name" source="name" alwaysOn resettable />,
            ]}
            empty={<EmptyListPage />}
            {...props}
            exporter={false}
        >
            <Datagrid optimized rowClick="show">
                <TextField source="id" sortable={false} />
                <TextField
                    source="name"
                    label="Name"
                    className={classes.bold}
                    sortable={false}
                />
                <TextField
                    source="postalCode"
                    label="Postal code"
                    sortable={false}
                />
                <TextField source="city" label="City" sortable={false} />
                <TextField
                    source="moa.company.name"
                    emptyText="-"
                    label="MOA"
                    sortable={false}
                />
                <DateField
                    locales="FR"
                    source="createdAt"
                    label="Created at"
                    sortable={false}
                />
                <ParticipantsField label="Participant" sortable={false} />
                <TextField source="status" label="Status" sortable={false} />
                <NumberField
                    source="waitingDemands"
                    label="Waiting demand"
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};

export default ProjectsList;
