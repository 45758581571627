import React, { useState } from 'react';
import IconContentAdd from '@material-ui/icons/Add';
import {
    Button,
    useRefresh,
    SaveButton,
    FormWithRedirect,
    useNotify,
    useCreate,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';

type AddSubResourceProps<T> = {
    target: string;
    resource: string;
    name?: string;
    parentRecord: any;
    children: React.ReactElement;
    data: Record<string, T>;
};

const AddSubResource = <T extends unknown>({
    target,
    resource,
    name,
    data,
    parentRecord,
    children,
}: AddSubResourceProps<T>) => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate(
        `${resource}/${parentRecord.id}/${target}`
    );
    const notify = useNotify();
    const refresh = useRefresh();

    const handleSubmit = async (values: any) => {
        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }: any) => {
                    notify(`${name ?? target} created`);
                    setShowDialog(false);
                    refresh();
                },
                onFailure: ({ body }: any) => {
                    notify(body.message, 'error');
                    setShowDialog(false);
                },
            }
        );
    };

    return (
        <>
            <Button
                onClick={() => setShowDialog(true)}
                label="ra.action.add"
                size="medium"
            >
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={() => setShowDialog(false)}
                aria-label={`Add new ${name ?? target}`}
            >
                <DialogTitle>Add new {name ?? target}</DialogTitle>

                <FormWithRedirect
                    resource="posts"
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving,
                    }: any) => (
                        <>
                            <DialogContent>
                                {React.cloneElement(children, { data })}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size="medium"
                                    label="ra.action.cancel"
                                    onClick={() => setShowDialog(false)}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
};

export default AddSubResource;
