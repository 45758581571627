import React from 'react';
import { Tab, useNotify, useRefresh } from 'react-admin';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { saveAs } from 'file-saver';
import { IconButton, MenuItem, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { apiUrl, httpClient } from '../../dataProvider';

const useStyles = makeStyles({
    root: {
        maxWidth: 250,
    },
    media: {
        width: 250,
        height: 250,
    },
});

const ProjectQrTab = ({ ...props }) => {
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const saveQR = () => {
        setAnchorEl(null);
        fetch(props.record?.projectTotem, { method: 'GET' })
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                saveAs(blob, `${props.record?.name}_totem.svg`);
            })
            .catch((err) => {
                console.error('err:', err);
            });
    };

    const regenerateTotem = () => {
        setAnchorEl(null);
        httpClient(`${apiUrl}/projects/${props.record.id}/totem`, {
            method: 'POST',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('');
                }
                notify(`Totem will soon be regenerated`, 'success');
            })
            .catch(() => {
                notify(`Totem could not be regenerated`, 'warning');
            });
    };

    const regenerateQr = () => {
        setAnchorEl(null);
        httpClient(`${apiUrl}/projects/${props.record.id}/qr`, {
            method: 'POST',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('');
                }
                notify(`QR was successfully regenerated`, 'success');
                refresh();
            })
            .catch(() => {
                notify(`QR could not be regenerated`, 'warning');
            });
    };

    return (
        <Tab
            label="Totem"
            path="projectTotem"
            icon={<BorderClearIcon />}
            {...props}
        >
            <Card className={classes.root}>
                <CardActionArea
                    href={`${apiUrl}/qr/${props.record?.id}`}
                    target="_blank"
                >
                    <CardMedia
                        className={classes.media}
                        image={props.record?.projectTotem}
                        title="Access to the totem"
                    />
                </CardActionArea>

                <CardActions disableSpacing>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.record?.name}
                    </Typography>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={saveQR}>Download QR</MenuItem>
                        <MenuItem onClick={regenerateQr}>
                            Regenerate QR
                        </MenuItem>
                        <MenuItem onClick={regenerateTotem}>
                            Regenerate Totem
                        </MenuItem>
                    </Menu>
                </CardActions>
            </Card>
        </Tab>
    );
};

export default ProjectQrTab;
