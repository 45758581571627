import AnalysisKey from '../../../Prequalifications/enums/AnalysisKey';
import {
    analysisRseKeys,
    analysisSapin2Keys,
} from '../../../Prequalifications/constants/analysis';
import { FieldParams } from '../../../Prequalifications/representations/PrequalificationRepresentation';
import React from 'react';
import AnalysisDetailTooltip from '../../../Prequalifications/components/AnalysisDetailTooltip';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';

export enum IndicatorType {
    RSE = 'rse',
    SAPIN2 = 'sapin2',
}

type RulesType = {
    ruleValue: number;
    ruleKeys: AnalysisKey[];
};

const dataByIndicatorType: Record<IndicatorType, RulesType> = {
    [IndicatorType.RSE]: {
        ruleValue: 1,
        ruleKeys: analysisRseKeys,
    },
    [IndicatorType.SAPIN2]: {
        ruleValue: 0,
        ruleKeys: analysisSapin2Keys,
    },
};

type IndicatorFieldType = FieldParams & {
    source: string;
    type: IndicatorType;
    sortable?: boolean;
};

const IndicatorsField = ({
    source,
    type,
    ...props
}: IndicatorFieldType): React.ReactElement => {
    const record = useRecordContext(props);
    const value = get(record, source);

    if (!value || value <= 0) {
        return <>-</>;
    }

    return (
        <AnalysisDetailTooltip
            prequalificationId={record.id}
            keys={dataByIndicatorType[type].ruleKeys}
            expectedValue={dataByIndicatorType[type].ruleValue}
        >
            <>{value}</>
        </AnalysisDetailTooltip>
    );
};

export default IndicatorsField;
