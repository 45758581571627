import React from 'react';
import { Show, TabbedShowLayout } from 'react-admin';
import CompanySummaryTab from './show/CompanySummaryTab';
import CompanyReferencesTab from './show/CompanyReferencesTab';

const CompanyTitle = ({ record }: { record?: { name?: string } }) => {
    return <span>Project {record?.name ?? 'view'}</span>;
};

const CompaniesShow = (props: any) => (
    <Show title={<CompanyTitle />} {...props}>
        <TabbedShowLayout>
            <CompanySummaryTab {...props} />
            <CompanyReferencesTab {...props} />
        </TabbedShowLayout>
    </Show>
);

export default CompaniesShow;
