import React from 'react';
import { Button, Tooltip, MenuItem } from '@material-ui/core';
import SplitDropdown from './SplitDropdown';

type Action = {
    title: string;
    onClick: () => void;
    disabled?: boolean;
};

type AdaptiveSplitDropdownProps = {
    actions: Action[];
    id: string;
    title: string;
    loading?: boolean;
};

const AdaptiveSplitDropdown = ({
    actions,
    id,
    title,
    loading = false,
}: AdaptiveSplitDropdownProps) => {
    if (actions.length === 0) {
        return null;
    }

    if (actions.length === 1) {
        return (
            <Tooltip title={title}>
                <Button
                    id={id}
                    onClick={actions[0].onClick}
                    disabled={actions[0]?.disabled ?? false}
                >
                    {actions[0].title}
                </Button>
            </Tooltip>
        );
    }

    return (
        <SplitDropdown
            id={id}
            title={actions[0].title}
            onClick={actions[0].onClick}
            disabledSplit={actions[0].disabled ?? false}
            loading={loading}
        >
            {actions
                .slice(1)
                .map(({ title, onClick, disabled = false }: Action) => (
                    <MenuItem key={title} onClick={onClick} disabled={disabled}>
                        {title}
                    </MenuItem>
                ))}
        </SplitDropdown>
    );
};

export default AdaptiveSplitDropdown;
