import React from 'react';
import { FieldProps, useRecordContext } from 'react-admin';
import { LinearProgress } from '@material-ui/core';
import NotCalculatedYet from './NotCalculatedYet';
import { Li, Ul } from './style';
import styled from 'styled-components';
import Alert from '@material-ui/lab/Alert';

const StyledAlert = styled(Alert)`
    word-break: break-word;
    white-space: break-spaces;
`;

const DistanceListField: React.FC<typeof FieldProps> = (props) => {
    const record = useRecordContext(props);

    const value = record[props.source];

    if (record.error) {
        return <StyledAlert severity="error">{record.error}</StyledAlert>;
    }

    if (props.isLoading) {
        return <LinearProgress />;
    }

    if (!value) {
        return <NotCalculatedYet />;
    }

    return (
        <Ul>
            {value
                .reduce((acc: string[], cur: any) => {
                    return [
                        ...acc,
                        { value: cur.value, name: cur.workpackage.name },
                    ];
                }, [])
                .map(
                    (
                        listItem: { id: string; value: number; name: string },
                        index: number
                    ) => (
                        <Li
                            key={`${listItem.id}-${index}`}
                            $value={listItem.value}
                        >
                            {listItem.name} ({listItem.value})
                        </Li>
                    )
                )}
        </Ul>
    );
};

export default DistanceListField;
