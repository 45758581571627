import React, { useEffect } from 'react';
import ReferenceArrayInput from '../ui/atoms/inputs/ReferenceArrayInput';
import { useForm, useFormState } from 'react-final-form';
import Alert from '@material-ui/lab/Alert';
import { get } from 'lodash';
import { useQuery } from 'react-admin';
import { ResourceName } from '../ResourceName';
import { ReferenceOrder } from '../interfaces/ReferenceOrder';
import { LinearProgress } from '@material-ui/core';

type ReferenceArrayInputProps = {
    source: string;
    dependOnSource: string;
    label: string;
    fullWidth: boolean;
    validate?: any;
    isSite?: boolean;
    isCompany?: boolean;
};

const RegistrationCountryReferenceArrayInput: React.FC<
    ReferenceArrayInputProps
> = (props) => {
    const { values } = useFormState();
    const { change } = useForm();
    const registrationCountryId = get(values, props.dependOnSource) ?? '';
    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: registrationCountryId
            ? `${ResourceName.REGISTRATION_COUNTRIES}/${registrationCountryId}/${ResourceName.REFERENCE_ORDERS}`
            : '',
        payload: {
            filter: { isSite: props.isSite, isCompany: props.isCompany },
        },
    });

    useEffect(() => {
        change(props.source, undefined);
    }, [registrationCountryId]);

    if (!registrationCountryId) {
        return (
            <Alert severity="warning">
                A registrationCountry must be selected in order to chose
                referenceTypes to add
            </Alert>
        );
    }

    if (loading) {
        return <LinearProgress variant="query" />;
    }

    if (!data || error) {
        return <Alert severity="error">Could not fetch referenceTypes</Alert>;
    }

    return (
        <>
            {data.length === 0 && (
                <Alert severity="error">
                    No referenceOrder exists with this registrationCountry
                </Alert>
            )}
            <ReferenceArrayInput
                disabled={data.length === 0}
                choices={data.map((refOrder: ReferenceOrder) => ({
                    id: refOrder.referenceType.id,
                    name: refOrder.referenceType.name,
                }))}
                {...props}
            />
        </>
    );
};

export default RegistrationCountryReferenceArrayInput;
