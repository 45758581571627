import React from 'react';
import { AutocompleteInput, ReferenceInput, required } from 'react-admin';
import { ResourceName } from '../../ResourceName';

type WorkpackageAutocompleteInputProps = {
    label?: string;
    source?: string;
    fullWidth?: boolean;
};

const filterToQuery = (name: string) => ({ name });

const optionText = (record: any) => record.name;

const WorkpackagesAutocompleteInput = (
    props: WorkpackageAutocompleteInputProps
) => (
    <ReferenceInput
        label={props.label}
        source={props.source}
        reference={ResourceName.WORKPACKAGES}
        filterToQuery={filterToQuery}
        perPage={25}
        allowEmpty={false}
        fullWidth={props.fullWidth}
    >
        <AutocompleteInput
            optionText={optionText}
            fullWidth
            isRequired={true}
            validate={required()}
            selectedIds={[]}
        />
    </ReferenceInput>
);

export default WorkpackagesAutocompleteInput;
