import React from 'react';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';

const VersionField = ({ source, ...props }: any): React.ReactElement => {
    const record = useRecordContext(props);
    const value = get(record, source);

    if (!value || value <= 0) {
        return <>-</>;
    }

    return <>{value}</>;
};

export default VersionField;
