import React from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    TextField,
    TextInput,
    TabProps,
} from 'react-admin';
import SubResource from '../utils/SubResource';
import { ResourceName } from '../ResourceName';
import Project from '../interfaces/Project';

const optionText = ({ name }: Project) => name;

const ContractProjects = ({ resource, record }: typeof TabProps) => (
    <SubResource
        target="projects"
        name="Contract Project"
        resource={resource}
        record={record}
        filters={[<TextInput label="Project Name" source="name" alwaysOn />]}
        addInput={
            <ReferenceInput
                label="Project"
                source="projectId"
                reference={ResourceName.PROJECTS}
                filterToQuery={(name: string) => ({ name })}
                fullWidth
                isRequired
            >
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
        }
        deleteButton
    >
        <TextField sortable={false} source="id" label="Id" />
        <TextField sortable={false} source="name" label="Name" />
    </SubResource>
);

export default ContractProjects;
