import React from 'react';

interface Props {
    record?: { [k: string]: any };
}

const ContractTitle: React.FC<Props> = ({ record }) => {
    return <span>Contract - {record ? `${record.name}` : ''}</span>;
};

export default ContractTitle;
