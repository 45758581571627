import { ObjectFieldTemplateProps } from '@rjsf/utils';
import React from 'react';
import styled from 'styled-components';

const IndentedContainer = styled.div`
    margin-left: 10px;
`;

const ObjectFieldTemplate = (
    props: ObjectFieldTemplateProps
): React.ReactElement => {
    return (
        <div>
            {props.idSchema.$id === 'root'
                ? props.title && <h2>{props.title}</h2>
                : props.title && <h4>{props.title}</h4>}
            <IndentedContainer>
                {props.properties.map((element, index) => (
                    <div key={index} className="property-wrapper">
                        {element.content}
                    </div>
                ))}
            </IndentedContainer>
        </div>
    );
};

export default ObjectFieldTemplate;
