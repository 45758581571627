import React, { useState } from 'react';
import styled from 'styled-components';
import { BlockPicker, ColorResult } from 'react-color';

const PopOver = styled.div`
    position: absolute;
    z-index: 2;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
`;

const PopOverContainer = styled.div`
    position: relative;
`;

const Mask = styled.div`
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

const defaultColors: string[] = [
    '#000000',
    '#434245',
    '#727078',
    '#03ccb9',
    '#158c80',
    '#3db6ad',
    '#4c3e72',
    '#ffc858',
    '#1f2041',
    '#f45141',
    '#ff9f11',
    '#4aa7e2',
];

type WysiwygColorPickerProps = {
    onChange: (property: string, value: string) => void;
    currentState: {
        color: string;
    };
    config: {
        icon: string;
    };
};

const WysiwygColorPicker: React.FC<WysiwygColorPickerProps> = ({
    onChange,
    currentState,
    config,
}) => {
    const [open, internalSetOpen] = useState<boolean>(false);

    const setOpen = (value: boolean) => {
        internalSetOpen(value);
    };

    const stopPropagation = (event: any) => {
        event.stopPropagation();
    };

    const onInternalChange = (color: ColorResult) => {
        onChange('color', color.hex);
        setOpen(false);
    };

    return (
        <div
            aria-haspopup="true"
            aria-expanded={open}
            aria-label="rdw-color-picker"
        >
            <div className="rdw-option-wrapper" onClick={() => setOpen(true)}>
                <img src={config.icon} alt="" />
            </div>
            {open ? (
                <PopOverContainer>
                    <Mask onClick={() => setOpen(false)} />
                    <PopOver onClick={stopPropagation}>
                        <BlockPicker
                            triangle="hide"
                            color={currentState.color}
                            onChangeComplete={onInternalChange}
                            colors={defaultColors}
                        />
                    </PopOver>
                </PopOverContainer>
            ) : undefined}
        </div>
    );
};

export default WysiwygColorPicker;
