import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import muiTheme from './themes/muiTheme';
import Layout from './Layout';
import { resources } from './resources';
import { ResourceName } from './ResourceName';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route } from 'react-router-dom';
import ProjectSearch from './Projects/search/ProjectSearch';

const App = () => (
    <>
        <CssBaseline />
        <Admin
            authProvider={authProvider}
            dataProvider={dataProvider}
            customRoutes={[
                <Route
                    exact
                    path={ResourceName.PROJECTS_SEARCH}
                    component={ProjectSearch}
                />,
            ]}
            theme={muiTheme}
            layout={Layout}
            title="Viaco Admin"
        >
            {resources.map((res) => (
                <Resource
                    key={res.name}
                    name={res.name}
                    options={res.options}
                    icon={res.icon}
                    show={res.show}
                    list={res.list}
                    create={res.create}
                    edit={res.edit}
                />
            ))}
            <Resource name={ResourceName.PROJECTS_BUILDING_TAGS} />
            <Resource name={ResourceName.PROJECTS_SITE_TAGS} />
        </Admin>
    </>
);

export default App;
