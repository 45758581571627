import { useMapEvents } from 'react-leaflet';
import { ExploreCoordinate } from '../hooks/useExplore';
import { Map } from 'leaflet';
import useDebounceCallback from '../../../utils/hooks/useDebounceCallback';
import distanceBetweensTwoPoints from '../../../utils/distanceBetweensTwoPoints';

type MapEventsProps = {
    onChange: (coordinates: ExploreCoordinate) => void;
};

const calculateRadiusFromZoom = (distance: number) => distance / 2;

const MapEvents = ({ onChange }: MapEventsProps) => {
    const moveMap = useDebounceCallback((map: Map) => {
        const bounds = map.getBounds();
        const distance = distanceBetweensTwoPoints(
            bounds.getNorthEast(),
            bounds.getSouthWest()
        );
        const center = map.getCenter();
        onChange({
            latitude: center.lat,
            longitude: center.lng,
            radius: calculateRadiusFromZoom(distance),
        });
    }, 1000);

    const map = useMapEvents({
        dragend() {
            moveMap(map);
        },
        zoomanim() {
            moveMap(map);
        },
    });

    return null;
};

export default MapEvents;
