import EditableShow, { EditableShowProps } from '../components/EditableShow';
import { useNotify, useRedirect, useRefresh } from 'react-admin';
import React from 'react';
import CompanyFields from './CompanyFields';

const getPicture = (data: { logo?: any }) => {
    if (data.logo?.rawFile) {
        return data.logo.rawFile;
    }
    if (typeof data.logo === 'string') {
        return data.logo;
    }

    return null;
};

const transformCompanyData = (
    data: Record<string, unknown>
): Record<string, unknown> => {
    delete data.mainReference;
    delete data.registrationCountry;

    return {
        ...data,
        logo: getPicture(data),
    };
};

const CompanyEdit = (props: EditableShowProps) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect(`/companies/${props.id}/show`);
        refresh();
    };

    return (
        <EditableShow
            {...props}
            transform={transformCompanyData}
            onSuccess={onSuccess}
        >
            <CompanyFields />
        </EditableShow>
    );
};

export default CompanyEdit;
