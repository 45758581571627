import React from 'react';
import {
    Show,
    TextField,
    EmailField,
    TabbedShowLayout,
    Tab,
} from 'react-admin';
import UserTitle from './UserTitle';
import MyDatagrid from '../utils/MyDatagridRow';
import DeleteOrganisationButton from './UserShow/DeleteOrganisationButton';
import DisableOrganisationButton from './UserShow/DisableOrganisationButton';
import AnonymizeUserButton from './UserShow/AnonymizeUserButton';

const UserShow: React.FC = (props) => (
    <Show title={<UserTitle {...props} />} {...props}>
        <TabbedShowLayout p={0}>
            <Tab label="summary" variant="fullWidth">
                <AnonymizeUserButton />
                <TextField source="id" />
                <TextField source="username" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <EmailField source="email" />
                <TextField source="phone" />
            </Tab>
            <Tab label="Organisations">
                <MyDatagrid source="organisations">
                    <TextField source="id" label="id" />
                    <TextField source="reference" label="Reference" />
                    <TextField source="name" label="Name" />
                    <TextField
                        source="membership.status"
                        label="Membership status"
                    />
                    <DisableOrganisationButton />
                    <DeleteOrganisationButton />
                </MyDatagrid>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default UserShow;
