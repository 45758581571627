import { FieldTemplateProps } from '@rjsf/utils';
import React from 'react';

const CustomFieldTemplate = (props: FieldTemplateProps) => {
    const { classNames, style, description, errors, children } = props;

    return (
        <div className={classNames} style={style}>
            {description}
            {children}
            {errors}
        </div>
    );
};

export default CustomFieldTemplate;
