import { httpClientJson } from '../../../dataProvider';
import { ExploreCoordinate } from './useExplore';

const NOMINATIM_OPEN_STREET_MAP_URL =
    'https://nominatim.openstreetmap.org/search';

const useLocationToCoordinates = () => {
    const locationToCoordinates = async (
        location: string
    ): Promise<ExploreCoordinate> => {
        const params = new URLSearchParams({
            format: 'json',
            limit: '1',
            q: location,
        });

        return await httpClientJson(
            NOMINATIM_OPEN_STREET_MAP_URL + '?' + params
        )
            .then((res: { json: Record<string, any> }) => {
                return {
                    latitude: res.json[0]?.lat ?? 0,
                    longitude: res.json[0]?.lon ?? 0,
                    radius: Math.max(
                        Math.abs(res.json[0]?.boundingbox[0]) ??
                            0 - Math.abs(res.json[0]?.boundingbox[1]) ??
                            0,
                        Math.abs(res.json[0]?.boundingbox[2]) ??
                            0 - Math.abs(res.json[0]?.boundingbox[3]) ??
                            0
                    ),
                };
            })
            .catch(() => {
                console.log('No Project find');
            });
    };

    return locationToCoordinates;
};

export default useLocationToCoordinates;
