import styled from 'styled-components';

export const Ul = styled.ul`
    list-style: none;
    font-size: 0.9em;
    padding: 0;
`;

export const Li = styled.li<{ $value?: number }>`
    ${(props) =>
        props.$value === 0
            ? `
        color: #158C80;
        font-weight: bold;
      `
            : ''}
`;
