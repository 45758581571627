import L from 'leaflet';
import './customMarkerCluster.css';

const customMarkerCluster = (cluster: { getChildCount: () => number }) => {
    return L.divIcon({
        html: `<span>${cluster.getChildCount()}</span>`,
        className: 'marker-cluster-custom',
        iconSize: L.point(32, 32, true),
    });
};

export default customMarkerCluster;
