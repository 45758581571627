/* eslint-disable @typescript-eslint/no-explicit-any */
const filterPropertiesWithNullType = (obj: any): any => {
    if (Array.isArray(obj)) {
        // If it's an array, filter each element recursively
        return obj.map((item: unknown) => filterPropertiesWithNullType(item));
    } else if (obj !== null && typeof obj === 'object') {
        const filteredObj: Record<string, unknown> = {};

        for (const key in obj) {
            // Recursively call the function for nested objects and arrays
            const filteredNestedObj = filterPropertiesWithNullType(
                (obj as Record<string, unknown>)[key]
            );

            // Include the property in the filtered object only if it has non-null 'type'
            if (
                !(
                    filteredNestedObj &&
                    !!filteredNestedObj.type &&
                    filteredNestedObj.type === 'null'
                )
            ) {
                (filteredObj as any)[key] = filteredNestedObj;
            }
        }
        delete filteredObj.required;

        return filteredObj;
    } else {
        // Include non-object properties directly
        return obj;
    }
};

export default filterPropertiesWithNullType;
