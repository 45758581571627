import React from 'react';
import { Tab, TabProps } from 'react-admin';
import SiteEdit from './SiteEdit';

const SiteSummaryTab = (props: typeof TabProps) => {
    const { hasCreate, hasEdit, hasShow, hasList, ...rest } = props;

    return (
        <Tab label="Summary" {...rest}>
            <SiteEdit {...rest} />
        </Tab>
    );
};

export default SiteSummaryTab;
