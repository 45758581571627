import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';

export enum RemoveBundleSubscriptionErrorType {
    EXISTING_SUBSCRIPTION = 'EXISTING_SUBSCRIPTION',
    EXISTING_REQUEST = 'EXISTING_REQUEST',
}

export interface ProjectBundleError {
    id: string;
    role: string;
    bundle: {
        id: string;
        code: string;
        name: string;
    };
    site: {
        id: string;
        company: {
            id: string;
            name: string;
        };
    };
}

const getSubtitleBundleError = (context: {
    type: RemoveBundleSubscriptionErrorType;
    projectParticipationErrors?: ProjectBundleError[];
    projectRequestErrors?: ProjectBundleError[];
}) => {
    switch (context.type) {
        case RemoveBundleSubscriptionErrorType.EXISTING_SUBSCRIPTION:
            return 'A bundle is used in one or more subscriptions. Please delete the subscription(s) first';
        case RemoveBundleSubscriptionErrorType.EXISTING_REQUEST:
            return 'A pending participation request has been linked with this bundle';
        default:
            return '';
    }
};

type BundleErrorProps = {
    data: {
        type: RemoveBundleSubscriptionErrorType;
        projectParticipationErrors?: ProjectBundleError[];
        projectRequestErrors?: ProjectBundleError[];
    } | null;
    handleClose: () => void;
    subtitle?: string;
};

const BundleError = ({ data, handleClose }: BundleErrorProps) => {
    if (!data) {
        return null;
    }

    const projectErrors =
        data.projectParticipationErrors ?? data.projectRequestErrors ?? [];

    return (
        <Dialog
            open={!!data}
            onClose={handleClose}
            aria-labelledby="impossible-to-delete-bundle"
        >
            <DialogTitle id="impossible-to-delete-bundle">
                Impossible to delete bundles
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {getSubtitleBundleError(data)}
                </DialogContentText>
                {projectErrors.length > 0 ? (
                    <List>
                        {projectErrors.map((pe: ProjectBundleError) => (
                            <ListItem key={pe.id}>
                                <ListItemText
                                    primary={`Bundle: ${pe.bundle.name} (${pe.bundle.code})`}
                                    secondary={`Site "${
                                        pe.site.company.name
                                    }" ${pe.role && `as ${pe.role}`}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BundleError;
