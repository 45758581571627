import React, { useState } from 'react';
import AdaptiveSplitDropdown from '../../ui/atoms/inputs/AdaptiveSplitDropdown';
import WorkpackagesConvertModal from './WorkpackagesConvertModal';

type WorkpackageActionProps = {
    label: string;
    record?: {
        id: string;
        workpackage: {
            name: string;
            id: string;
        };
    };
};
const WorkpackagesListActionsButton = (props: WorkpackageActionProps) => {
    const [loading] = useState(false);
    const [open, setOpen] = useState(false);

    return (
        <>
            <AdaptiveSplitDropdown
                actions={[
                    {
                        title: 'CONVERT',
                        onClick: () => setOpen(true),
                    },
                ]}
                id="workpackages"
                title="workpackages"
                loading={loading}
                {...props}
            />

            <WorkpackagesConvertModal
                open={open}
                onClose={() => setOpen(false)}
                {...props}
            />
        </>
    );
};

export default WorkpackagesListActionsButton;
