import React from 'react';
import {
    Datagrid,
    TextField,
    BooleanField,
    TextInput,
    SelectInput,
} from 'react-admin';
import List from '../ui/atoms/List';
import { makeStyles } from '@material-ui/core/styles';
import StatusField from '../ui/atoms/fields/organisations/StatusField';
import AddressField from '../ui/atoms/fields/organisations/companies/AddressField';

const useStyles = makeStyles({
    bold: { fontWeight: 'bold' },
});

enum SiteStatusModel {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    CLOSED = 'CLOSED',
}

const SitesList: React.FC = (props) => {
    const classes = useStyles();

    return (
        <List
            filters={[
                <TextInput
                    label="Search"
                    source="search"
                    alwaysOn
                    resettable
                />,
                <SelectInput
                    source="status"
                    label="Status"
                    resettable
                    alwaysOn
                    choices={[
                        {
                            id: SiteStatusModel.ACTIVE,
                            name: SiteStatusModel.ACTIVE,
                        },
                        {
                            id: SiteStatusModel.INACTIVE,
                            name: SiteStatusModel.INACTIVE,
                        },
                        {
                            id: SiteStatusModel.CLOSED,
                            name: SiteStatusModel.CLOSED,
                        },
                    ]}
                />,
            ]}
            {...props}
            exporter={false}
        >
            <Datagrid optimized rowClick="show">
                <TextField source="id" sortable={false} />
                <TextField
                    source="mainReference.value"
                    label="Reference"
                    sortable={false}
                />
                <TextField
                    source="company.name"
                    label="Company name"
                    className={classes.bold}
                    sortable={false}
                />
                <AddressField label="Address" sortable={false} />
                <TextField
                    source="membersNumber"
                    label="Users number"
                    sortable={false}
                />
                <TextField
                    source="waitingRequestsNumber"
                    label="Waiting requests"
                    sortable={false}
                />
                <StatusField source="status" sortable={false} />
                <BooleanField
                    source="isHeadquarter"
                    label="Headquarter"
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};

export default SitesList;
