import {
    useEditController,
    EditContextProvider,
    useCheckMinimumRequiredProps,
    SaveButton,
    Button,
    SimpleForm,
} from 'react-admin';
import React, { cloneElement, ReactElement, useState } from 'react';
import { Toolbar, makeStyles } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles({
    editButtonContainer: {
        display: 'flex',
        justifyContent: 'right',
    },
});

export type EditableShowProps = {
    children: ReactElement;
    transform: (newData: any) => any;
    basePath: string;
    history: Record<string, any>;
    id: string;
    location: Record<string, any>;
    match: Record<string, any>;
    options: Record<string, any>;
    resource: string;
    onSuccess: () => void;
    alterRecord?: (record: any) => void;
    title?: string;
    permissions?: string;
    hasCreate?: boolean;
    hasShow?: boolean;
    hasList?: boolean;
    hasEdit?: boolean;
    hasLinkReference?: Record<string, unknown>;
    additionalToolbarButtons?: ReactElement;
};

const PostEditToolbar = (props: any) => (
    <Toolbar>
        {props.disabled ? (
            <Button label="Edit" onClick={props.toogleDisabled} size="medium">
                <CreateIcon />
            </Button>
        ) : (
            <>
                <Button
                    size="medium"
                    onClick={props.toogleDisabled}
                    label="cancel"
                />
                <SaveButton {...props} />
            </>
        )}
    </Toolbar>
);

const EditableShow = (props: EditableShowProps): ReactElement => {
    useCheckMinimumRequiredProps('Edit', ['children'], props);
    const classes = useStyles();
    const controllerProps = useEditController({
        ...props,
        mutationMode: 'pessimistic',
    });
    const { basePath, redirect, resource, save, saving, version, record } =
        controllerProps;
    const { alterRecord } = props;
    const alteredRecord = alterRecord ? alterRecord(record) : record;
    const [disabled, setDisabled] = useState(true);

    return (
        <EditContextProvider value={controllerProps}>
            <div className={classes.editButtonContainer}>
                {props.additionalToolbarButtons &&
                    props.additionalToolbarButtons}
                <Button
                    label="Edit"
                    onClick={() => setDisabled(!disabled)}
                    size="medium"
                >
                    <CreateIcon />
                </Button>
            </div>
            <SimpleForm
                basePath={basePath}
                record={alteredRecord}
                redirect={redirect}
                resource={resource}
                save={save}
                saving={saving}
                version={version}
                toolbar={
                    <PostEditToolbar
                        toogleDisabled={() => setDisabled(!disabled)}
                        disabled={disabled}
                    />
                }
            >
                {cloneElement(props.children, {
                    disabled: disabled,
                })}
            </SimpleForm>
        </EditContextProvider>
    );
};

export default EditableShow;
