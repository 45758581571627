import React, { useState } from 'react';
import {
    Button,
    Confirm,
    useDataProvider,
    useNotify,
    useRefresh,
} from 'react-admin';
import { Delete } from '@material-ui/icons';

const DeleteOrganisationButton = (props: {
    record?: { id: string; name: string };
    label?: string;
    recordparent?: { id: string; lastName: string; firstName: string };
}) => {
    const { record: organisation, recordparent: user } = props;
    const [open, setIsOpen] = useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    if (!organisation) {
        return null;
    }

    if (!user) {
        return null;
    }

    const handleDelete = () => {
        dataProvider
            .removeUserFromSite({
                organisationId: organisation.id,
                userId: user.id,
            })
            .then(() => {
                notify('Contract Subscription Deleted', 'success');
                refresh();
            })
            .catch(() => {
                notify('Error: Contract Subscription not deleted', 'warning');
            });
    };

    return (
        <>
            <Confirm
                isOpen={open}
                title={'Delete User'}
                content={`User ${user.firstName} ${user.lastName} will be removed from organisation ${organisation.name}`}
                onClose={() => setIsOpen(false)}
                onConfirm={() => handleDelete()}
            />
            <Button label={'Delete'} onClick={() => setIsOpen(true)}>
                <Delete />
            </Button>
        </>
    );
};

export default DeleteOrganisationButton;
