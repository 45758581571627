import React from 'react';
import { SimpleForm, TextInput, SelectInput } from 'react-admin';
import useDebounceCallback from '../../utils/hooks/useDebounceCallback';
import { styled } from '@material-ui/core';
import ArrayOption from '../interfaces/ArrayOption';

const FormContainer = styled('div')(() => ({
    width: '100%',
    height: 'auto',

    '& .MuiCardContent-root:last-child': {
        paddingBottom: '0 !important',
    },
}));

const Select = styled(SelectInput)(() => ({
    '& .MuiList': {
        display: 'flex',
        flexDirection: 'column',
    },
}));

type ProjectSearchFormProps = {
    onChange: (search: string) => void;
    projectOwnerOptions: ArrayOption[];
    setProjectOwner: (projectOwner: string) => void;
};

const ProjectSearchForm = ({
    onChange,
    setProjectOwner,
    projectOwnerOptions,
}: ProjectSearchFormProps) => {
    const handleChangeForm = useDebounceCallback((e: any) => {
        onChange(e.target.value);
    });

    return (
        <FormContainer>
            <SimpleForm
                toolbar={<></>}
                onChange={(e: React.ChangeEvent) => handleChangeForm(e)}
                onSubmit={(e: React.ChangeEvent) => e.preventDefault()}
            >
                <>
                    <TextInput source="location" fullWidth />
                    <Select
                        source="projectOwner"
                        emptyText="All"
                        choices={projectOwnerOptions}
                        fullWidth
                        allowEmpty
                        onChange={(e: { target: { value: string } }) =>
                            setProjectOwner(e.target.value)
                        }
                    />
                </>
            </SimpleForm>
        </FormContainer>
    );
};

export default ProjectSearchForm;
