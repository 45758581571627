import React, { useState } from 'react';
import {
    Button,
    Confirm,
    useDataProvider,
    useNotify,
    useRefresh,
} from 'react-admin';
import { Link, LinkOff } from '@material-ui/icons';

const active = (status: string): boolean => status === 'ACTIVE';

const DisableOrganisationButton = (props: {
    record?: { id: string; name: string; membership: { status: string } };
    label?: string;
    recordparent?: { id: string; lastName: string; firstName: string };
}) => {
    const { record: organisation, recordparent: user } = props;
    const [open, setIsOpen] = useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    if (!organisation) {
        return null;
    }

    if (!user) {
        return null;
    }

    const status = organisation.membership.status;

    const handleUpdate = () => {
        const data = {
            status: active(status) ? 'INACTIVE' : 'ACTIVE',
        };
        dataProvider
            .updateMembership({
                organisationId: organisation.id,
                userId: user.id,
                data,
            })
            .then(() => {
                notify('Contract Subscription Updated', 'success');
                refresh();
            })
            .catch(() => {
                notify('Error: Contract Subscription not updated', 'warning');
            });
    };

    return (
        <>
            <Confirm
                isOpen={open}
                title={active(status) ? 'Disable User' : 'Enable User'}
                content={`User ${user.firstName} ${user.lastName} will be ${
                    active(status) ? 'disable' : 'enable'
                } from organisation ${organisation.name}`}
                onClose={() => setIsOpen(false)}
                onConfirm={() => handleUpdate()}
            />
            <Button
                label={active(status) ? 'Disable' : 'Enable'}
                onClick={() => setIsOpen(true)}
            >
                {active(status) ? <LinkOff /> : <Link />}
            </Button>
        </>
    );
};

export default DisableOrganisationButton;
