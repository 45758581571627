import React from 'react';
import { Datagrid, TextField } from 'react-admin';
import List from '../ui/atoms/List';

const UserProvisioningList: React.FC = (props) => {
    return (
        <List {...props} exporter={false}>
            <Datagrid optimized>
                <TextField source="id" sortable={false} />
                <TextField source="email" label="Email" sortable={false} />
                <TextField
                    source="reference"
                    label="Reference"
                    sortable={false}
                />
                <TextField source="used" label="Used" sortable={false} />
            </Datagrid>
        </List>
    );
};

export default UserProvisioningList;
