import React from 'react';
import { useRecordContext } from 'react-admin';
import { getCountryNameByCode } from '../../../../../constants/messages/countries';

const RegistrationCountryField = (props: any) => {
    const record = useRecordContext(props);
    const value = record;

    if (value.company)
        return (
            <span>
                {getCountryNameByCode(value.company.registrationCountry.code)}
            </span>
        );

    return <span>{getCountryNameByCode(value.registrationCountry.code)}</span>;
};

export default RegistrationCountryField;
