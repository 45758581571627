import { ImageField as DefaultImageField } from 'react-admin';
import { styled } from '@material-ui/core';
import React from 'react';

const ImageField = styled(DefaultImageField)(
    ({ disabled }: { disabled?: boolean }) => ({
        opacity: disabled ? 0.5 : 1,
    })
);

type InputFieldPreviewImageProps = {
    record?: Record<string, string>;
    source: string;
    disabled?: boolean;
};

const InputFieldPreviewImage = ({
    record,
    source,
    disabled,
}: InputFieldPreviewImageProps): React.ReactElement => {
    if (typeof record == 'string') {
        record = {
            [source]: record,
        };
    }

    return <ImageField record={record} source={source} disabled={disabled} />;
};

export default InputFieldPreviewImage;
