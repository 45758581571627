const useDebounceCallback = (
    cb: (...args: any[]) => void,
    milliseconds = 500
) => {
    let timeout: NodeJS.Timeout | null = null;

    return (...args: any[]) => {
        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            cb(...args);
        }, milliseconds);
    };
};

export default useDebounceCallback;
