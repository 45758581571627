import AnalysisKey from '../enums/AnalysisKey';

const analysisRseKeys: AnalysisKey[] = [
    AnalysisKey.REFERENT,
    AnalysisKey.FOLLOWED_ACTION,
    AnalysisKey.CERTIFICATION_OR_LABEL,
];

const analysisSapin2Keys: AnalysisKey[] = [
    AnalysisKey.PPE,
    AnalysisKey.CONVICTION,
    AnalysisKey.SANCTIONED_COUNTRY,
    AnalysisKey.ENTITY_SANCTIONED,
    AnalysisKey.ART17_OBLIGATIONS,
    AnalysisKey.ART17_WITHOUT_REFERENT,
    AnalysisKey.ART17_WITHOUT_4_ACTIONS,
    AnalysisKey.ART8_WITHOUT_REFERENT,
];

const analysisMessages = {
    PPE: 'Présence d’une Personne Politiquement Exposée (PPE)',
    CONVICTION:
        'Condamnation pour corruption, trafic d’influence, délit de favoritisme, etc.',
    SANCTIONED_COUNTRY:
        'Relation d’affaires avec un pays ou une entreprise ressortissant d’un pays sous sanctions internationales',
    ENTITY_SANCTIONED:
        'Relation d’affaire actuelle ou à venir avec un organisme, entreprise et/ou personne physique originaire d’un pays sous sanctions internationales',
    ART17_OBLIGATIONS:
        "Entreprise remplissant les seuils d'éligibilité de l’article 17 mais ayant répondu non à la question sur l’article 17",
    ART17_WITHOUT_REFERENT:
        "Entreprise assujettie aux obligations de l'article 17 sans référent contre la corruption",
    ART17_WITHOUT_4_ACTIONS:
        "Entreprise assujettie aux obligations de l'article 17 n’ayant pas coché les quatre dispositifs",
    ART8_WITHOUT_REFERENT:
        "Entreprise assujettie aux obligations de l'article 8 sans référent contre la corruption",
    REFERENT: 'Entreprise possédant un référent',
    FOLLOWED_ACTION: 'Entreprise possédant une action suivie',
    CERTIFICATION_OR_LABEL:
        'Entreprise possédant une certification ou un label',
};

export { analysisRseKeys, analysisSapin2Keys, analysisMessages };
