import React, { useContext, useEffect, useMemo } from 'react';
import { FieldProps, FormContextType } from '@rjsf/utils';
import formatPathToFlattenedProperty from './formatPathToFlattenedProperty';
import PrekFormContext from './PrekFormContext';
import valueFromType from './valueFromType';

const CustomCheckbox = function (props: FieldProps) {
    const { recordValue, setRecordValue }: FormContextType =
        useContext(PrekFormContext);

    const path = formatPathToFlattenedProperty(props.idSchema.$id);
    const thisRecordValue = recordValue[path];

    useEffect(() => {
        if (thisRecordValue) {
            const formDataValue = valueFromType(
                thisRecordValue,
                props.schema.type as string
            );

            setTimeout(() => props.onChange(formDataValue), 0);
        }
    }, []);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;

        const formDataValue = valueFromType(
            newValue,
            props.schema.type as string
        );

        props.onChange(formDataValue);

        setRecordValue((prev: Record<string, unknown>) => {
            return {
                ...prev,
                [path]: newValue,
            };
        });
    };

    const title = useMemo<string>(() => {
        if (props.schema?.title) {
            return props.schema.title;
        }

        if (
            (props.schema?.items as Record<string, unknown>)?.properties &&
            typeof (props.schema?.items as Record<string, unknown>)
                ?.properties === 'object'
        ) {
            const values = Object.values(
                (props.schema?.items as Record<string, unknown>)
                    ?.properties as Record<string, unknown>
            ) as Record<string, unknown>[];

            return '[' + values.map((value) => value.title).join(', ') + ']';
        }

        return '';
    }, [props.schema?.title, props.schema?.items]);

    return (
        <div id={props.idSchema.$id}>
            <label>
                <input
                    type="checkbox"
                    name={props.idSchema.$id}
                    checked={recordValue[path]}
                    onChange={onChange}
                />
                {title}
            </label>
        </div>
    );
};

export default CustomCheckbox;
