import ArrayOption from './interfaces/ArrayOption';

export interface ProjectPurposesParent {
    label: string;
    value: string;
    children: Record<string, Omit<ProjectPurposesParent, 'children'>>;
}

export const buildWorkDestinationOptions = (
    projectPurposes: Record<string, ProjectPurposesParent>
): ArrayOption[] => {
    const options: ArrayOption[] = [];

    Object.values(projectPurposes).forEach(
        (projectPurposesParent: ProjectPurposesParent) => {
            options.push({
                id: projectPurposesParent.value,
                name: projectPurposesParent.label,
                disabled: true,
            });
            Object.values(projectPurposesParent.children).forEach(
                (
                    projectPurposesChildren: Omit<
                        ProjectPurposesParent,
                        'children'
                    >
                ) => {
                    options.push({
                        id: `${projectPurposesParent.value}-${projectPurposesChildren.value}`,
                        name: projectPurposesChildren.label,
                    });
                }
            );
        }
    );

    return options;
};

type ProjectPurposesType = Record<
    string,
    {
        label: string;
        value: string;
        children: Record<
            string,
            {
                label: string;
                value: string;
            }
        >;
    }
>;

const projectPurposes: ProjectPurposesType = {
    BAAT: {
        label: 'Bâtiments agricoles',
        value: 'BAAT',
        children: { TBAA: { label: 'Bâtiments agricoles', value: 'TBAA' } },
    },
    BAIN: {
        label: 'Bâtiments indéterminés',
        value: 'BAIN',
        children: { TBAI: { label: 'Bâtiments indéterminés', value: 'TBAI' } },
    },
    ESEX: {
        label: 'Espace extérieur',
        value: 'ESEX',
        children: {
            TESE: { label: 'Espace extérieur', value: 'TESE' },
            AJTS: {
                label: 'Aire de jeux, skatepark, terrain de sport',
                value: 'AJTS',
            },
            ESVE: { label: 'Espaces verts, Parcs et Jardins', value: 'ESVE' },
            PDSC: { label: 'Parcs de stationnement couverts', value: 'PDSC' },
            PARK: { label: 'Parking extérieur', value: 'PARK' },
        },
    },
    ESMS: {
        label: 'Etablissement de santé / Médico-social',
        value: 'ESMS',
        children: {
            TESM: {
                label: 'Etablissement de santé / Médico-social',
                value: 'TESM',
            },
            CEMO: { label: "Centre d'éducation motrice (CEM)", value: 'CEMO' },
            CRTR: {
                label: 'Centre de Réinsertion par le Travail (CRT)',
                value: 'CRTR',
            },
            CDEF: {
                label: "Centre Départemental de l'Enfance et de la Famille (CDEF)",
                value: 'CDEF',
            },
            CAMS: { label: 'Centre Médico-Social', value: 'CAMS' },
            CLPS: { label: 'Clinique psychiatrique', value: 'CLPS' },
            ESSR: {
                label: 'Etablissement de soins de suite et de réadaptation (SSR)',
                value: 'ESSR',
            },
            ESAT: {
                label: "Etablissement ou service d'aide par le travail (ESAT)",
                value: 'ESAT',
            },
            FODA: { label: "Foyer d'accueil / Foyer-Logement", value: 'FODA' },
            HOCL: { label: 'Hopital / Clinique', value: 'HOCL' },
            HADO: { label: 'Hospitalisation à Domicile (HAD)', value: 'HADO' },
            IMED: {
                label: 'Institut médico-éducatif (CME/IME)',
                value: 'IMED',
            },
            ITEP: {
                label: 'Institut thérapeutique, éducatifs et pédagogiques (ITEP)',
                value: 'ITEP',
            },
            MASP: {
                label: "Maison d'accueil spécialisée (MAS)",
                value: 'MASP',
            },
            MECS: {
                label: "Maison d'enfants à caractère sanitaire ou social (MECS)",
                value: 'MECS',
            },
            EHMR: {
                label: 'Maison de retraite / Ehpad / Ehpa / Mapa / MAPAD / Marpa / Usld',
                value: 'EHMR',
            },
            MDAD: { label: 'Maison des adolescents (MDA)', value: 'MDAD' },
            CAME: {
                label: 'Pôle santé (cabinet médical, maison médicale)',
                value: 'CAME',
            },
            SAAN: { label: 'Santé Animale', value: 'SAAN' },
            SESS: {
                label: "Service d'Education Spécialisée et de Soins A Domicile (SESSAD)",
                value: 'SESS',
            },
        },
    },
    IMCO: {
        label: 'Immobilier commercial',
        value: 'IMCO',
        children: {
            TIMC: { label: 'Immobilier commercial', value: 'TIMC' },
            BOCO: { label: 'Boîte commerciale', value: 'BOCO' },
            CECO: { label: 'Centre commercial', value: 'CECO' },
            CDPI: { label: "Commerce de pied d'immeuble", value: 'CDPI' },
            GHHS: {
                label: 'Galerie (hors hyper ou supermarché)',
                value: 'GHHS',
            },
            GAHS: { label: "Galerie d'hyper ou de supermarché", value: 'GAHS' },
            GRMA: { label: 'Grand magasin', value: 'GRMA' },
            HYSU: { label: 'Hyper et supermarché', value: 'HYSU' },
            REPA: {
                label: "Retail Park / Magasin d'usine / Parc d'activités commerciales",
                value: 'REPA',
            },
        },
    },
    IMEN: {
        label: "Immobilier d'entreprise",
        value: 'IMEN',
        children: {
            TIME: { label: "Immobilier d'entreprise", value: 'TIME' },
            BURE: { label: 'Bureau', value: 'BURE' },
            DACE: { label: 'Data-center', value: 'DACE' },
            LOAC: {
                label: "Locaux d'activités (atelier, usine)",
                value: 'LOAC',
            },
            LOST: { label: 'Logistique / stockage / Entrepôts', value: 'LOST' },
        },
    },
    IMRE: {
        label: 'Immobilier résidentiel',
        value: 'IMRE',
        children: {
            TIMR: { label: 'Immobilier résidentiel', value: 'TIMR' },
            CAMP: { label: "Camping / Aire d'accueil", value: 'CAMP' },
            HETE: { label: 'Hébergement temporaire', value: 'HETE' },
            RHDL: { label: 'Hôtel', value: 'RHDL' },
            LOCO: { label: 'Logement collectif', value: 'LOCO' },
            LISC: {
                label: 'Logement intermédiaire / semi-collectif',
                value: 'LISC',
            },
            LOIG: {
                label: 'Logements individuels/Maisons individuelles Groupée (MIG)/Beguinage/Maisons en bande',
                value: 'LOIG',
            },
            MIDI: { label: 'Maison individuelle diffuse', value: 'MIDI' },
        },
    },
    INFR: {
        label: 'Infrastructure',
        value: 'INFR',
        children: {
            TINF: { label: 'Infrastructure', value: 'TINF' },
            AEGP: { label: 'Aéroport', value: 'AEGP' },
            CENT: {
                label: 'Centrale (électrique, nucléaire, éolienne…)',
                value: 'CENT',
            },
            CHUR: {
                label: 'Chaufferie urbaine, réseau de chaleur/froid',
                value: 'CHUR',
            },
            DECH: { label: 'Déchetterie', value: 'DECH' },
            ECUR: { label: 'Eclairage urbain', value: 'ECUR' },
            FOUR: { label: 'Fourrière', value: 'FOUR' },
            GARE: { label: 'Gare', value: 'GARE' },
            OACH: {
                label: "Ouvrage d'art  (pont, passerelle, tunnel, barrage...)",
                value: 'OACH',
            },
            PORT: { label: 'Port', value: 'PORT' },
            RCAE: {
                label: 'Réseau cable aérien(aérotram,téléphérique, remontée mécanique)',
                value: 'RCAE',
            },
            REPE: { label: "Réseau d'eau pluviale", value: 'REPE' },
            REPO: { label: "Réseau d'eau potable", value: 'REPO' },
            REUA: {
                label: "Réseau d'eau usée / Assainissement",
                value: 'REUA',
            },
            REGZ: { label: 'Réseau de gaz', value: 'REGZ' },
            REEL: { label: 'Réseau électrique', value: 'REEL' },
            REFE: {
                label: 'Réseau ferroviaire (tramway, métro, train)',
                value: 'REFE',
            },
            REFL: { label: 'Réseau fluvial', value: 'REFL' },
            RIRR: { label: 'Réseau irrigation', value: 'RIRR' },
            RMAR: { label: 'Réseau maritime', value: 'RMAR' },
            RERO: { label: 'Réseau routier', value: 'RERO' },
            RETE: { label: 'Réseau télécom', value: 'RETE' },
            SEPC: {
                label: "Station d'épuration / Pompage / Captage d'eau",
                value: 'SEPC',
            },
        },
    },
    LABO: {
        label: 'Laboratoires',
        value: 'LABO',
        children: {
            TLAB: { label: 'Laboratoires', value: 'TLAB' },
            LAME: { label: 'Analyses Médicales', value: 'LAME' },
            LERE: { label: 'Enseignement / Recherche', value: 'LERE' },
            LIPR: { label: 'Industriel /Production', value: 'LIPR' },
        },
    },
    LOEN: {
        label: "Locaux d'enseignement",
        value: 'LOEN',
        children: {
            TLOE: { label: "Locaux d'enseignement", value: 'TLOE' },
            COLL: { label: 'Collège', value: 'COLL' },
            PETI: {
                label: "Crèche / Petite enfance / Etablissement d'éveil",
                value: 'PETI',
            },
            PRIM: { label: 'Elémentaire', value: 'PRIM' },
            ENSF: {
                label: 'Enseignement supérieur / Formation',
                value: 'ENSF',
            },
            EREA: {
                label: "Etablissement Régional d'Enseignement Adapté (EREA)",
                value: 'EREA',
            },
            LYCE: { label: 'Lycée', value: 'LYCE' },
            MAPR: { label: 'Maternelle', value: 'MAPR' },
            PERI: { label: 'Périscolaire', value: 'PERI' },
        },
    },
    LOCU: {
        label: 'Loisirs / Culturel',
        value: 'LOCU',
        children: {
            TLOC: { label: 'Loisirs / Culturel', value: 'TLOC' },
            AULO: {
                label: 'Autre local (local associatif, office du tourisme…)',
                value: 'AULO',
            },
            EQCU: {
                label: 'Bibliothèque / Médiathèque / Archives',
                value: 'EQCU',
            },
            CEEQ: { label: 'Centre équestre', value: 'CEEQ' },
            EQSP: { label: 'Equipements sportifs / piscine', value: 'EQSP' },
            GOLF: { label: 'Golf', value: 'GOLF' },
            LDCU: { label: 'Lieu de culte', value: 'LDCU' },
            BHLC: { label: 'Monument Historique', value: 'BHLC' },
            MUSE: { label: 'Musée', value: 'MUSE' },
            PTLO: { label: 'Parc à thème / de loisir', value: 'PTLO' },
            SDAC: {
                label: "Salle d'audition, conférence, congrès",
                value: 'SDAC',
            },
            CSDS: {
                label: 'Salle de spectacle, cinéma, théatre, opéra',
                value: 'CSDS',
            },
            SPSF: {
                label: 'Salle polyvalente, salle des fêtes',
                value: 'SPSF',
            },
            TTSP: { label: 'Thermes, Thalasso, SPA', value: 'TTSP' },
        },
    },
    REGE: {
        label: 'Résidences gérées',
        value: 'REGE',
        children: {
            TREG: { label: 'Résidences gérées', value: 'TREG' },
            RDAF: { label: "Résidence d'affaires", value: 'RDAF' },
            RETO: { label: 'Résidence de tourisme', value: 'RETO' },
            RPET: { label: 'Résidence pour étudiants', value: 'RPET' },
            RSNM: {
                label: 'Résidence sénior (non médicalisée)',
                value: 'RSNM',
            },
        },
    },
    REST: {
        label: 'Restauration',
        value: 'REST',
        children: {
            THOR: { label: 'Restauration', value: 'THOR' },
            RCOL: { label: 'Restauration collective', value: 'RCOL' },
            RCOM: { label: 'Restauration commerciale', value: 'RCOM' },
        },
    },
    ADSP: {
        label: 'Service public',
        value: 'ADSP',
        children: {
            TADS: { label: 'Service public', value: 'TADS' },
            BACO: {
                label: 'Bâtiments communaux, départementaux...',
                value: 'BACO',
            },
            CICR: {
                label: 'Cimetières / Crématorium / Funérarium',
                value: 'CICR',
            },
            DEFE: { label: 'Défense', value: 'DEFE' },
            PLDD: { label: 'Prison / Lieu de détention', value: 'PLDD' },
            SECI: {
                label: 'Sécurité civile (police, pompier…)',
                value: 'SECI',
            },
            TRIB: { label: 'Tribunaux', value: 'TRIB' },
        },
    },
};

export default projectPurposes;
