import { TopToolbar, Button, useNotify, CreateButton } from 'react-admin';
import { apiUrl, httpClient } from '../dataProvider';
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react';

const ListActions = () => {
    const notify = useNotify();

    const recalculateMainReference = () => {
        httpClient(`${apiUrl}/references/mainReference`, {
            method: 'POST',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('');
                }
                notify(`MainReference will soon be recalculated`, 'success');
            })
            .catch(() => {
                notify(`MainReference could not be recalculated`, 'warning');
            });
    };

    return (
        <TopToolbar>
            <Button
                onClick={recalculateMainReference}
                label="Recalculate mainReference"
            >
                <RefreshIcon />
            </Button>
            <CreateButton />
        </TopToolbar>
    );
};

export default ListActions;
