import {
    parsePhoneNumber,
    isValidPhoneNumber,
    CountryCode,
    parseIncompletePhoneNumber,
} from 'libphonenumber-js';
import React, { useMemo, useState } from 'react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import countries from '../../../data/countries';
import { MenuItem, Select, TextField, TextFieldProps } from '@material-ui/core';
import styled from 'styled-components';
import { useInput } from 'react-admin';
import validatePhoneNumber from '../../../utils/validatePhoneNumber';

const InputContainer = styled.div<{ $error?: boolean }>`
    display: flex;
    align-items: baseline;
    gap: 1em;
    ${(props) => !props.$error && 'margin-bottom: 1.73em'}
`;

type PhoneNumberInputProps = TextFieldProps & {
    source: string;
    validate?: (...args: any[]) => any;
};

const countryChoices = countries.map(
    (country: { name: string; id: string }) => {
        return {
            id: country.id,
            name: `${getUnicodeFlagIcon(country.id)} ${country.id}`,
        };
    }
);

const PhoneNumberInput = (props: PhoneNumberInputProps) => {
    const {
        input: { value, onChange },
    } = useInput(props);

    // initial phone number
    const initialParsedPhone = useMemo(() => {
        const defaultValue = value || '';
        try {
            if (isValidPhoneNumber(defaultValue)) {
                return parsePhoneNumber(defaultValue);
            } else {
                return defaultValue;
            }
        } catch (e) {
            return '';
        }
    }, [value]);

    // country selector
    const [country, setCountry] = useState<CountryCode>(
        initialParsedPhone?.country ?? 'FR'
    );
    // formatted phone number
    const [phone, setPhone] = useState<string>(
        initialParsedPhone?.formatNational?.() ?? ''
    );

    const onCountryChange = (e: React.BaseSyntheticEvent) => {
        const newCountry = e.target.value.toString();
        try {
            if (isValidPhoneNumber(phone, newCountry)) {
                const newPhoneNumber = parsePhoneNumber(phone, newCountry);
                setPhone(newPhoneNumber.formatNational());
                onChange(newPhoneNumber.number);
            } else {
                const incompletePhoneNumber = parseIncompletePhoneNumber(phone);
                setPhone(incompletePhoneNumber);
                onChange(incompletePhoneNumber);
            }
        } catch (error) {
        } finally {
            setCountry(newCountry);
        }
    };

    const onPhoneChange = (e: React.BaseSyntheticEvent) => {
        const newPhone = e.target.value.toString();
        const incompletePhoneNumber = parseIncompletePhoneNumber(newPhone);
        try {
            if (isValidPhoneNumber(newPhone, country)) {
                const newPhoneNumber = parsePhoneNumber(newPhone, country);
                setPhone(newPhoneNumber.formatNational());
                onChange(newPhoneNumber.number);
            } else {
                setPhone(incompletePhoneNumber);
                onChange(incompletePhoneNumber);
            }
        } catch (error) {
            setPhone(incompletePhoneNumber);
            onChange(incompletePhoneNumber);
        }
    };

    const isValidPhoneValidator = () => {
        return !validatePhoneNumber(phone, country)
            ? {
                  error: false,
                  helperText: '',
              }
            : {
                  error: true,
                  helperText: 'Phone number is not valid',
              };
    };

    return (
        <React.Fragment>
            <InputContainer $error={isValidPhoneValidator().error}>
                <Select
                    value={country}
                    label="Phone number country"
                    variant="filled"
                    margin="dense"
                    disabled={props.disabled}
                    onChange={onCountryChange}
                >
                    {countryChoices.map((choice) => {
                        return (
                            <MenuItem value={choice.id}>{choice.name}</MenuItem>
                        );
                    })}
                </Select>
                <TextField
                    label={props.label}
                    value={phone}
                    variant="filled"
                    margin="dense"
                    fullWidth
                    disabled={props.disabled}
                    onChange={onPhoneChange}
                    error={isValidPhoneValidator().error}
                    helperText={isValidPhoneValidator().helperText}
                />
            </InputContainer>
        </React.Fragment>
    );
};

export default PhoneNumberInput;
