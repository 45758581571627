import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

function useDebounce<T>(data: T, miliSeconds = 500): T {
    const [debounced, setDebounced] = useState<T>(data);
    const debouncedFunc = useMemo(
        () => debounce((_data) => setDebounced(_data), miliSeconds),
        [miliSeconds]
    );

    useEffect(() => {
        debouncedFunc(data);
    }, [debouncedFunc, data]);

    return debounced;
}

export default useDebounce;
