import SubResource from '../../../utils/SubResource';
import { TextField, TextInput, Tab, Loading } from 'react-admin';
import React, { useMemo } from 'react';
import SitesInput from '../../../Contract/SitesInput';
import useHttpClientJson from '../../../utils/useHttpClientJson';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import Alert from '@material-ui/lab/Alert';

type IdResponse = {
    list: unknown[];
};

const ProjectAdminContent = ({ resource, record }: any) => {
    const projectId = record.id;

    const { loading, error, data } = useHttpClientJson<IdResponse>(
        `projects/${projectId}/participations`
    );

    const ids = useMemo(
        () =>
            data?.list?.map((participation: any) => participation.site.id) ??
            [],
        [data]
    );

    if (loading) {
        return <Loading />;
    }

    if (error || ids.length === 0) {
        return (
            <Alert severity="info">
                No Participation, therefore no project-admin can be added
            </Alert>
        );
    }

    const matchSuggestion = (filter: string, choice: any) => {
        return ids.includes(choice.id);
    };

    return (
        <SubResource
            target="project-admins"
            name="Project Admin"
            resource={resource}
            record={record}
            deleteIdentificator="siteId"
            filters={[
                <TextInput
                    label="Name or reference"
                    source="search"
                    size="small"
                    alwaysOn
                />,
            ]}
            addInput={
                <SitesInput
                    fullWidth
                    label="Site"
                    source="siteId"
                    matchSuggestion={matchSuggestion}
                />
            }
            deleteButton
        >
            <TextField
                sortable={false}
                source="site.company.name"
                label="Name"
            />
            <TextField
                source="site.mainReference.value"
                label="Reference"
                sortable={false}
            />
        </SubResource>
    );
};

const ProjectAdminTab = (props: any) => {
    return (
        <Tab label="Admins" path="admins" icon={<HowToRegIcon />} {...props}>
            <ProjectAdminContent
                resource={props.resource}
                record={props.record}
            />
        </Tab>
    );
};

export default ProjectAdminTab;
