import React, { useMemo } from 'react';
import { BusinessDayValue } from './WorkingDay';
import BusinessHoursDay from './BusinessHoursDay';
import TextField from '@material-ui/core/TextField';
import { useInput } from 'ra-core';
import { makeStyles } from '@material-ui/core';
import WorkingTimeUi from './WorkingTimeUi';
import styled from 'styled-components';

const useStyles = makeStyles({
    container: {
        fontFamily: 'work sans, cursive',
    },
});

const InvisibleTextField = styled(TextField)`
    display: none;
`;

export type BusinessHoursValue = Record<BusinessHoursDay, BusinessDayValue>;

const WorkingTimeInput = ({
    value: initialValue,
    ...props
}: // because we manually declare react-admin, ts is lost and won't accept TextInputProps
// @ts-ignore
TextInputProps) => {
    const classes = useStyles();

    const {
        input: { name, onChange, value, ...rest },
        meta: { touched, error },
        isRequired,
    } = useInput({
        ...props,
        initialValue: initialValue || undefined,
    });

    const objectValue = useMemo<BusinessHoursValue | undefined>(
        () => (value ? JSON.parse(value) : undefined),
        [value]
    );

    const copyPrevious =
        (dayToFill: BusinessHoursDay, dayToCopy?: BusinessHoursDay) => () => {
            if (!objectValue || !dayToCopy) {
                return;
            }
            onDayChange(dayToFill)(objectValue[dayToCopy]);
        };

    const onDayChange =
        (day: BusinessHoursDay) => (dayValue: BusinessDayValue) => {
            onChange(
                JSON.stringify({
                    ...objectValue,
                    [day]: dayValue,
                })
            );
        };

    return (
        <div className={classes.container}>
            <WorkingTimeUi
                objectValue={objectValue}
                onChange={onDayChange}
                copyPrevious={copyPrevious}
                disabled={props.disabled}
            />
            <InvisibleTextField
                name={name}
                label={props.label}
                error={!!(touched && error)}
                helperText={touched && error}
                required={isRequired}
                value={value}
                {...rest}
            />
        </div>
    );
};

export default WorkingTimeInput;
