import user from './User';
import movements from './Movements';
import contracts from './Contract';
import siteJoin from './Invitation/SiteJoin';
import preQualification from './Invitation/PreQualification';
import partnerships from './Partnerships';
import sites from './Sites';
import projects from './Projects';
import prequalifications from './Prequalifications';
import userProvisioning from './UserProvisioning';
import userProvisioningError from './UserProvisioningError';
import workpackages from './Workpackages';
import {
    Domain as DomainIcon,
    Email as InvitationIcon,
    ListAlt as ListAltIcon,
    Archive as ArchiveIcon,
} from '@material-ui/icons';
import { ResourceName } from './ResourceName';
import registrationCountries from './registrationCountries';
import PublicIcon from '@material-ui/icons/Public';
import referenceTypes from './referenceTypes';
import companies from './Companies';

type Resource = {
    name: string;
    options?: {
        label: string;
        menuParent?: string;
        isMenuParent?: boolean;
    };
    icon?: React.FC;
    show?: React.FC;
    list?: React.FC;
    create?: React.FC;
    edit?: React.FC;
};

export const resources: Resource[] = [
    {
        name: ResourceName.COMPANIES,
        options: {
            label: 'Companies',
            menuParent: ResourceName.ORGANISATIONS,
        },
        ...companies,
    },
    {
        name: ResourceName.ORGANISATIONS,
        options: { label: 'Organisations', isMenuParent: true },
        icon: DomainIcon,
    },
    {
        name: ResourceName.SITES,
        options: {
            label: 'Sites',
            menuParent: ResourceName.ORGANISATIONS,
        },
        ...sites,
    },
    {
        name: ResourceName.MOVEMENTS,
        options: { label: 'Movements', menuParent: ResourceName.ORGANISATIONS },
        ...movements,
    },
    {
        name: ResourceName.PREQUALIFICATIONS,
        options: {
            label: 'Prequalifications',
            isMenuParent: true,
        },
        icon: ListAltIcon,
    },
    {
        name: ResourceName.FORMS,
        options: {
            label: 'Forms',
            menuParent: ResourceName.PREQUALIFICATIONS,
        },
        ...prequalifications,
    },
    {
        name: ResourceName.PARTNERSHIPS,
        options: {
            label: 'Partnerships',
            menuParent: ResourceName.PREQUALIFICATIONS,
        },
        ...partnerships,
    },
    {
        name: ResourceName.SITE_JOIN,
        options: {
            label: 'v1 - Join Sites',
            menuParent: ResourceName.INVITATION,
        },
        ...siteJoin,
    },
    {
        name: ResourceName.INVITATION,
        options: {
            label: 'Invitations',
            isMenuParent: true,
        },
        icon: InvitationIcon,
    },
    {
        name: ResourceName.PREQUALIFICATIONS_INVITATION,
        options: {
            label: 'Prequalifications',
            menuParent: ResourceName.INVITATION,
        },
        ...preQualification,
    },
    {
        name: ResourceName.CONTRACTS,
        options: { label: 'Contracts' },
        ...contracts,
    },
    {
        name: ResourceName.USERS,
        options: {
            label: 'v1 - Users',
        },
        ...user,
    },
    {
        name: ResourceName.USER_PROVISIONING,
        options: {
            label: 'UserProvisioning',
        },
        ...userProvisioning,
    },
    {
        name: ResourceName.USER_PROVISIONG_ERROR,
        options: {
            label: 'UserProvisioningError',
        },
        ...userProvisioningError,
    },
    {
        name: 'Projects folder',
        options: { label: 'Projects', isMenuParent: true },
        icon: DomainIcon,
    },
    {
        name: ResourceName.PROJECTS,
        options: {
            label: 'List',
            menuParent: 'Projects folder',
        },
        ...projects,
    },
    {
        name: ResourceName.WORKPACKAGES,
        options: {
            label: 'Workpackages',
            menuParent: 'Projects folder',
        },
        icon: ArchiveIcon,
        ...workpackages,
    },
    {
        name: 'Internationalization',
        options: { label: 'Internationalization', isMenuParent: true },
        icon: PublicIcon,
    },
    {
        name: ResourceName.REGISTRATION_COUNTRIES,
        options: {
            label: 'Registration countries',
            menuParent: 'Internationalization',
        },
        ...registrationCountries,
    },
    {
        name: ResourceName.REFERENCE_TYPES,
        options: {
            label: 'Reference types',
            menuParent: 'Internationalization',
        },
        ...referenceTypes,
    },
];
