import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import {
    Toolbar as DefaultToolbar,
    required,
    useRedirect,
    useNotify,
    TextInput,
    SimpleForm,
    Create,
    SaveButton,
} from 'react-admin';
import { ResourceName } from '../ResourceName';

const Toolbar = styled(DefaultToolbar)`
    display: flex;
    justify-content: space-between;
`;

const CancelButton = (props: any): React.ReactElement => {
    const redirect = useRedirect();

    return (
        <Button
            variant="contained"
            startIcon={<Cancel />}
            onClick={() =>
                redirect('/workpackage-recommendations/recommendations')
            }
        >
            Cancel
        </Button>
    );
};

const CreateToolbar = (props: any): React.ReactElement => (
    <Toolbar {...props}>
        <SaveButton label="Create" />
        <CancelButton />
    </Toolbar>
);

const WorkpackagesCreate = (props: any): React.ReactElement => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (result: any) => {
        notify(`Workpackage created. Code: ${result.data.code}`);
        redirect(`/${ResourceName.WORKPACKAGES}`);
    };

    const onFailure = (error: any) => {
        notify(error.message, 'warning');
    };

    return (
        <Create
            {...props}
            title="Create workpackage"
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <SimpleForm toolbar={<CreateToolbar />}>
                <TextInput source="name" validate={[required()]} fullWidth />
            </SimpleForm>
        </Create>
    );
};

export default WorkpackagesCreate;
