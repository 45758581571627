import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import { Tooltip, makeStyles } from '@material-ui/core';
import Button from './Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles({
    splitContainer: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
    },
    buttonLeftContainer: {
        width: 'auto',
        flex: 1,
    },
    buttonLeft: {
        width: '100%',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRightWidth: 0,
    },
    buttonSplit: {
        width: '35px',
        minWidth: '35px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeftWidth: '1px',
    },
});

export type SplitDropdownProps = {
    id: string;
    children: Array<React.ReactElement>;
    title: string;
    tooltip?: string;
    disabled?: boolean;
    disabledSplit?: boolean;
    width?: string;
    onClick: (event: React.MouseEvent) => void;
    loading?: boolean;
};

const SplitDropdown: React.FC<SplitDropdownProps> = ({
    id,
    children,
    title,
    disabled = false,
    disabledSplit = false,
    onClick = () => {},
    tooltip = '',
    loading = false,
}: SplitDropdownProps) => {
    const [open, setOpen] = useState(false);
    const ref = React.useRef(null);
    const classes = useStyles();

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.splitContainer} ref={ref}>
            <Tooltip title={tooltip}>
                <span className={classes.buttonLeftContainer}>
                    <Button
                        className={classes.buttonLeft}
                        onClick={onClick}
                        disabled={disabled || disabledSplit || loading}
                    >
                        {title}
                    </Button>
                </span>
            </Tooltip>
            <Button
                className={classes.buttonSplit}
                aria-controls={id}
                aria-haspopup="true"
                onClick={handleClick}
                disabled={disabled || loading}
            >
                <KeyboardArrowDownIcon />
            </Button>
            <Menu
                id={id}
                anchorEl={ref.current}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {React.Children.map(children, (child: React.ReactElement) =>
                    React.cloneElement(child, {
                        onClick: (event: React.MouseEvent) => {
                            setOpen(false);
                            if (child.props.onClick && !loading) {
                                child.props.onClick(event);
                            }
                        },
                    })
                )}
            </Menu>
        </div>
    );
};

export default SplitDropdown;
