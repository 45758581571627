import React from 'react';
import { TabProps, TextField, TextInput } from 'react-admin';
import SubResource from '../utils/SubResource';
import SitesInput from './SitesInput';

const ContractSites = ({ resource, record }: typeof TabProps) => {
    return (
        <SubResource
            target="sites"
            name="Contract Subscription"
            resource={resource}
            record={record}
            filters={[
                <TextInput
                    label="Name or reference"
                    source="search"
                    size="small"
                    alwaysOn
                />,
            ]}
            addInput={<SitesInput fullWidth label="Site" source="siteId" />}
            deleteButton
        >
            <TextField sortable={false} source="company.name" label="Name" />
            <TextField
                sortable={false}
                source="mainReference.value"
                label="Reference"
            />
        </SubResource>
    );
};

export default ContractSites;
