import React from 'react';
import ProjectParticipationItem from '../../../interfaces/ProjectParticipationItem';
import ProjectParticipationRole from '../../../interfaces/ProjectParticipationRole';
import { createStyles, List, Theme } from '@material-ui/core';
import ParticipationLine from './ParticipationLine';
import ContractorParticipationList from './ContractorParticipationList';
import { makeStyles } from '@material-ui/core/styles';

type ParticipationListProps = {
    participations: ProjectParticipationItem[];
    role: ProjectParticipationRole;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
    })
);

const ParticipationList = ({
    participations,
    role,
}: ParticipationListProps) => {
    const classes = useStyles();
    if (role === ProjectParticipationRole.CONTRACTOR) {
        return <ContractorParticipationList participations={participations} />;
    }

    return (
        <List component="div" disablePadding className={classes.root}>
            {participations.map((participation) => (
                <ParticipationLine
                    key={participation.id}
                    participation={participation}
                />
            ))}
        </List>
    );
};

export default ParticipationList;
