import React from 'react';
import { useRecordContext } from 'react-admin';
import { getCountryNameByCode } from '../../../../../constants/messages/countries';

const AddressField = (props: any) => {
    const record = useRecordContext(props);
    const value = record;

    return (
        <span>
            {value.street} {value.postalCode} {value.city} {value.country}{' '}
            {getCountryNameByCode(value.registrationCountry.code)}
        </span>
    );
};

export default AddressField;
