import React, { useState } from 'react';
import {
    Box,
    FormControlLabel,
    makeStyles,
    Switch,
    Typography,
} from '@material-ui/core';
import {
    Create,
    TextInput,
    SelectInput,
    AutocompleteInput,
    required,
    ReferenceInput,
} from 'react-admin';
import CompanyReferenceInput from './CompanyReferenceInput';
import { ResourceName } from '../ResourceName';
import RegistrationCountryReferenceArrayInput from './RegistrationCountryReferenceArrayInput';
import MySimpleForm, { SimpleFormViewProps } from '../utils/MySimpleForm';
import PhoneNumberInput from '../ui/atoms/inputs/PhoneNumberInput';
import validatePhoneNumber from '../utils/validatePhoneNumber';

const useStyles = makeStyles({
    sectionBorder: {
        border: '1px solid rgb(0 0 0 / 20%)',
        borderRadius: '10px',
        padding: '8px 12px',
    },
});

const optionText = ({ code }: { code: string }) => code;

const SiteCreate: React.FC = (props) => {
    const classes = useStyles();

    const [isNewCompany, setCompanyCreation] = useState(false);

    return (
        <Create {...props}>
            <MySimpleForm>
                {(props: SimpleFormViewProps) => {
                    const { change } = props.form;

                    return (
                        <>
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant="h5">Company</Typography>
                                <FormControlLabel
                                    control={<Switch />}
                                    label={
                                        isNewCompany
                                            ? 'NewCompany'
                                            : 'Existing Company'
                                    }
                                    value={isNewCompany}
                                    onChange={(
                                        event: React.ChangeEvent<{}>,
                                        checked: boolean
                                    ) => {
                                        change('company.id', undefined);
                                        setCompanyCreation(checked);
                                    }}
                                />
                            </Box>
                            <Box className={classes.sectionBorder}>
                                {isNewCompany ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box display="flex">
                                            <Box flex={1} mr="1em">
                                                <ReferenceInput
                                                    label="Registration country"
                                                    source="company.registrationCountryId"
                                                    reference={
                                                        ResourceName.REGISTRATION_COUNTRIES
                                                    }
                                                    filterToQuery={optionText}
                                                    fullWidth
                                                    isRequired
                                                    validate={required()}
                                                >
                                                    <AutocompleteInput
                                                        optionText={optionText}
                                                    />
                                                </ReferenceInput>
                                            </Box>
                                            <Box flex={1} ml="1em">
                                                <TextInput
                                                    source="company.name"
                                                    label="Name"
                                                    fullWidth
                                                    validate={required()}
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex">
                                            <Box flex={1} mr="1em">
                                                <TextInput
                                                    source="company.website"
                                                    fullWidth
                                                    label="Website"
                                                />
                                            </Box>
                                            <Box flex={1} ml="1em">
                                                <TextInput
                                                    disabled
                                                    source="company.logo"
                                                    fullWidth
                                                    label="Logo"
                                                />
                                            </Box>
                                        </Box>
                                        <RegistrationCountryReferenceArrayInput
                                            source="company.references"
                                            dependOnSource="company.registrationCountryId"
                                            label="References"
                                            fullWidth
                                            validate={required()}
                                            isCompany
                                        />
                                    </Box>
                                ) : (
                                    <CompanyReferenceInput
                                        reference={ResourceName.COMPANIES}
                                        source="company.id"
                                        label="Company"
                                        isNew={isNewCompany}
                                        validate={required()}
                                    />
                                )}
                            </Box>
                            <Typography variant="h5">Site</Typography>
                            <Box className={classes.sectionBorder}>
                                <Box display="flex">
                                    <Box flex={1} mr="1em">
                                        <SelectInput
                                            source="site.status"
                                            defaultValue="ACTIVE"
                                            label="Status"
                                            fullWidth
                                            choices={[
                                                {
                                                    id: 'ACTIVE',
                                                    name: 'ACTIVE',
                                                },
                                                {
                                                    id: 'INACTIVE',
                                                    name: 'INACTIVE',
                                                },
                                            ]}
                                            validate={required()}
                                        />
                                    </Box>
                                    <Box flex={1} ml="1em">
                                        <PhoneNumberInput
                                            source="phone"
                                            label="phone"
                                            validate={validatePhoneNumber}
                                        />
                                    </Box>
                                </Box>

                                <TextInput
                                    fullWidth
                                    source="site.address.street"
                                    label="Street"
                                    validate={required()}
                                />
                                <Box display="flex">
                                    <Box flex={1} mr="1em">
                                        <TextInput
                                            source="site.address.postalCode"
                                            label="Postal Code"
                                            fullWidth
                                            validate={required()}
                                        />
                                    </Box>
                                    <Box flex={1} ml="1em">
                                        <TextInput
                                            source="site.address.city"
                                            label="City"
                                            fullWidth
                                            validate={required()}
                                        />
                                    </Box>
                                    <Box flex={1} ml="1em">
                                        <ReferenceInput
                                            label="Registration country"
                                            source="site.registrationCountryId"
                                            reference={
                                                ResourceName.REGISTRATION_COUNTRIES
                                            }
                                            filterToQuery={optionText}
                                            fullWidth
                                            isRequired
                                            validate={required()}
                                        >
                                            <AutocompleteInput
                                                optionText={optionText}
                                            />
                                        </ReferenceInput>
                                    </Box>
                                </Box>

                                <RegistrationCountryReferenceArrayInput
                                    source="site.references"
                                    dependOnSource="site.registrationCountryId"
                                    label="References"
                                    fullWidth
                                    validate={required()}
                                    isSite
                                />
                            </Box>
                        </>
                    );
                }}
            </MySimpleForm>
        </Create>
    );
};

export default SiteCreate;
