import FlagIcon from '@material-ui/icons/Flag';
import RegistrationCountryShow from './show/RegistrationCountryShow';
import RegistrationCountriesList from './RegistrationCountriesList';
import RegistrationCountryCreate from './RegistrationCountryCreate';

export default {
    list: RegistrationCountriesList,
    show: RegistrationCountryShow,
    create: RegistrationCountryCreate,
    icon: FlagIcon,
};
