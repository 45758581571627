import React from 'react';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';

const StatusField = (props: any) => {
    const { source } = props;
    const record = useRecordContext(props);
    const status = get(record, source);

    return <span>{status}</span>;
};

export default StatusField;
