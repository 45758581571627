import React from 'react';
import useIconColor from './hooks/useIconColor';
import SVG, { IconProps } from './SVG';

const Marker: React.FC<IconProps> = ({
    size,
    color,
}: IconProps): React.ReactElement => {
    const iconColor = useIconColor(color);

    return (
        <SVG size={size} viewBox="0 0 24 24">
            <g fill={iconColor}>
                <g id="atom/ic/place">
                    <path
                        stroke="#FFFFFF"
                        d="M12,21.2395092 C12.215687,20.9874435 12.452479,20.7037307 12.705521,20.3919468 C13.5171158,19.391946 14.3287766,18.3106978 15.0855178,17.1890993 C16.0812106,15.7133403 16.9044725,14.280585 17.4963181,12.9373069 C18.1511712,11.4510244 18.5,10.1229832 18.5,9 C18.5,5.40614237 15.5938576,2.5 12,2.5 C8.40614237,2.5 5.5,5.40614237 5.5,9 C5.5,10.1229832 5.84882884,11.4510244 6.50368188,12.9373069 C7.09552746,14.280585 7.91878936,15.7133403 8.91448222,17.1890993 C9.67122337,18.3106978 10.4828842,19.391946 11.294479,20.3919468 C11.547521,20.7037307 11.784313,20.9874435 12,21.2395092 Z M12,12 C10.3438576,12 9,10.6561424 9,9 C9,7.34385763 10.3438576,6 12,6 C13.6561424,6 15,7.34385763 15,9 C15,10.6561424 13.6561424,12 12,12 Z"
                    ></path>
                </g>
            </g>
        </SVG>
    );
};

export default Marker;
