import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputProps } from 'react-admin';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useInput } from 'ra-core';
import WysiwygColorPicker from './WysiwygColorPicker';

const WysiwygInputContainer = styled.div<{ $disabled?: boolean }>`
    ${(props) =>
        props.$disabled &&
        `
            > div {            
              opacity: 0.6;
            }
      `}

    .wysiwyg-toolbar {
        background-color: rgba(0, 0, 0, 0.04);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: 1px ${(props) => (props.$disabled ? 'dotted' : 'solid')}
            rgba(0, 0, 0, 0.42);
    }

    .wysiwyg-editor {
        position: relative;
        transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        background-color: rgba(0, 0, 0, 0.04);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 27px 12px 10px;

        :before {
            left: 0;
            right: 0;
            bottom: 0;
            content: '\\00a0';
            position: absolute;
            transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1)
                0ms;
            border-bottom: 1px
                ${(props) => (props.$disabled ? 'dotted' : 'solid')}
                rgba(0, 0, 0, 0.42);
            pointer-events: none;
        }

        .public-DraftStyleDefault-block {
            margin: 0;
        }
    }
`;

const StyledEditor = styled(Editor)`
    display: block;
`;

const Textarea = styled.textarea`
    width: 100%;
    height: 400px;
`;

const stateToHtml = (state: EditorState): string =>
    draftToHtml(convertToRaw(state.getCurrentContent()));

const htmlToState = (html?: string): EditorState => {
    const { contentBlocks, entityMap } = htmlToDraft(html ?? '');

    return EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlocks, entityMap)
    );
};

const useEditorState = (
    html: string
): {
    editorState: EditorState;
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
} => {
    const [editorState, setEditorState] = useState(() => htmlToState(html));

    useEffect(() => {
        const htmlState = stateToHtml(editorState);
        if (!htmlState && html) {
            setEditorState(htmlToState(html));
        }
    }, [html, editorState]);

    return { editorState, setEditorState };
};

const WysiwygInput: React.FC<typeof InputProps> = ({ source, disabled }) => {
    const { input } = useInput({ source });

    const { editorState, setEditorState } = useEditorState(input.value);

    const onInternalChange = (state: EditorState) => {
        setEditorState(state);
        input.onChange(stateToHtml(state));
    };

    return (
        <WysiwygInputContainer $disabled={disabled}>
            <StyledEditor
                readOnly={disabled}
                editorState={editorState}
                wrapperClassName="wysiwyg-wrapper"
                editorClassName="wysiwyg-editor"
                toolbarClassName="wysiwyg-toolbar"
                onEditorStateChange={onInternalChange}
                toolbar={{
                    options: [
                        'inline',
                        'list',
                        'textAlign',
                        'colorPicker',
                        'link',
                        'remove',
                        'history',
                    ],
                    list: {
                        options: ['unordered', 'ordered'],
                    },
                    colorPicker: { component: WysiwygColorPicker },
                }}
            />
            <Textarea value={stateToHtml(editorState)} disabled hidden />
        </WysiwygInputContainer>
    );
};

export default WysiwygInput;
