import React from 'react';
import {
    TextInput,
    TextField,
    Datagrid,
    EditButton,
    useListContext,
} from 'react-admin';
import List from '../ui/atoms/List';
import WorkpackageListActions from './WorkpackageListActions';
import PreprocessNameField from './fields/PreprocessNameField';
import DistanceListField from './fields/DistanceListField';
import useDataWithRecommendations from './hook/useDataWithRecommendations';
import WorkpackagesListActionsButton from './components/WorkpackagesListActionsButton';
import { LinearProgress } from '@material-ui/core';

const ListContent = ({ ...props }: any) => {
    const { data: originalData = {}, ids = [] } = useListContext();

    const { data, isLoading } = useDataWithRecommendations(ids, originalData);

    if (!Object.keys(data ?? {}).length) {
        return <LinearProgress />;
    }

    return (
        <Datagrid {...props} data={data} selectedIds={[]}>
            <TextField label="ID" source="id" sortable={false} />
            <TextField label="Name" source="name" sortable={false} />
            <PreprocessNameField
                isLoading={isLoading}
                label="Preprocess names"
                source="preprocessName"
                sortable={false}
            />
            <TextField label="Count" source="count" sortable={false} />
            <DistanceListField
                isLoading={isLoading}
                label="Recommendations"
                source="distances"
                sortable={false}
            />
            <EditButton label="Rename" />
            <WorkpackagesListActionsButton label="Actions" />
        </Datagrid>
    );
};

const WorkpackagesList: React.FC = (props) => {
    return (
        <List
            actions={<WorkpackageListActions />}
            {...props}
            filter={{ isReferentiel: false }}
            filters={[
                <TextInput label="Name" source="name" alwaysOn resettable />,
            ]}
        >
            <ListContent />
        </List>
    );
};
export default WorkpackagesList;
