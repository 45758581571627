import { useMap } from 'react-leaflet';

export const FLY_DURATION = 500;
export const POPUP_DELAY = 200;
export const MAX_ZOOM = 18;

const deferCallback = ({
    callback,
    duration,
}: {
    callback: () => void;
    duration: number;
}) => {
    setTimeout(() => {
        callback();
    }, duration);
};

const useMoveMap = (callback?: () => void) => {
    const map = useMap();

    return (latitude: number, longitude: number, zoom: number) => {
        const newPosition = {
            lat: latitude,
            lng: longitude,
        };
        const distance = map.distance(map.getCenter(), newPosition);
        const currentZoom = map.getZoom();

        if (distance < 1 && currentZoom === MAX_ZOOM) {
            callback?.();

            return;
        } else if (distance > 50_000) {
            if (callback) {
                deferCallback({ callback, duration: POPUP_DELAY });
            }
            map.setView(
                {
                    lat: latitude,
                    lng: longitude,
                },
                zoom
            );
        } else {
            if (callback) {
                deferCallback({
                    callback,
                    duration: FLY_DURATION + POPUP_DELAY,
                });
            }
            map.flyTo(
                {
                    lat: latitude,
                    lng: longitude,
                },
                zoom,
                {
                    duration: FLY_DURATION / 1000,
                }
            );
        }
    };
};

export default useMoveMap;
