const valueFromType = (
    value: boolean,
    type: string
): string | number | boolean => {
    switch (type) {
        case 'number': {
            return Number(value);
        }
        case 'string': {
            return value ? 'string' : '';
        }
        default: {
            return value;
        }
    }
};

export default valueFromType;
