import React from 'react';
import { TabbedShowLayout, Show } from 'react-admin';
import ReferenceOrderTab from './referenceOrderTab/ReferenceOrderTab';

const ReferenceOrderTitle = ({ record }: { record?: { code?: string } }) => {
    return <span>Country {record?.code ?? 'view'}</span>;
};

const RegistrationCountryShow: React.FC = (props: any) => {
    return (
        <Show title={<ReferenceOrderTitle />} {...props}>
            <TabbedShowLayout>
                <ReferenceOrderTab {...props} />
            </TabbedShowLayout>
        </Show>
    );
};

export default RegistrationCountryShow;
