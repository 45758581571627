import { Box, styled, Typography } from '@material-ui/core';
import React from 'react';
import { useListContext, CreateButton, Button } from 'react-admin';
import Empty from '../ui/icons/Empty';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { ResourceName } from '../ResourceName';
import theme from '../themes/utils/theme';

const Container = styled(Box)(() => ({
    color: theme.colors.gray,
}));

const Controls = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2em',
}));

const EmptyListPage = () => {
    const { basePath, resource, hasCreate } = useListContext();

    return (
        <Container textAlign="center" m={1}>
            <Empty size="215px" color={theme.colors.gray} />
            <Typography variant="h4" paragraph>
                {`No ${resource} yet.`}
            </Typography>
            {hasCreate && (
                <Typography variant="body1">
                    Do you want to add one ?
                </Typography>
            )}
            <Controls>
                {hasCreate && (
                    <CreateButton size="medium" basePath={basePath} />
                )}
                {resource === 'projects' && (
                    <Button
                        size="medium"
                        label="Search"
                        component={Link}
                        to={{ pathname: ResourceName.PROJECTS_SEARCH }}
                    >
                        <SearchIcon />
                    </Button>
                )}
            </Controls>
        </Container>
    );
};

export default EmptyListPage;
