import React from 'react';
import { Tab, TabProps, TextField, TextInput } from 'react-admin';
import SubResource from '../../utils/SubResource';
import ReferenceUpdate from '../../references/ReferenceUpdate';
import ReferenceInput from '../../references/ReferenceInput';
import { ResourceName } from '../../ResourceName';

const SiteReferencesTab = ({
    ...props
}: typeof TabProps): React.ReactElement => {
    const { resource, record } = props;

    return (
        <Tab {...props} label="References">
            <SubResource
                target={ResourceName.REFERENCES}
                name="References"
                resource={resource}
                record={record}
                deleteButton
                disabledDelete={(data) => Object.keys(data).length < 2}
                filters={[
                    <TextInput
                        label="Value"
                        source="value"
                        size="small"
                        alwaysOn
                        resettable
                    />,
                ]}
                addInput={
                    <ReferenceInput
                        registrationCountryId={record?.registrationCountry.id}
                        resourceId={record?.id}
                        resourceType="site"
                    />
                }
            >
                <TextField sortable={false} source="id" label="Id" />
                <TextField sortable={false} source="value" label="Value" />
                <TextField sortable={false} source="type" label="Type" />
                <ReferenceUpdate
                    registrationCountryId={record?.registrationCountry?.id}
                    resourceType="site"
                />
            </SubResource>
        </Tab>
    );
};

export default SiteReferencesTab;
