import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import React from 'react';

type ParticipationErrorProps = {
    open: boolean;
    handleClose: () => void;
};

const DeleteParticipationError = ({
    open,
    handleClose,
}: ParticipationErrorProps) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="impossible-to-delete-participation"
        >
            <DialogTitle id="impossible-to-delete-participation">
                Impossible to delete participation
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This participation has subcontractor. Please delete the
                    subcontractor(s) first
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteParticipationError;
