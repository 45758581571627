/* eslint-disable react/jsx-key */
import React from 'react';

import {
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    ReferenceField,
    TextInput,
    SelectInput,
} from 'react-admin';
import { ResourceName } from '../ResourceName';
import List from '../ui/atoms/List';
import { contractTypeChoice } from './SelectContractTypeInput';

const ContractList: React.FC = (props) => (
    <List
        filters={[
            <TextInput label="Name" source="name" alwaysOn resettable />,
            <SelectInput
                source="types"
                choices={contractTypeChoice}
                label="Type"
            />,
        ]}
        {...props}
    >
        <Datagrid rowClick="show">
            <TextField source="id" sortable={false} />
            <TextField source="name" />
            <TextField source="type" />
            <ReferenceField
                label="Source Organisation"
                source="sourceCompanyId"
                reference={ResourceName.COMPANIES}
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Referent User"
                source="referentId"
                reference={ResourceName.USERS}
            >
                <TextField source="email" />
            </ReferenceField>
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <BooleanField source="isActive" />
            <BooleanField source="prequalificationUpdate" />
            <BooleanField source="carbonPath" />
            <BooleanField source="apiJson" />
            <BooleanField source="apiHtml" />
            <BooleanField source="apiPdf" />
        </Datagrid>
    </List>
);

export default ContractList;
