import React from 'react';
import { Create } from 'react-admin';
import MySimpleForm from '../utils/MySimpleForm';
import Project, { NatureOfMarket, NatureOfWork } from '../interfaces/Project';
import transformStringToDate from '../utils/transformStringToDate';
import ProjectFields from './ProjectFields';

export const natureOfMarketChoices = [
    { id: NatureOfMarket.PRIVATE, name: 'Private' },
    { id: NatureOfMarket.PUBLIC, name: 'Public' },
];

export const natureOfWorkChoices = [
    { id: `${NatureOfWork.NEW_CONSTRUCTION}`, name: 'New construction' },
    {
        id: `${NatureOfWork.REHABILITATION}`,
        name: 'Rehabilitation - Renovation',
    },
    {
        id: `${NatureOfWork.DEMOLITION}`,
        name: 'Demolition - Dismantling',
    },
];

export const deleteEmptyOperatingDaysAndHour = (
    days?: string
): string | null => {
    if (!days) {
        return null;
    }

    const parsedDays = JSON.parse(days);

    const result = Object.keys(parsedDays).map((day) => {
        if (parsedDays?.[day])
            return {
                [day]: parsedDays[day].filter(
                    (day: any) => day.from !== '' || day.to !== ''
                ),
            };

        return null;
    });

    return JSON.stringify(Object.assign({}, ...result));
};

const transformProjectData = (data: Project): any => {
    return {
        ...data,
        picture: data.picture?.rawFile ?? undefined,
        natureOfMarket: data.natureOfMarket !== NatureOfMarket.PRIVATE,
        DOC: transformStringToDate(data.DOC),
        DOS: transformStringToDate(data.DOS),
        workDestination:
            data.workDestination?.length > 0
                ? JSON.stringify(data.workDestination)
                : undefined,
        estimatedDeliveryDate: transformStringToDate(
            data.estimatedDeliveryDate
        ),
        operatingDaysAndHour: deleteEmptyOperatingDaysAndHour(
            data.operatingDaysAndHour
        ),
    };
};

const ProjectCreate = (props: any) => {
    return (
        <Create transform={transformProjectData} {...props}>
            <MySimpleForm>
                <ProjectFields hasCreate />
            </MySimpleForm>
        </Create>
    );
};

export default ProjectCreate;
