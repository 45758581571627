import React from 'react';
import { Datagrid, TextField, DateField } from 'react-admin';
import Search from '../ui/atoms/inputs/Search';
import List from '../ui/atoms/List';
import MovementsListRepresentation from '../interfaces/movements/movementsListRepresentation';

type MovementsField = {
    record?: MovementsListRepresentation;
    label: string;
};

const EntityField = ({ record }: MovementsField): React.ReactElement => (
    <>{record?.site?.id ? 'SITE' : 'COMPANY'}</>
);
const IdField = ({ record }: MovementsField): React.ReactElement => (
    <>{record?.site?.id || record?.company?.id}</>
);
const ReferenceField = ({ record }: MovementsField): React.ReactElement => (
    <>{record?.site?.reference || record?.company?.reference}</>
);
const NameField = ({ record }: MovementsField): React.ReactElement => (
    <>{record?.site?.company.name || record?.company?.name}</>
);

const MovementsList: React.FC = (props) => (
    <List filters={<Search />} {...props}>
        <Datagrid
            rowClick={(
                id: string,
                basePath: string,
                record: MovementsListRepresentation
            ) =>
                record.site?.id
                    ? `/sites/${record.site?.id}/show`
                    : `/companies/${record.company?.id}/show`
            }
        >
            <IdField label="Id" />
            <TextField source="type" />
            <ReferenceField label="Reference" />
            <NameField label="Name" />
            <EntityField label="Entity" />
            <DateField source="createdAt" label="Date" showTime />
            <TextField source="previousValue" />
            <TextField source="newValue" />
        </Datagrid>
    </List>
);

export default MovementsList;
