import { FieldProps, useRecordContext } from 'react-admin';
import { get } from 'lodash';
import React from 'react';

type ConcatField = Omit<typeof FieldProps, 'source'> & { sources: string[] };

const ConcatField = (props: ConcatField) => {
    const { sources } = props;
    const record = useRecordContext(props);
    const result = sources.map((source) => get(record, source)).join(' ');

    return <span>{result}</span>;
};

export default ConcatField;
