import React, { useMemo } from 'react';
import {
    TextInput,
    required,
    DateInput,
    AutocompleteInput,
    NumberInput,
    RadioButtonGroupInput,
    SelectArrayInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    Labeled,
    BooleanInput,
} from 'react-admin';
import { Box, Typography, styled } from '@material-ui/core';
import { countries } from '../constants/messages/countries';
import { NatureOfMarket, NatureOfWork } from '../interfaces/Project';
import { ResourceName } from '../ResourceName';
import WorkingTimeInput from '../ui/atoms/inputs/WorkingTime/WorkingTimeInput';
import projectPurposes, {
    buildWorkDestinationOptions,
} from './projectPurposes';
import ArrayOption from './interfaces/ArrayOption';
import InputFieldPreviewImage from './InputFieldPreviewImage';
import WysiwygInput from '../ui/atoms/inputs/wysiwyg/WysiwygInput';
import ImageInput from '../ui/atoms/inputs/ImageInput';
import PhoneNumberInput from '../ui/atoms/inputs/PhoneNumberInput';
import validatePhoneNumber from '../utils/validatePhoneNumber';

const Contact = styled(Typography)(({ disabled }: { disabled?: boolean }) => ({
    opacity: disabled ? 0.5 : 1,
}));

const Block = styled(Box)(() => ({
    '&': {
        display: 'flex',
    },
}));

const Container = styled(Box)(() => ({
    '&': {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const Tags = styled(Box)(({ disabled }: { disabled?: boolean }) => ({
    '&': {
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? 0.6 : 1,
    },
}));

const natureOfMarketChoices = [
    { id: NatureOfMarket.PRIVATE, name: 'Private' },
    { id: NatureOfMarket.PUBLIC, name: 'Public' },
];

const natureOfWorkChoices = [
    { id: `${NatureOfWork.NEW_CONSTRUCTION}`, name: 'New construction' },
    {
        id: `${NatureOfWork.REHABILITATION}`,
        name: 'Rehabilitation - Renovation',
    },
    {
        id: `${NatureOfWork.DEMOLITION}`,
        name: 'Demolition - Dismantling',
    },
];

type ProjectFieldProps = {
    disabled?: boolean;
    hasCreate?: boolean;
};

const ProjectFields = ({
    disabled,
    hasCreate,
}: ProjectFieldProps): React.ReactElement => {
    const workDestinationOptions = useMemo<ArrayOption[]>(
        () => buildWorkDestinationOptions(projectPurposes),
        []
    );

    return (
        <Container>
            {!hasCreate && <TextInput source="id" disabled />}
            {!hasCreate && (
                <TextInput source="scanNumber" label="Scanned" disabled />
            )}
            <TextInput
                source="name"
                label="Name"
                validate={required()}
                disabled={disabled}
            />
            <TextInput
                source="exploreId"
                label="Id Explore"
                disabled={disabled}
            />
            <WysiwygInput
                source="description"
                label="Description"
                resettable
                disabled={disabled}
            />

            <Block>
                <Box flex={1} mr="1em">
                    <TextInput
                        source="street"
                        label="Address"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
                <Box flex={1} mr="1em" ml="1em">
                    <TextInput
                        source="postalCode"
                        label="Postal code"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
                <Box flex={1} ml="1em">
                    <TextInput
                        source="poBox"
                        label="PO box"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
            </Block>

            <Block>
                <Box flex={1} mr="1em">
                    <TextInput
                        source="businessPostCode"
                        label="Cedex"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
                <Box flex={1} mr="1em" ml="1em">
                    <TextInput
                        source="city"
                        label="City"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
                <Box flex={1} ml="1em">
                    <AutocompleteInput
                        defaultValue="FR"
                        source="countryCode"
                        label="Country"
                        choices={Object.entries(countries).map(
                            ([key, value]) => ({
                                id: key,
                                name: value,
                            })
                        )}
                        fullWidth
                        validate={required()}
                        disabled={disabled}
                    />
                </Box>
            </Block>

            <Block>
                <Box flex={1} mr="1em">
                    <NumberInput
                        source="latitude"
                        label="Latitude"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
                <Box flex={1} ml="1em">
                    <NumberInput
                        source="longitude"
                        label="Longitude"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
            </Block>

            <ImageInput
                source="picture"
                label="Picture"
                accept="image/*"
                placeholder="Drop your file here"
                disabled={disabled}
                options={{ disabled: disabled }}
            >
                <InputFieldPreviewImage source="src" disabled={disabled} />
            </ImageInput>

            <RadioButtonGroupInput
                source="natureOfMarket"
                choices={natureOfMarketChoices}
                defaultValue={NatureOfMarket.PRIVATE}
                label="Nature of market"
                disabled={disabled}
            />

            <RadioButtonGroupInput
                source="natureOfWork"
                label="Nature of work"
                choices={natureOfWorkChoices}
                row={false}
                disabled={disabled}
            />

            <Block>
                <Tags flex={1} mr="1em" disabled={disabled}>
                    <SelectArrayInput
                        source="workDestination"
                        label="Work destination"
                        choices={workDestinationOptions}
                        fullWidth
                        disabled={disabled}
                    />
                </Tags>
                <Box flex={1} ml="1em">
                    <NumberInput
                        source="surface"
                        label="Surface (SDP)"
                        placeholder="m²"
                        min={0}
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
            </Block>

            <Block>
                <Box flex={1} mr="1em">
                    <DateInput
                        source="DOC"
                        label="Opening declaration of construction site (DOC)"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
                <Box flex={1} ml="1em">
                    <DateInput
                        source="DOS"
                        label="Service order date (DOS)"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
            </Block>

            <DateInput
                source="estimatedDeliveryDate"
                label="Estimated delivery date"
                disabled={disabled}
            />

            <NumberInput
                source="estimatedWorkforce"
                label="Estimated workforce"
                min={0}
                disabled={disabled}
            />

            <Block>
                <Box flex={1} mr="1em">
                    <TextInput
                        source="amountHT"
                        label="Amount HT"
                        placeholder="€"
                        min={0}
                        fullWidth
                        disabled={disabled}
                        format={(n: number) =>
                            new Intl.NumberFormat('en-US').format(n)
                        }
                        parse={(s: string) =>
                            parseInt(s.replace(/,/gi, ''), 10)
                        }
                    />
                </Box>
                <Box flex={1} ml="1em">
                    <TextInput
                        source="amountTTC"
                        label="Amount TTC"
                        placeholder="€"
                        min={0}
                        fullWidth
                        disabled={disabled}
                        format={(n: number) =>
                            new Intl.NumberFormat('en-US').format(n)
                        }
                        parse={(s: string) =>
                            parseInt(s.replace(/,/gi, ''), 10)
                        }
                    />
                </Box>
            </Block>
            <Block>
                <Tags flex={1} mr="1em" disabled={disabled}>
                    <ReferenceArrayInput
                        source="projectBuildingTags"
                        reference={ResourceName.PROJECTS_BUILDING_TAGS}
                        label="Building labels"
                        fullWidth
                        filterToQuery={(search: string) => ({ search })}
                    >
                        <AutocompleteArrayInput optionText="tagName" />
                    </ReferenceArrayInput>
                </Tags>
                <Tags flex={1} ml="1em" disabled={disabled}>
                    <ReferenceArrayInput
                        source="projectSiteTags"
                        reference={ResourceName.PROJECTS_SITE_TAGS}
                        label="Site labels"
                        fullWidth
                        filterToQuery={(search: string) => ({ search })}
                    >
                        <AutocompleteArrayInput optionText="tagName" />
                    </ReferenceArrayInput>
                </Tags>
            </Block>
            <Labeled label="Maquette BIM">
                <Block>
                    <Box flex={1} mr="1em">
                        <BooleanInput
                            source="bimAtDesign"
                            label="Conception"
                            disabled={disabled}
                        />
                    </Box>
                    <Box flex={1} mr="1em">
                        <BooleanInput
                            source="bimAtExecution"
                            label="Execution"
                            disabled={disabled}
                        />
                    </Box>
                </Block>
            </Labeled>

            <Labeled label="Working time">
                <WorkingTimeInput
                    source="operatingDaysAndHour"
                    disabled={disabled}
                />
            </Labeled>

            <hr />

            <Contact variant="h6" disabled={disabled}>
                Contact
            </Contact>

            <hr />

            <Block>
                <Box flex={1} mr="1em">
                    <TextInput
                        source="firstName"
                        label="First name"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
                <Box flex={1} ml="1em">
                    <TextInput
                        source="lastName"
                        label="Last name"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
            </Block>
            <Block>
                <Box flex={1} mr="1em">
                    <TextInput
                        source="email"
                        label="Email"
                        tyme="email"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
                <Box flex={1} ml="1em">
                    <PhoneNumberInput
                        source="phone"
                        label="phone"
                        disabled={disabled}
                        validate={validatePhoneNumber}
                    />
                </Box>
            </Block>
        </Container>
    );
};

export default ProjectFields;
