import React from 'react';
import { ExploreProject } from '../hooks/useExplore';
import MapMarker from './MapMarker';
import MarkerClusterGroup from 'react-leaflet-cluster';
import customMarkerCluster from './customMarkerCluster';

type MapContentProps = {
    results: ExploreProject[];
};

const MapMarkers = ({ results }: MapContentProps) => {
    return (
        <>
            <MarkerClusterGroup
                iconCreateFunction={customMarkerCluster}
                showCoverageOnHover={false}
            >
                {results.map((result) => (
                    <MapMarker key={result.exploreId} project={result} />
                ))}
            </MarkerClusterGroup>
        </>
    );
};

export default MapMarkers;
