import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import AnalysisDetail from './AnalysisDetail';
import AnalysisKey from '../enums/AnalysisKey';

type AnalysisDetailTooltipProps = {
    children: React.ReactElement;
    prequalificationId: string;
    expectedValue: number;
    keys: AnalysisKey[];
};

const AnalysisDetailTooltip = ({
    children,
    prequalificationId,
    expectedValue,
    keys,
}: AnalysisDetailTooltipProps) => {
    const [open, setOpen] = useState(false);

    return (
        <Tooltip
            title={
                <AnalysisDetail
                    open={open}
                    prequalificationId={prequalificationId}
                    expectedValue={expectedValue}
                    keys={keys}
                />
            }
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
        >
            <div>{children}</div>
        </Tooltip>
    );
};

export default AnalysisDetailTooltip;
