import styled from '@material-ui/core/styles/styled';
import React, { useMemo } from 'react';
import DefaultAddress from '../../ui/atoms/Address';
import { useHistory } from 'react-router-dom';
import MarkerIcon from '../../ui/icons/Marker';
import WorkIcon from '../../ui/icons/Work';
import { ExploreProject } from './hooks/useExplore';
import Tooltip from '../../ui/atoms/Tooltip';
import TextShortened from '../../ui/atoms/TextShortened';
import useLocationToCoordinates from './hooks/useLocationToCoordinates';
import theme from '../../themes/utils/theme';

const Container = styled('div')(() => ({
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius,
    whiteSpace: 'nowrap',
}));

const Name = styled('h3')(() => ({
    marginTop: 0,
}));

const Informations = styled('section')(() => ({
    marginBottom: '50px',
}));

const Line = styled('span')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
}));

const ErrorLine = styled(Line)(({ error }: { error: boolean }) => ({
    filter: error ? 'opacity(0.5)' : '0px',
    width: '100%',
}));

const Address = styled(DefaultAddress)(() => ({
    marginLeft: '8px',
}));

const ProjectOwner = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '8px',
    width: 'calc(100% - 32px)',
}));

const POTitle = styled('div')(() => ({
    color: theme.colors.gray,
}));

const LinkButton = styled('span')(() => ({
    textDecoration: 'none',
    fontWeight: 'bold',
    color: `${theme.colors.primary1} !important`,
}));

const LinkContainer = styled('section')(() => ({
    position: 'absolute',
    left: 0,
    bottom: 0,
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.colors.raBorderColor}`,
    borderBottomLeftRadius: theme.radius,
    borderBottomRightRadius: theme.radius,
    cursor: 'pointer',
    transition: `linear ${theme.transition} background`,

    '&:hover': {
        background: theme.colors.raGray,
    },
}));

type ProjectCardProps = {
    data: ExploreProject;
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
};

const ProjectCard = ({ data, className, onClick, style }: ProjectCardProps) => {
    const project = useMemo(
        () => ({
            name: data.name,
            exploreId: data.exploreId,
            street: data.address,
            postalCode: data.postalCode,
            countryCode: data.country,
            city: data.city,
            latitude: data.geolocation.lat,
            longitude: data.geolocation.lon,
        }),
        [data]
    );

    const PROJECT_CREATE_HANDLE = '/projects/create';

    const locationToCoordinates = useLocationToCoordinates();

    const history = useHistory();

    const redirectToCreateUrl = () => {
        if (data.geolocation.lat === 0 || data.geolocation.lon === 0) {
            locationToCoordinates(
                `${data.address} ${data.city} ${data.country}`
            )
                .then(({ latitude, longitude }) => {
                    const fixedProject = {
                        ...project,
                        latitude,
                        longitude,
                    };

                    history.push(
                        `${PROJECT_CREATE_HANDLE}?source=${JSON.stringify(
                            fixedProject
                        )}`
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            history.push(
                `${PROJECT_CREATE_HANDLE}?source=${JSON.stringify(project)}`
            );
        }
    };

    const isGeolocationValid =
        data.geolocation.lat !== 0 && data.geolocation.lon !== 0;

    const projectOwner = data.projectOwner.join(', ');

    return (
        <Container className={className} style={style}>
            <Informations onClick={onClick}>
                <Tooltip title={project.name}>
                    <Name>
                        <TextShortened>{project.name}</TextShortened>
                    </Name>
                </Tooltip>
                <ErrorLine error={!isGeolocationValid}>
                    <MarkerIcon size="24px" />
                    <Address
                        street={project.street}
                        postalCode={project.postalCode}
                        city={project.city}
                    />
                </ErrorLine>
                <Line>
                    <WorkIcon size="24px" color={theme.colors.gray} />
                    <ProjectOwner>
                        <POTitle>Project ownership</POTitle>
                        <Tooltip title={projectOwner}>
                            <TextShortened>
                                <strong>{projectOwner}</strong>
                            </TextShortened>
                        </Tooltip>
                    </ProjectOwner>
                </Line>
            </Informations>
            <LinkContainer onClick={redirectToCreateUrl}>
                <LinkButton>Join this project</LinkButton>
            </LinkContainer>
        </Container>
    );
};

export default ProjectCard;
