import React, { useState } from 'react';
import { Button, TopToolbar, useNotify } from 'react-admin';
import { apiUrl, httpClient } from '../dataProvider';

const PrequalificationListActions = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const calculAnalysis = () => {
        setLoading(true);

        httpClient(`${apiUrl}/analysis`, {
            method: 'POST',
        })
            .then((res) => {
                if (res.status === 200) {
                    notify(`Recalculate in progress`);
                }
            })
            .catch(() => {
                notify('Error: analysis could not be calculated', 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <TopToolbar>
            <Button
                disabled={loading}
                onClick={calculAnalysis}
                label="Calculate all analysis"
            />
        </TopToolbar>
    );
};

export default PrequalificationListActions;
