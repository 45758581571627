import { Tab, useQueryWithStore, useRecordContext } from 'react-admin';
import React from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { LinearProgress } from '@material-ui/core';
import AddParticipationButton from './participations/AddParticipationButton';
import ProjectParticipationList from './participations/ProjectParticipationList';
import Alert from '@material-ui/lab/Alert';
import ParticipationProvider from './participations/ParticipationProvider';

const ProjectBundlesTab = ({ ...props }) => {
    const record = useRecordContext(props);

    const { loaded, data, error, refetch } = useQueryWithStore({
        type: 'getList',
        resource: `projects/${record.id}/participations`,
    });

    return (
        <Tab
            label="Participations"
            path="participations"
            icon={<PersonAddIcon />}
            {...props}
        >
            {!loaded && !error ? (
                <div>
                    <LinearProgress variant="query" />
                </div>
            ) : (
                <ParticipationProvider projectId={record.id} refresh={refetch}>
                    <>
                        {error ? (
                            <Alert severity="error">
                                Could not get the participation list!
                            </Alert>
                        ) : (
                            <ProjectParticipationList data={data} />
                        )}

                        <AddParticipationButton />
                    </>
                </ParticipationProvider>
            )}
        </Tab>
    );
};

export default ProjectBundlesTab;
