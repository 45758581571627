import { Assignment as ContractIcon } from '@material-ui/icons';

import ContractList from './ContractList';
import ContractEdit from './ContractEdit';
import ContractCreate from './ContractCreate';
import ContractShow from './ContractShow';

export default {
    list: ContractList,
    edit: ContractEdit,
    icon: ContractIcon,
    create: ContractCreate,
    show: ContractShow,
};
