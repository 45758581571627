import React from 'react';
import { Datagrid, TextField, TextInput, SelectInput } from 'react-admin';
import List from '../ui/atoms/List';
import { makeStyles } from '@material-ui/core/styles';
import StatusField from '../ui/atoms/fields/organisations/StatusField';

const useStyles = makeStyles({
    bold: { fontWeight: 'bold' },
});

enum CompanyStatus {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
}

const CompaniesList: React.FC = (props) => {
    const classes = useStyles();

    return (
        <List
            filters={[
                <TextInput
                    label="Search"
                    source="search"
                    alwaysOn
                    resettable
                />,
                <SelectInput
                    source="status"
                    label="Status"
                    resettable
                    alwaysOn
                    choices={[
                        {
                            id: CompanyStatus.ACTIVE,
                            name: CompanyStatus.ACTIVE,
                        },
                        {
                            id: CompanyStatus.CLOSED,
                            name: CompanyStatus.CLOSED,
                        },
                    ]}
                />,
            ]}
            {...props}
        >
            <Datagrid optimized rowClick="show">
                <TextField source="id" sortable={false} />
                <TextField
                    sortable={false}
                    source="mainReference.value"
                    label="Reference"
                />
                <TextField
                    source="name"
                    className={classes.bold}
                    sortable={false}
                />
                <StatusField
                    source="headquarter.status"
                    label="Status"
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};

export default CompaniesList;
