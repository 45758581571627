const theme = {
    transition: '0.2s',
    radius: '4px',

    colors: {
        primary1: '#03ccb9',
        black: '#000',
        graydarker: '#474747',
        graydark: '#767676',
        gray: '#8F8F8F',
        grayer: '#cccccc',
        graylight: '#f6f8f9',
        graylighter: '#f6f8f9',
        white: '#FFF',
        red: '#FC3346',
        raGray: '#fafafa',
        raBorderColor: 'rgb(204, 204, 204)',
    },
};

export const breakpoint: Record<string, number> = {
    xs: 0,
    mini: 425,
    small: 768,
    medium: 1024,
    large: 1920,
};

export default theme;
