import React from 'react';
import {
    DeleteButton,
    FormWithRedirect,
    SaveButton,
    Toolbar,
} from 'react-admin';
import { Record, RedirectionSideEffect } from 'ra-core';
import { FormProps, FormRenderProps } from 'react-final-form';
import { Card, CardContent } from '@material-ui/core';

export interface SimpleFormProps
    extends Omit<FormProps, 'onSubmit'>,
        Omit<
            React.HtmlHTMLAttributes<HTMLFormElement>,
            'onSubmit' | 'children'
        > {
    basePath?: string;
    className?: string;
    initialValues?: any;
    margin?: 'none' | 'normal' | 'dense';
    record?: Record;
    redirect?: RedirectionSideEffect;
    resource?: string;
    sanitizeEmptyValues?: boolean;
    submitOnEnter?: boolean;
    toolbar?: React.ReactElement;
    undoable?: boolean;
    variant?: 'standard' | 'outlined' | 'filled';
    warnWhenUnsavedChanges?: boolean;
}

export interface SimpleFormViewProps extends FormRenderProps {
    basePath?: string;
    className?: string;
    margin?: 'none' | 'normal' | 'dense';
    handleSubmitWithRedirect?: (redirectTo: RedirectionSideEffect) => void;
    record?: Record;
    redirect?: RedirectionSideEffect;
    resource?: string;
    save?: () => void;
    saving?: boolean;
    toolbar?: React.ReactElement;
    undoable?: boolean;
    variant?: 'standard' | 'outlined' | 'filled';
    submitOnEnter?: boolean;
    __versions?: any; // react-final-form internal prop, missing in their type
}

const nestedProps = ({ basePath, handleSubmit, ...props }: any) => props;

const MySimpleForm: React.FC<SimpleFormProps> = (props) => (
    <FormWithRedirect
        {...nestedProps(props)}
        render={(formProps: SimpleFormViewProps) => (
            // here starts the custom form layout
            <form>
                <Card elevation={0}>
                    <CardContent>
                        {typeof props.children === 'function'
                            ? props.children(formProps)
                            : props.children}
                    </CardContent>
                </Card>
                <Toolbar>
                    <SaveButton
                        disabled={formProps.saving}
                        saving={formProps.saving}
                        handleSubmitWithRedirect={
                            formProps.handleSubmitWithRedirect
                        }
                        redirect="show"
                    />
                    <DeleteButton record={formProps.record} redirect="list" />
                </Toolbar>
            </form>
        )}
    />
);

export default MySimpleForm;
