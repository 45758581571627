import React from 'react';
import { Datagrid, TextField } from 'react-admin';
import List from '../ui/atoms/List';

const UserList: React.FC = (props) => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="username" />
            <TextField source="firstName" />
            <TextField source="lastName" />
        </Datagrid>
    </List>
);

export default UserList;
