import React, { useState } from 'react';
import { Confirm, useDataProvider, useNotify } from 'react-admin';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ProjectParticipationItem from '../../../interfaces/ProjectParticipationItem';
import DeleteParticipationError from './DeleteParticipationError';
import { useParticipationContext } from './ParticipationProvider';

type DeleteParticipationButtonProps = {
    participation: ProjectParticipationItem;
};

const DeleteParticipationButton = ({
    participation,
}: DeleteParticipationButtonProps) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [participationError, setParticipationError] = useState(false);
    const dataProvider = useDataProvider();
    const { refresh } = useParticipationContext();
    const notify = useNotify();

    const removeParticipation = async (
        projectParticipation: ProjectParticipationItem
    ) => {
        setLoading(true);
        await dataProvider
            .delete('participations', {
                id: projectParticipation.id,
            })
            .then(() => {
                notify('Participation deleted', 'success');
                refresh();
            })
            .catch((error: any) => {
                if (error.status === 409 && error?.body?.context) {
                    setParticipationError(true);
                } else {
                    notify('Error: participation not delete', 'warning');
                }
            })
            .finally(() => {
                setOpen(false);
                setLoading(false);
            });
    };

    return (
        <>
            <IconButton
                edge="end"
                aria-label="delete"
                size="small"
                disabled={loading}
                onClick={() => setOpen(true)}
            >
                <DeleteIcon />
            </IconButton>
            <Confirm
                isOpen={open}
                onClose={() => setOpen(false)}
                confirm="Yes"
                cancel="No"
                onConfirm={() => removeParticipation(participation)}
                title="Do you really want to delete this participation ?"
            />
            <DeleteParticipationError
                open={participationError}
                handleClose={() => setParticipationError(false)}
            />
        </>
    );
};

export default DeleteParticipationButton;
