import React from 'react';
import {
    TextInput,
    SelectInput,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

type ReferenceArrayInputProps = {
    source: string;
    label: string;
    fullWidth: boolean;
    disabled?: boolean;
    validate?: any;
    choices?: { id: string; name: string }[];
};

const useStyles = makeStyles({
    index: { display: 'none' },
});

const ReferenceArrayInput: React.FC<ReferenceArrayInputProps> = ({
    choices = [],
    ...props
}) => {
    const classes = useStyles();

    return (
        <ArrayInput {...props}>
            <SimpleFormIterator
                getItemLabel={() => ''}
                fullWidth
                classes={classes}
            >
                <SelectInput
                    source="typeId"
                    choices={choices}
                    label="Reference type"
                    fullWidth
                />
                <TextInput source="value" label="Value" fullWidth />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default ReferenceArrayInput;
