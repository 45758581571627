import React from 'react';
import {
    Datagrid,
    TextField,
    TextInput,
    useRecordContext,
    FieldProps,
} from 'react-admin';
import List from '../ui/atoms/List';
import EmptyListPage from '../components/EmptyListPage';
import { countries } from '../constants/messages/countries';
import ListActions from './RegistrationCountriesListActions';

const TranslatedField = (props: typeof FieldProps) => {
    const record = useRecordContext(props);

    const value = record[props.source];

    if (value in countries) {
        return <>{countries[value]}</>;
    }

    return <>{value}</>;
};

const RegistrationCountriesList: React.FC = (props) => {
    return (
        <List
            actions={<ListActions />}
            filters={[
                <TextInput label="Code" source="code" alwaysOn resettable />,
            ]}
            empty={<EmptyListPage />}
            {...props}
            exporter={false}
        >
            <Datagrid optimized rowClick="show">
                <TextField source="id" sortable={false} />
                <TextField source="code" label="Code" sortable={false} />
                <TranslatedField source="code" label="Name" sortable={false} />
            </Datagrid>
        </List>
    );
};

export default RegistrationCountriesList;
