export const getObjectDiff = (
    o1: Record<string, any>,
    o2: Record<string, any>
): Record<string, any> => {
    const diff = Object.keys(o2).reduce((diff, key) => {
        if (o1[key] === o2[key]) return diff;

        return {
            ...diff,
            [key]: o1[key],
        };
    }, {});

    return diff;
};
