import React from 'react';
import { AutocompleteInput, Create, SimpleForm } from 'react-admin';
import { countries } from '../constants/messages/countries';

const RegistrationCountryCreate: React.FC = (props) => (
    <Create {...props}>
        <SimpleForm>
            <AutocompleteInput
                source="code"
                label="Registration country"
                choices={Object.entries(countries).map(([key, value]) => ({
                    id: key,
                    name: `${key} - ${value}`,
                }))}
                fullWidth
            />
        </SimpleForm>
    </Create>
);

export default RegistrationCountryCreate;
