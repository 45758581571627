import React from 'react';
import List from './List';
import Map from './map/Map';
import ProjectSearchForm from './ProjectSearchForm';
import useOpenStreetMapCoordinates from './hooks/useOpenStreetMapCoordinates';
import styled from '@material-ui/core/styles/styled';
import { breakpoint } from '../../themes/utils/theme';

export const Container = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',

    [`@media (min-width:${breakpoint.medium}px)`]: {
        flexDirection: 'row',
    },
});

const ListContainer = styled('div')(() => ({
    width: '322px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '24px 21px 0px 11px',
}));

const ProjectSearch = () => {
    const {
        exploreItem,
        onChangeSearch,
        loading,
        coordinates,
        setProjectOwner,
        projectOwnerOptions,
        onCoordinateChange,
    } = useOpenStreetMapCoordinates();

    return (
        <Container>
            <ListContainer>
                <ProjectSearchForm
                    onChange={onChangeSearch}
                    setProjectOwner={setProjectOwner}
                    projectOwnerOptions={projectOwnerOptions}
                />
                <List results={exploreItem} loading={loading} />
            </ListContainer>
            <Map
                onChange={onCoordinateChange}
                results={exploreItem}
                coordinates={coordinates}
            />
        </Container>
    );
};

export default ProjectSearch;
