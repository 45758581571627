import React from 'react';
import { TextInput, required, SelectInput } from 'react-admin';
import { Box, styled } from '@material-ui/core';
import ImageInput from '../ui/atoms/inputs/ImageInput';
import InputFieldPreviewImage from '../Projects/InputFieldPreviewImage';
import CompanyStatus from './enums/CompanyStatus';

const Container = styled(Box)(() => ({
    '&': {
        display: 'flex',
        flexDirection: 'column',
    },
}));

type ProjectFieldProps = {
    disabled?: boolean;
    hasCreate?: boolean;
};

const statusChoices = [
    {
        id: CompanyStatus.CLOSED,
        name: CompanyStatus.CLOSED,
    },
    {
        id: CompanyStatus.ACTIVE,
        name: CompanyStatus.ACTIVE,
    },
];

const CompanyFields = ({
    disabled,
    hasCreate,
}: ProjectFieldProps): React.ReactElement => {
    return (
        <Container>
            {!hasCreate && <TextInput source="id" disabled />}
            <TextInput
                source="mainReference.value"
                label="Reference"
                validate={required()}
                disabled
            />
            <TextInput
                source="name"
                label="Name"
                validate={required()}
                disabled={disabled}
            />
            <TextInput
                source="headquarterId"
                label="HeadquarterId"
                validate={required()}
                disabled
            />
            <ImageInput
                source="logo"
                label="Logo"
                accept="image/*"
                placeholder="Drop your file here"
                disabled={disabled}
                options={{ disabled: disabled }}
            >
                <InputFieldPreviewImage source="src" disabled={disabled} />
            </ImageInput>
            <TextInput
                source="registrationCountry.code"
                label="registrationCountry"
                disabled
            />
            <SelectInput
                source="status"
                label="Status"
                validate={required()}
                disabled={disabled}
                choices={statusChoices}
            />
            <TextInput source="website" label="Website" disabled={disabled} />
        </Container>
    );
};

export default CompanyFields;
