import React from 'react';
import { AutocompleteInput, useQueryWithStore } from 'react-admin';
import { OrderedBundle } from '../bundles/BundleAutoComplete';
import { CircularProgress } from '@material-ui/core';

type ProjectBundleInputProps = {
    projectId: string;
    validate?: any;
    source: string;
    label: string;
    fullWidth?: boolean;
};

const optionText = (bundle: OrderedBundle) =>
    `${bundle.order}: ${bundle.name} (${bundle.code})`;

const Label = ({ loading, label }: { loading: boolean; label: string }) => {
    return (
        <>
            {label} {loading && <CircularProgress color="inherit" size={15} />}
        </>
    );
};

const ProjectBundleInput = ({
    projectId,
    validate,
    source,
    label,
    fullWidth,
}: ProjectBundleInputProps) => {
    const { loaded, data } = useQueryWithStore({
        type: 'getList',
        resource: `projects/${projectId}/bundles`,
    });

    return (
        <AutocompleteInput
            label={<Label loading={!loaded} label={label} />}
            disabled={!loaded}
            source={source}
            optionText={optionText}
            validate={validate}
            fullWidth={fullWidth}
            allowEmpty={false}
            choices={
                data?.sort((a: OrderedBundle, b: OrderedBundle) =>
                    a.order > b.order ? 1 : -1
                ) ?? []
            }
        />
    );
};

export default ProjectBundleInput;
