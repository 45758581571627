const download = (blob: Blob, filename: string) => {
    const nav = window.navigator as any;
    if (window.navigator && nav.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        nav.msSaveOrOpenBlob(blob, filename);

        return;
    }

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
};

export default download;
