import { People as UserIcon } from '@material-ui/icons';

import UserList from './UserList';
import UserShow from './UserShow';

export default {
    show: UserShow,
    list: UserList,
    icon: UserIcon,
};
