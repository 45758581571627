import React, { useMemo, useState } from 'react';
import {
    Create,
    required,
    TextInput,
    FileInput,
    FileField,
    FormWithRedirect,
    SaveButton,
    useDataProvider,
    useNotify,
    useRedirect,
    SelectInput,
} from 'react-admin';
import MySimpleForm from '../utils/MySimpleForm';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { ResourceName } from '../ResourceName';
import IconCancel from '@material-ui/icons/Cancel';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ProviderCountry from '../interfaces/ProviderCountry';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';

const transformUserProvisioningData = (data: {
    email: string;
    reference: string;
    file: string;
    countryCode: ProviderCountry;
}): {
    email: string;
    reference: string;
    countryCode: ProviderCountry;
} => {
    return {
        countryCode: data.countryCode,
        email: data.email,
        reference: data.reference,
    };
};
const useStyles = makeStyles({
    editButtonContainer: {
        display: 'flex',
        justifyContent: 'right',
    },
});
const UserProvisioningCreate = (props: any) => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const countryChoice = useMemo(() => {
        return Object.values(ProviderCountry).map((value) => ({
            id: value,
            name: value,
        }));
    }, []);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async (values: {
        file: {
            rawFile: File;
        };
    }) => {
        const params = {
            data: {
                file: values.file.rawFile,
            },
        };

        await dataProvider
            .uploadUserProvisioningCsv(params)
            .then(() => {
                notify('The CSV file is currently processing');
                redirect('/user-provisioning');
            })
            .catch(() => {
                notify('Error: upload CSV file', 'warning');
            })
            .finally(() => {
                setShowDialog(false);
            });
    };

    return (
        <Create transform={transformUserProvisioningData} {...props}>
            <MySimpleForm>
                <div className={classes.editButtonContainer}>
                    <Button
                        startIcon={<PublishIcon />}
                        variant="text"
                        onClick={handleClick}
                    >
                        Upload CSV
                    </Button>
                </div>
                <Box>
                    <TextInput
                        source="email"
                        label="Email"
                        validate={required()}
                        fullWidth
                    />
                    <SelectInput
                        helperText="This is the provider from which the country will be used to to create the site (ilg for France for example)"
                        source="countryCode"
                        label="Country Code"
                        defaultValue={ProviderCountry.FR}
                        choices={countryChoice}
                        fullWidth
                        resettable
                        alwaysOn
                    />
                    <TextInput
                        fullWidth
                        source="reference"
                        label="Reference"
                        validate={required()}
                    />
                </Box>
                <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
                    <FormWithRedirect
                        resource={ResourceName.USER_PROVISIONING}
                        save={handleSubmit}
                        render={({
                            handleSubmitWithRedirect,
                            saving,
                        }: {
                            handleSubmitWithRedirect: () => void;
                            saving: () => void;
                        }) => (
                            <>
                                <DialogContent>
                                    <Box>
                                        <FileInput
                                            source="file"
                                            label="CSV file"
                                            accept="text/csv"
                                        >
                                            <FileField
                                                source="src"
                                                title="title"
                                            />
                                        </FileInput>
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseClick}>
                                        <IconCancel />
                                    </Button>
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        saving={saving}
                                    />
                                </DialogActions>
                            </>
                        )}
                    />
                </Dialog>
            </MySimpleForm>
        </Create>
    );
};

export default UserProvisioningCreate;
