import Form, { IChangeEvent } from '@rjsf/core';
import { RegistryFieldsType } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { JSONSchema7 } from 'json-schema';
import React, { useState } from 'react';
import CustomCheckbox from './CustomCheckbox';
import filterPropertiesWithNullType from './filterPropertiesWithNullType';
import ObjectFieldTemplate from './ObjectFieldTemplate';
import CustomFieldTemplate from './CustomFieldTemplate';
import styled from 'styled-components';

const InvisibleButton = styled.button`
    display: none;
`;

const customUiSchemaField = ['organisation'];

const customFields: RegistryFieldsType = {
    StringField: CustomCheckbox,
    NumberField: CustomCheckbox,
    BooleanField: CustomCheckbox,
    ArrayField: CustomCheckbox,
    ...customUiSchemaField.reduce(
        (acc, curr) => ({
            ...acc,
            [curr]: CustomCheckbox,
        }),
        {}
    ),
};

const PrekSchemaQuestionSelector = (props: {
    schema: JSONSchema7;
    uiSchema?: JSONSchema7;
}) => {
    const { schema, uiSchema } = props;
    const [formData, setFormData] = useState({});
    const onChange = (data: IChangeEvent) => {
        setFormData(data.formData);
    };

    return (
        <Form
            schema={filterPropertiesWithNullType(schema) as JSONSchema7}
            uiSchema={uiSchema}
            fields={customFields}
            validator={validator}
            onChange={onChange}
            formData={formData}
            templates={{
                ObjectFieldTemplate,
                FieldTemplate: CustomFieldTemplate,
            }}
        >
            <InvisibleButton type="submit" />
        </Form>
    );
};

export default PrekSchemaQuestionSelector;
