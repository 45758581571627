import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    useQuery,
    Labeled,
    DateField,
    EmailField,
    TextField,
} from 'react-admin';
import { LinearProgress } from '@material-ui/core';
import PrequalificationRepresentation from './representations/PrequalificationRepresentation';
import RegistrationCountryField from '../ui/atoms/fields/organisations/companies/RegistrationCountryField';
import StatusField from '../ui/atoms/fields/organisations/StatusField';

type ShowProps = {
    record: PrequalificationRepresentation;
    label: string;
};

const PrequalificationHTMLField = ({ record }: ShowProps) => {
    const prequalificationId = record.id;

    const { data, loading } = useQuery({
        type: 'getPrequalification',
        payload: { prequalificationId, accept: 'text/html' },
    });

    if (loading) {
        return <LinearProgress variant="query" />;
    }

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: data,
            }}
        />
    );
};

const PrequalificationShow: React.FC = ({ hasShow, ...props }: any) => (
    <Show title="Prequalification" {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" label="Id" />
                <TextField source="status" label="Status" />
                <DateField source="updatedAt" label="Updated at" />
                <DateField source="createdAt" label="Created at" />
                <TextField source="version" label="Version" />
                <TextField
                    sortable={false}
                    source="mainReference.value"
                    label="Company reference"
                />
                <TextField source="company.name" label="Company name" />
                <Labeled label="Company status">
                    <StatusField source="company.status" />
                </Labeled>
                <TextField
                    source="company.headquarterId"
                    label="Headquarter ID"
                />
                <DateField
                    source="company.creationDate"
                    label="Company creation date"
                />
                <Labeled label="Company registration country">
                    <RegistrationCountryField source="company.registrationCountry" />
                </Labeled>
                <TextField source="submitterId" label="Submitter id" />
                <TextField
                    source="prequalification.submitter.firstName"
                    label="Submitter first name"
                />
                <TextField
                    source="prequalification.submitter.lastName"
                    label="Submitter last name"
                />
                <Labeled label="Submitter email">
                    <EmailField source="prequalification.submitter.email" />
                </Labeled>
                <TextField
                    source="prequalification.submitter.function"
                    label="Submitter function"
                />
                <TextField
                    source="prequalification.submitter.phone"
                    label="Submitter phone"
                />
            </Tab>
            <Tab label="prequalification">
                <PrequalificationHTMLField {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default PrequalificationShow;
