import React, { useMemo } from 'react';
import {
    InputProps,
    AutocompleteInput,
    ReferenceInput as MuiReferenceInput,
    TextInput,
    useQuery,
    required,
} from 'react-admin';
import { ResourceName } from '../ResourceName';
import { capitalize } from 'lodash';
import Alert from '@material-ui/lab/Alert';
import { LinearProgress } from '@material-ui/core';
import Reference from '../interfaces/Reference';

export type siteOrCompany = 'site' | 'company';

type ReferenceInputProps = typeof InputProps & {
    registrationCountryId: string;
    resourceType: siteOrCompany;
};

const getResourceTypePlural = (resourceType: siteOrCompany): string => {
    if (resourceType === 'site') {
        return 'sites';
    }

    return 'companies';
};

const optionText = ({ name }: any) => name;

const ReferenceInput = ({
    source,
    resourceType,
    resourceId,
    registrationCountryId,
    ...others
}: ReferenceInputProps): React.ReactElement => {
    const { data, ...rest } = others;

    return (
        <>
            <MuiReferenceInput
                {...rest}
                label="ReferenceType"
                source="referenceTypeId"
                reference={ResourceName.REFERENCE_TYPES}
                filterToQuery={optionText}
                filter={{
                    [`is${capitalize(resourceType)}`]: true,
                    registrationCountryId,
                }}
                fullWidth
                isRequired
                validate={required()}
            >
                <CustomAutoComplete
                    resourceId={resourceId}
                    resourceType={resourceType}
                />
            </MuiReferenceInput>
            <TextInput
                label="Value"
                source="value"
                fullWidth
                isRequired
                validate={required()}
            />
        </>
    );
};

const CustomAutoComplete = ({ resourceType, resourceId, ...props }: any) => {
    const { choices, ...rest } = props;
    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: resourceId
            ? `${getResourceTypePlural(resourceType)}/${resourceId}/${
                  ResourceName.REFERENCES
              }`
            : '',
    });

    const filteredChoices = useMemo(() => {
        if (!data) {
            return [];
        }
        const existingReferenceTypeIds = data.map(
            (reference: Reference) => reference.referenceTypeId
        );

        return choices.filter(
            (referenceType: { id: string }) =>
                !existingReferenceTypeIds.includes(referenceType.id)
        );
    }, [data, choices]);

    if (error) {
        return (
            <Alert severity="error">
                Could not get any available referenceType
            </Alert>
        );
    }

    if (loading) {
        return <LinearProgress variant="query" />;
    }

    if (filteredChoices.length === 0) {
        return (
            <>
                <Alert severity="error">No available choices</Alert>
                <TextInput
                    validate={() => false}
                    label="Type"
                    source="referenceTypeId"
                    initialValue=""
                    disabled
                    fullWidth
                />
            </>
        );
    }

    return <AutocompleteInput {...rest} choices={filteredChoices} />;
};

export default ReferenceInput;
