import { AutocompleteInput, ReferenceInput } from 'react-admin';
import React from 'react';
import { ResourceName } from '../ResourceName';

const filterToQuery = (searchText: string) => ({ emailLike: searchText });

const AutocompleteUserByEmail = ({ ...props }) => {
    return (
        <ReferenceInput
            label="User email"
            reference={ResourceName.USERS}
            filterToQuery={filterToQuery}
            perPage={10}
            allowEmpty
            {...props}
        >
            <AutocompleteInput optionText="email" />
        </ReferenceInput>
    );
};

export default AutocompleteUserByEmail;
