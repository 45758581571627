import React from 'react';
import ProjectParticipationItem from '../../../interfaces/ProjectParticipationItem';
import {
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DeleteParticipationButton from './DeleteParticipationButton';

type ParticipationLineProps = {
    participation: ProjectParticipationItem;
};

const address = (participation: ProjectParticipationItem): string => `
${participation.site.street},
${participation.site.postalCode} ${participation.site.city},
${participation.site.registrationCountry.code}`;

const ParticipationLine = ({ participation }: ParticipationLineProps) => {
    return (
        <>
            <ListItem>
                <ListItemIcon>
                    <ApartmentIcon />
                </ListItemIcon>
                <ListItemText
                    primary={`${participation.site.company.name}: ${participation.site.mainReference.value}`}
                    secondary={address(participation)}
                />
                <DeleteParticipationButton participation={participation} />
            </ListItem>
            <Divider />
        </>
    );
};

export default ParticipationLine;
