const typography = {
    fontFamily: ['Work Sans', ' cursive'].join(','),
    fontSize: 14, // Should be a number in pixels
    fontStyle: 'normal',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 900,
};

export default typography;
