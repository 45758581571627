import React from 'react';
import { Datagrid, TextField, DateField, TextInput } from 'react-admin';
import List from '../ui/atoms/List';
import { makeStyles } from '@material-ui/core/styles';
import PrequalificationsListActionsButton from './components/PrequalificationsListActionsButton';
import IndicatorsField, {
    IndicatorType,
} from '../ui/atoms/fields/IndicatorsField';
import VersionField from '../ui/atoms/fields/VersionField';
import StatusField from '../ui/atoms/fields/organisations/StatusField';
import PrequalificationListActions from './PrequalificationListActions';

const useStyles = makeStyles({
    bold: { fontWeight: 'bold' },
});

const emptyText = '-';

const PrequalificationList: React.FC = (props) => {
    const classes = useStyles();

    return (
        <List
            filters={[
                <TextInput
                    label="Search"
                    source="search"
                    alwaysOn
                    resettable
                />,
            ]}
            actions={<PrequalificationListActions />}
            {...props}
        >
            <Datagrid optimized>
                <TextField
                    source="id"
                    label="Id"
                    emptyText={emptyText}
                    sortable={false}
                />
                <TextField
                    source="company.mainReference.value"
                    label="Company reference"
                    sortable={false}
                />
                <TextField
                    source="company.name"
                    label="Company name"
                    className={classes.bold}
                    sortable={false}
                />
                <StatusField
                    source="company.status"
                    label="Company status"
                    sortable={false}
                />
                <TextField source="status" label="Status" sortable={false} />
                <DateField
                    source="updatedAt"
                    emptyText={emptyText}
                    label="Date"
                    sortable={false}
                />
                <VersionField
                    source="version"
                    label="Version"
                    sortable={false}
                />
                <IndicatorsField
                    label="Sapin 2"
                    source="indicators.sapin2"
                    type={IndicatorType.SAPIN2}
                    sortable={false}
                />
                <IndicatorsField
                    label="RSE"
                    source="indicators.rse"
                    type={IndicatorType.RSE}
                    sortable={false}
                />
                <PrequalificationsListActionsButton label="Actions" />
            </Datagrid>
        </List>
    );
};

export default PrequalificationList;
