enum AnalysisKey {
    PPE = 'PPE',
    CONVICTION = 'CONVICTION',
    SANCTIONED_COUNTRY = 'SANCTIONED_COUNTRY',
    ENTITY_SANCTIONED = 'ENTITY_SANCTIONED',
    ART17_OBLIGATIONS = 'ART17_OBLIGATIONS',
    ART17_WITHOUT_REFERENT = 'ART17_WITHOUT_REFERENT',
    ART17_WITHOUT_4_ACTIONS = 'ART17_WITHOUT_4_ACTIONS',
    ART8_WITHOUT_REFERENT = 'ART8_WITHOUT_REFERENT',
    REFERENT = 'REFERENT',
    FOLLOWED_ACTION = 'FOLLOWED_ACTION',
    CERTIFICATION_OR_LABEL = 'CERTIFICATION_OR_LABEL',
}

export default AnalysisKey;
