import { useCallback, useState } from 'react';
import { apiUrl, httpClient } from '../../dataProvider';
import { useNotify } from 'react-admin';

type SubmitContractPrekSettings = {
    submit: (recordValue: Record<string, boolean>) => Promise<void>;
    submitting: boolean;
};

const useSubmitContractPrekSettings = (
    contractPrekSettingsId?: string
): SubmitContractPrekSettings => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const notify = useNotify();

    const submit = useCallback(
        async (recordValue: Record<string, boolean>) => {
            if (!contractPrekSettingsId) {
                return;
            }

            setSubmitting(true);

            return httpClient(
                `${apiUrl}/contractPrekSettings/${contractPrekSettingsId}`,
                {
                    method: 'PUT',
                    body: JSON.stringify({
                        value: recordValue,
                    }),
                }
            )
                .then(async (res) => {
                    if (res.status === 200) {
                        notify('Contract prequalification settings updated.');
                    }
                })
                .catch((error: Error) => {
                    console.error('[ContractPrekSettings]:', error);
                    notify(
                        'Unable to save contract prequalification settings',
                        'warning'
                    );
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
        [contractPrekSettingsId]
    );

    return {
        submit,
        submitting,
    };
};

export default useSubmitContractPrekSettings;
