import React from 'react';
import ProjectParticipationItem from '../../../interfaces/ProjectParticipationItem';
import Alert from '@material-ui/lab/Alert';
import useParticipationByRole from './useParticipationByRole';
import ParticipationRole from './ParticipationRole';

type ProjectParticipationListProps = {
    data: ProjectParticipationItem[];
};

const ProjectParticipationList = ({ data }: ProjectParticipationListProps) => {
    const participationByRole = useParticipationByRole(data);

    if (data.length <= 0) {
        return <Alert severity="info">No Participation Yet!</Alert>;
    }

    return (
        <>
            {participationByRole.map(({ participations, role }) => (
                <ParticipationRole
                    participations={participations}
                    role={role}
                    key={role}
                />
            ))}
        </>
    );
};

export default ProjectParticipationList;
