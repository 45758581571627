import React from 'react';
import {
    Tab,
    TabProps,
    ShowProps,
    TextInput,
    TextField,
    useRecordContext,
} from 'react-admin';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import SubResource from '../../utils/SubResource';
import { get } from 'lodash';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: (props: { status: string }) => {
        switch (props.status) {
            case 'PENDING':
                return {
                    backgroundColor: theme.palette.warning.main,
                };
            case 'ACCEPTED':
                return {
                    backgroundColor: theme.palette.success.main,
                };
            case 'REJECTED':
                return {
                    backgroundColor: theme.palette.error.main,
                };
            default:
                return {
                    backgroundColor: theme.palette.info.main,
                };
        }
    },
}));

const ParticipationStatusField = (props: any) => {
    const { source } = props;
    const record = useRecordContext(props);
    const status = get(record, source);
    const classes = useStyles({ status });

    return <Chip classes={classes} label={status} />;
};

const InvitationContent = ({ resource, record }: typeof TabProps) => (
    <SubResource
        target="participation-requests"
        resource={resource}
        record={record}
        filters={[
            <TextInput label="Name or reference" source="search" alwaysOn />,
        ]}
    >
        <TextField sortable={false} source="site.company.name" label="Name" />
        <TextField
            sortable={false}
            source="site.mainReference.value"
            label="reference"
        />
        <TextField sortable={false} source="role" label="Role" />
        <TextField sortable={false} source="bundle.name" label="Bundle" />
        <ParticipationStatusField source="status" />
    </SubResource>
);

const ParticipationRequestTab = (props: typeof ShowProps) => {
    return (
        <Tab
            label="Requests to join"
            path="requests"
            icon={<LiveHelpIcon />}
            {...props}
        >
            <InvitationContent
                resource={props.resource}
                record={props.record}
            />
        </Tab>
    );
};

export default ParticipationRequestTab;
