import React from 'react';
import BusinessHoursDay from './BusinessHoursDay';
import WorkingDay, { BusinessDayValue } from './WorkingDay';

type WorkingTimeUiProps = {
    objectValue?: any;
    disabled?: boolean;
    onChange?: (day: BusinessHoursDay) => (dayValue: BusinessDayValue) => void;
    copyPrevious?: (
        dayToFill: BusinessHoursDay,
        dayToCopy?: BusinessHoursDay
    ) => () => void;
    displayEmpty?: boolean;
};

const WorkingTimeUi = ({
    objectValue,
    disabled,
    onChange,
    displayEmpty = true,
    copyPrevious,
}: WorkingTimeUiProps) => (
    <>
        {Object.values(BusinessHoursDay).map((day, i) => (
            <WorkingDay
                copyPrevious={
                    i > 0
                        ? copyPrevious?.(
                              day,
                              Object.values(BusinessHoursDay)[i - 1]
                          )
                        : undefined
                }
                day={day}
                key={day}
                value={objectValue?.[day]}
                disabled={disabled ?? false}
                onChange={onChange?.(day)}
                displayEmpty={displayEmpty}
            />
        ))}
    </>
);

export default WorkingTimeUi;
