import React from 'react';
import Project, { NatureOfMarket } from '../interfaces/Project';
import transformStringToDate from '../utils/transformStringToDate';
import EditableShow, { EditableShowProps } from '../components/EditableShow';
import ProjectFields from './ProjectFields';
import { useRedirect, useNotify, useRefresh } from 'react-admin';
import { deleteEmptyOperatingDaysAndHour } from './ProjectCreate';

const alterRecord = (record: Record<string, any>) => ({
    ...record,
    natureOfMarket:
        record.natureOfMarket === false
            ? NatureOfMarket.PRIVATE
            : NatureOfMarket.PUBLIC,
    workDestination:
        record.workDestination?.replace(/["\\\][]+/g, '').split(',') || null,
    projectBuildingTags:
        record.projectBuildingTags?.map((tag: { id: string }) => tag.id) ?? [],
    projectSiteTags:
        record.projectSiteTags?.map((tag: { id: string }) => tag.id) ?? [],
});

const transformNatureOfMarket = (data: Project) => {
    if (data.natureOfMarket === NatureOfMarket.PUBLIC) {
        return true;
    } else if (data.natureOfMarket === NatureOfMarket.PRIVATE) {
        return false;
    } else {
        return undefined;
    }
};

const getPicture = (data: Project) => {
    if (data.picture?.rawFile) {
        return data.picture.rawFile;
    }
    if (typeof data.picture === 'string') {
        return data.picture;
    }

    return null;
};

const transformProjectData = (data: Project): any => ({
    ...data,
    picture: getPicture(data),
    DOC: data.DOC ? transformStringToDate(data.DOC) : undefined,
    DOS: data.DOS ? transformStringToDate(data.DOS) : undefined,
    workDestination:
        data.workDestination?.length > 0
            ? JSON.stringify(data.workDestination)
            : undefined,
    estimatedDeliveryDate: data.estimatedDeliveryDate
        ? transformStringToDate(data.estimatedDeliveryDate)
        : undefined,
    bundles: undefined,
    natureOfMarket: transformNatureOfMarket(data),
    operatingDaysAndHour: deleteEmptyOperatingDaysAndHour(
        data.operatingDaysAndHour
    ),
});

const ProjectEdit = (props: EditableShowProps) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect(`/projects/${props.id}/show`);
        refresh();
    };

    return (
        <EditableShow
            {...props}
            alterRecord={alterRecord}
            transform={transformProjectData}
            onSuccess={onSuccess}
        >
            <ProjectFields />
        </EditableShow>
    );
};

export default ProjectEdit;
