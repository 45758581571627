import React, { useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {
    Button,
    FormWithRedirect,
    SaveButton,
    SaveContextProvider,
    useDataProvider,
    useNotify,
    useRefresh,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import { Edit } from '@material-ui/icons';

type EditDialogProps<T extends { id: string }> = {
    record: T;
    label?: string;
    resource: string;
    disabled?: boolean;
    children: React.ReactElement | React.ReactElement[];
};

function EditDialog<T extends { id: string }>({
    record,
    resource,
    children,
    label,
    disabled,
}: EditDialogProps<T>): React.ReactElement {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);
    const [open, setIsOpen] = useState(false);
    const saveContext = useMemo(
        () => ({
            record,
            save: () => {},
            setOnFailure: () => {},
            saving: loading,
        }),
        [loading]
    );

    const handleSubmit = async (values: any) => {
        setLoading(true);

        await dataProvider
            .update(resource, {
                id: record.id,
                data: values,
                previousData: record,
            })
            .then(() => {
                notify(`${label ?? resource} updated successfully`, 'success');
                refresh();
                setIsOpen(false);
            })
            .catch((e: { message?: string }) => {
                notify(
                    `Error: Cannot update ${label ?? resource}. ${
                        e.message ?? ''
                    }`,
                    'warning'
                );
                setIsOpen(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Dialog fullWidth open={open}>
                <DialogTitle>Update {label ?? resource}</DialogTitle>
                <DialogContent>
                    <SaveContextProvider value={saveContext}>
                        <FormWithRedirect
                            resource={resource}
                            record={record}
                            save={handleSubmit}
                            render={({
                                handleSubmitWithRedirect,
                                saving,
                            }: {
                                handleSubmitWithRedirect: () => void;
                                saving: () => void;
                            }) => (
                                <>
                                    <DialogContent>
                                        {children}
                                        <DialogActions>
                                            <Button
                                                variant="text"
                                                size="medium"
                                                label="ra.action.cancel"
                                                onClick={() => setIsOpen(false)}
                                            >
                                                <IconCancel />
                                            </Button>
                                            <SaveButton
                                                handleSubmitWithRedirect={
                                                    handleSubmitWithRedirect
                                                }
                                                saving={saving}
                                                disabled={disabled}
                                                submitOnEnter={true}
                                            />
                                        </DialogActions>
                                    </DialogContent>
                                </>
                            )}
                        />
                    </SaveContextProvider>
                </DialogContent>
            </Dialog>
            <Button label={'Edit'} onClick={() => setIsOpen(true)}>
                <Edit />
            </Button>
        </>
    );
}

export default EditDialog;
