import { noop } from 'lodash';
import React, { useMemo, useState } from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    SaveButton,
    Button,
    BooleanField,
    Tab,
    TextField,
    useDataProvider,
    useRefresh,
    useNotify,
    Toolbar,
} from 'react-admin';
import { ResourceName } from '../../../ResourceName';
import { ReferenceOrder } from '../../../interfaces/ReferenceOrder';
import SubResource from '../../../utils/SubResource';
import DatagriDragNDropable, {
    DragNDropableHandle,
} from '../../../components/DatagridDragNDropable';
import Merge from '../../../utils/types/Merge';
import styled from 'styled-components';

const InvisibleSaveButton = styled(SaveButton)`
    margin-left: 8px;
`;

const optionText = ({ name }: any) => name;

type ReferenceReOrder = Merge<
    ReferenceOrder,
    {
        newOrder: number;
    }
>;

const useSaveDnd = (
    countryId?: string
): {
    update: (items: ReferenceReOrder[]) => void;
    loading: boolean;
} => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const [loading, setLoading] = useState<boolean>(false);

    if (!countryId) {
        return {
            update: noop,
            loading,
        };
    }

    return {
        update: (items: ReferenceReOrder[]) => {
            setLoading(true);
            dataProvider
                .updateSubResource(
                    `${ResourceName.REGISTRATION_COUNTRIES}/${countryId}/${ResourceName.REFERENCE_ORDERS}`,
                    items.map((order, index) => {
                        const { newOrder, ...orderReferenceProperties } = order;

                        return {
                            ...orderReferenceProperties,
                            order: newOrder,
                        };
                    })
                )
                .then(() => {
                    notify('Reference orders have been updated', 'success');
                    refresh();
                })
                .catch((e: Error) => {
                    notify("Reference orders couldn't be updated", 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        loading,
    };
};

const ReferenceOrderTab: React.FC = (props: any) => {
    const { hasCreate, hasEdit, hasShow, hasList, ...rest } = props;
    const countryId = props?.record?.id;
    const refresh = useRefresh();
    const { update, loading } = useSaveDnd(countryId);
    const [changedReferenceOrders, setChangedReferenceOrders] = useState<
        ReferenceReOrder[]
    >([]);

    const onDnd = (items: ReferenceOrder[]) => {
        setChangedReferenceOrders(() =>
            items
                .map((refOrder, index) => ({
                    ...refOrder,
                    newOrder: index + 1,
                }))
                .filter(
                    (refOrder, index) => refOrder.order !== refOrder.newOrder
                )
        );
    };

    const saveChangedDnd = (): void => {
        update(changedReferenceOrders);
    };

    const cancelChangedDnd = (): void => {
        refresh();
    };

    return (
        <Tab label="Reference order" {...rest}>
            <SubResource
                target="reference-orders"
                name="Reference order"
                resource={props.resource}
                record={props.record}
                deleteButton
                pagination={false}
                CustomDatagrid={DatagriDragNDropable}
                onChange={onDnd}
                addInput={<CustomReferenceInput />}
            >
                <DragNDropableHandle />
                <TextField sortable={false} source="id" label="Id" />
                <TextField
                    sortable={false}
                    source="referenceType.name"
                    label="Name"
                />
                <OrderField label="Order" />
                <BooleanField
                    source="referenceType.isCompany"
                    label="Is Company"
                    sortable={false}
                />
                <BooleanField
                    source="referenceType.isSite"
                    label="Is Site"
                    sortable={false}
                />
            </SubResource>
            <Toolbar>
                <Button
                    size="medium"
                    label="ra.action.cancel"
                    loading={loading}
                    onClick={cancelChangedDnd}
                    disabled={changedReferenceOrders.length === 0}
                />
                <InvisibleSaveButton
                    loading={loading}
                    handleSubmitWithRedirect={saveChangedDnd}
                    disabled={changedReferenceOrders.length === 0}
                />
            </Toolbar>
        </Tab>
    );
};

const CustomReferenceInput = (props: any) => {
    const { data, ...rest } = props;

    return (
        <ReferenceInput
            {...rest}
            label="ReferenceType"
            source="referenceTypeId"
            reference={ResourceName.REFERENCE_TYPES}
            filterToQuery={optionText}
            fullWidth
            isRequired
        >
            <CustomAutocompleteInput {...props} data={data} />
        </ReferenceInput>
    );
};

const CustomAutocompleteInput = (
    props: { choices: any[] } & {
        data: Record<string, ReferenceReOrder>;
    }
) => {
    const { choices, data, ...rest } = props;

    const filteredChoices = useMemo(() => {
        const dataArray = Object.values(data).map(
            (referenceOrder: ReferenceOrder) => referenceOrder.referenceTypeId
        );

        return choices.filter((choice: { id: string }) => {
            return !dataArray.includes(choice.id);
        });
    }, [data, choices]);

    return (
        <AutocompleteInput
            {...rest}
            choices={filteredChoices}
            optionText={optionText}
        />
    );
};

const OrderField = ({ rowIndex }: { rowIndex?: number; label?: string }) => {
    const newOrder = (rowIndex ?? 0) + 1;

    return <>{newOrder}</>;
};

export default ReferenceOrderTab;
