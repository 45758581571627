import React from 'react';
import { Datagrid, TextField } from 'react-admin';
import List from '../ui/atoms/List';

const UserProvisioningErrorList: React.FC = (props) => {
    return (
        <List {...props} exporter={false}>
            <Datagrid optimized>
                <TextField source="id" sortable={false} />
                <TextField source="email" label="Email" sortable={false} />
                <TextField
                    source="reference"
                    label="Reference"
                    sortable={false}
                />
                <TextField
                    source="countryCode"
                    label="Country Code"
                    sortable={false}
                />
                <TextField source="groupId" label="Group id" sortable={false} />
                <TextField source="detail" label="Détail" sortable={false} />
            </Datagrid>
        </List>
    );
};

export default UserProvisioningErrorList;
