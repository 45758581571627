import React from 'react';
import { BooleanInput, Create, SimpleForm, TextInput } from 'react-admin';

const ReferenceTypeCreate: React.FC = (props) => (
    <Create {...props} record={{ isCompany: false, isSite: false }}>
        <SimpleForm>
            <TextInput source="name" label="Name" fullWidth />
            <BooleanInput source="isCompany" label="Is Company" />
            <BooleanInput source="isSite" label="Is Site" />
        </SimpleForm>
    </Create>
);

export default ReferenceTypeCreate;
