import React, { useState } from 'react';
import PrequalificationStatus from '../enums/PrequalificationStatus';
import { FieldParams } from '../representations/PrequalificationRepresentation';
import AdaptiveSplitDropdown from '../../ui/atoms/inputs/AdaptiveSplitDropdown';
import { useNotify, Confirm, useRefresh } from 'react-admin';
import dataProvider, { apiUrl, httpClientJson } from '../../dataProvider';
import { useHistory } from 'react-router-dom';
import download from '../../utils/download';

const PrequalificationsListActionsButton = (props: FieldParams) => {
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const prequalificationId = props.record?.id;
    const [loading, setLoading] = useState(false);
    const refresh = useRefresh();

    const archiveLastValidPrequalification = async () => {
        setLoading(true);

        await dataProvider
            .archiveLastValidPrequalification(props.record?.company.id)
            .then(() => {
                notify('Archiving done');
                refresh();
            })
            .catch(() => {
                notify('Error: prequalification not archived', 'warning');
            })
            .finally(() => {
                setLoading(false);
                setOpen(false);
            });
    };

    const exportPrequalificationPdf = () => {
        setLoading(true);

        dataProvider
            .getPrequalification({
                prequalificationId: prequalificationId,
                accept: 'application/pdf',
            })
            .then((blob: Blob) => {
                setLoading(false);
                download(blob, `prequalification-${prequalificationId}`);
            });
    };

    const calculAnalysis = () => {
        setLoading(true);

        httpClientJson(`${apiUrl}/analysis/${prequalificationId}`, {
            method: 'POST',
        })
            .then(() => {
                notify('Prequalification Analysis done');
            })
            .catch(() => {
                notify('Error: analysis could not be calculated', 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const exportPrequalificationJson = () => {
        setLoading(true);

        dataProvider
            .getPrequalification({
                prequalificationId: prequalificationId,
                accept: 'application/json',
            })
            .then(({ data }: Record<string, unknown>) => {
                const blob = new Blob([JSON.stringify(data, null, 2)], {
                    type: 'application/json',
                });
                setLoading(false);
                download(blob, `prequalification-${prequalificationId}`);
            });
    };

    const hasPrequalificationStatusToComplete =
        props.record?.status === PrequalificationStatus.TO_COMPLETE;

    return (
        <>
            <AdaptiveSplitDropdown
                actions={[
                    {
                        title: 'SHOW',
                        onClick: () =>
                            history.push(
                                `prequalifications/${prequalificationId}/show`
                            ),
                        disabled: hasPrequalificationStatusToComplete,
                    },
                    {
                        title: 'ARCHIVE',
                        onClick: () => setOpen(true),
                        disabled:
                            props.record?.status !==
                            PrequalificationStatus.VALID,
                    },
                    {
                        title: 'EXPORT JSON',
                        onClick: () => exportPrequalificationJson(),
                        disabled: hasPrequalificationStatusToComplete,
                    },
                    {
                        title: 'EXPORT PDF',
                        onClick: () => exportPrequalificationPdf(),
                        disabled: hasPrequalificationStatusToComplete,
                    },
                    {
                        title: 'CALCUL ANALYSIS',
                        onClick: () => calculAnalysis(),
                    },
                ]}
                id="prequalifications"
                title="Prequalifications"
                loading={loading}
                {...props}
            />
            {open && (
                <Confirm
                    isOpen={open}
                    onClose={() => setOpen(false)}
                    onConfirm={() => archiveLastValidPrequalification()}
                    confirm="Yes"
                    cancel="No"
                    title="Do you really want to archive this prequalification ?"
                />
            )}
        </>
    );
};

export default PrequalificationsListActionsButton;
