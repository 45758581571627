import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
    SaveContextProvider,
    SimpleForm,
    SaveButton,
    Toolbar,
    Error,
    useNotify,
    useDataProvider,
    useMutation,
    Tab,
} from 'react-admin';
import { ToolbarProps, LinearProgress } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import BundleInput from './bundles/BundleInput';
import { Bundle } from './bundles/BundleAutoComplete';
import { ResourceName } from '../../ResourceName';
import BundleError, {
    ProjectBundleError,
    RemoveBundleSubscriptionErrorType,
} from './bundles/BundleError';
import DuplicateProjectWorkPackagesButton from './workpackages/DuplicateProjectWorkPackagesButton';

const ProjectBundlesEditToolbar = (
    props: ToolbarProps & { pristine?: boolean }
) => (
    <Toolbar {...props}>
        <SaveButton disabled={props.pristine} />
    </Toolbar>
);

const formateBundles = (
    bundle: Bundle,
    index: number
): Bundle & { order: number } => {
    return {
        name: bundle.name,
        id: bundle.id,
        code: bundle.code,
        order: index + 1,
    };
};

const ProjectBundlesTab = (props: any) => {
    const { hasCreate, hasEdit, hasShow, hasList, ...rest } = props;
    const dataProvider = useDataProvider();
    const [saving, setSaving] = useState<boolean>(false);
    const [bundleError, setBundleError] = useState<{
        type: RemoveBundleSubscriptionErrorType;
        projectParticipationErrors?: ProjectBundleError[];
        projectRequestErrors?: ProjectBundleError[];
    } | null>(null);
    const notify = useNotify();
    const projectId = rest?.id;
    const [approve, { data, loading, error }] = useMutation({
        type: 'getList',
        resource: projectId ? `projects/${projectId}/bundles` : '',
        payload: {},
    });

    useEffect(() => {
        if (projectId) {
            approve();
        }
    }, [approve, projectId]);

    const handleSave = useCallback(
        (data: { bundles: Bundle[]; id: string }) => {
            setSaving(true);
            const formatedBundles = data.bundles.map(formateBundles);
            dataProvider
                .updateSubResource(
                    `${ResourceName.PROJECTS}/${projectId}/bundles`,
                    formatedBundles
                )
                .then(() => {
                    notify('Bundles has been updated', 'info');
                })
                .catch((error: any) => {
                    if (error.status === 409 && error?.body?.context) {
                        setBundleError(error.body.context);
                    } else {
                        notify('Bundles has not been updated', 'warning');
                    }
                })
                .finally(() => {
                    approve();
                    setSaving(false);
                });
        },
        [approve, dataProvider, notify, projectId]
    );

    const saveContext = useMemo(
        () => ({
            save: handleSave,
            setOnFailure: () => {},
            saving,
        }),
        [saving, handleSave]
    );

    if (error) {
        return <Error />;
    }

    return (
        <Tab label="Bundles" path="bundles" icon={<LocalOfferIcon />} {...rest}>
            {loading ? (
                <div>
                    <LinearProgress variant="query" />
                </div>
            ) : (
                <SaveContextProvider value={saveContext}>
                    <DuplicateProjectWorkPackagesButton projectId={rest.id} />
                    <SimpleForm
                        toolbar={<ProjectBundlesEditToolbar />}
                        save={handleSave}
                        record={{
                            bundles: data ?? [],
                        }}
                        saving={saving}
                    >
                        <BundleInput source="bundles" />
                    </SimpleForm>
                </SaveContextProvider>
            )}
            <BundleError
                data={bundleError}
                handleClose={() => setBundleError(null)}
            />
        </Tab>
    );
};

export default ProjectBundlesTab;
