import { PaginationPayload } from 'ra-core';

export type Range = {
    minRange?: number;
    maxRange?: number;
};

export const formatRangeHeader = (
    resource: string,
    pagination?: PaginationPayload
): Range => {
    if (!resource.includes('v1')) {
        return {};
    }
    const minRange =
        (pagination?.perPage ?? 20) * ((pagination?.page ?? 1) - 1);
    const maxRange = (pagination?.perPage ?? 20) * (pagination?.page ?? 1) - 1;

    return { minRange, maxRange };
};
