import createPalette from '@material-ui/core/styles/createPalette';
import merge from 'lodash/merge';
import { defaultTheme } from 'react-admin';

const palette = createPalette(
    merge({}, defaultTheme.palette, {
        primary: {
            main: '#474747',
        },
        secondary: {
            main: '#00ba00',
        },
        error: { main: '#f45141' },
        warning: { main: '#ff9f11' },
        success: { main: '#7ac943' },
        info: { main: '#767676' },
        common: {},
    })
);

export default palette;
