import React from 'react';
import { SelectInput } from 'react-admin';
import ContractType from '../interfaces/ContractType';

interface Props {
    disabled?: boolean;
    fullWidth?: boolean;
}

export const contractTypeChoice = [
    { id: ContractType.PREQUALIFICATION, name: 'Prequalification' },
    { id: ContractType.PREFILL, name: 'Prefill' },
    { id: ContractType.TOTEM, name: 'Totem' },
];

const SelectContractTypeInput: React.FC<Props> = (props) => {
    return (
        <SelectInput {...props} source="type" choices={contractTypeChoice} />
    );
};

export default SelectContractTypeInput;
