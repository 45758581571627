import React from 'react';
import { Show, TabbedShowLayout } from 'react-admin';
import SiteReferencesTab from './show/SiteReferencesTab';
import SiteSummaryTab from './show/SiteSummaryTab';
import SiteMembersTab from './show/SiteUsersTab';

const SiteTitle = ({ record }: { record?: { name?: string } }) => {
    return <span>Project {record?.name ?? 'view'}</span>;
};

const SitesShow = (props: any) => (
    <Show title={<SiteTitle />} {...props}>
        <TabbedShowLayout>
            <SiteSummaryTab {...props} />
            <SiteReferencesTab {...props} />
            <SiteMembersTab {...props} />
        </TabbedShowLayout>
    </Show>
);

export default SitesShow;
