import React from 'react';
import { TabProps } from 'react-admin';
import ContractPrekSettingsForm from './ContractPrekSettingsForm';
import useHttpClientJson from '../../utils/useHttpClientJson';
import { LinearProgress } from '@material-ui/core';
import ContractPrekSetting from '../../interfaces/ContractPrekSetting';
import ModuleSchema from '../../interfaces/ModuleSchema';

interface ModuleSchemaResponse {
    [k: string]: unknown;
    moduleSchemas: ModuleSchema[];
}

const ContractPrekSettings = ({ record }: typeof TabProps) => {
    const { loading: loadingContractPrekSettings, data: contractPrekSettings } =
        useHttpClientJson<ContractPrekSetting>(
            `contracts/${record.id}/contractPrekSettings`
        );

    const { loading: loadingSchemaModules, data: moduleSchemas } =
        useHttpClientJson<ModuleSchemaResponse>(
            'v1/prequalification-schemas/ETHICAL_COMPLIANCE'
        );

    const loading = loadingContractPrekSettings || loadingSchemaModules;

    if (loading || !contractPrekSettings || !moduleSchemas) {
        return <LinearProgress variant="query" />;
    }

    return (
        <ContractPrekSettingsForm
            contractPrekSettings={contractPrekSettings}
            moduleSchemas={moduleSchemas?.moduleSchemas}
        />
    );
};

export default ContractPrekSettings;
