import React from 'react';
import { BooleanField, Datagrid, TextField, BooleanInput } from 'react-admin';
import List from '../ui/atoms/List';
import EmptyListPage from '../components/EmptyListPage';

const ReferenceTypesList: React.FC = (props) => {
    return (
        <List
            filters={[
                <BooleanInput
                    source="isCompany"
                    label="Is Company"
                    alwaysOn
                    resettable
                />,
                <BooleanInput
                    source="isSite"
                    label="Is Site"
                    alwaysOn
                    resettable
                />,
            ]}
            empty={<EmptyListPage />}
            {...props}
            exporter={false}
        >
            <Datagrid optimized>
                <TextField source="id" sortable={false} />
                <TextField source="name" label="Name" sortable={false} />
                <BooleanField
                    source="isCompany"
                    label="Is Company"
                    sortable={false}
                />
                <BooleanField
                    source="isSite"
                    label="Is Site"
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};

export default ReferenceTypesList;
