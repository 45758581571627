import React from 'react';
import ResourceInputProps from '../../utils/interfaces/ResourceInputProps';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import { ResourceName } from '../../ResourceName';

const optionText = (record: any) =>
    record?.id
        ? `${record.firstName} ${record.lastName} (${record.email})`
        : '';

const filterToQuery = (emailLike: string) => ({ emailLike });

const enableGetChoices = ({ q }: { q: string }) => q.length >= 2;

const UsersInput: React.FC<ResourceInputProps> = (props) => (
    <ReferenceInput
        label={props.label}
        source={props.source}
        reference={ResourceName.USERS}
        filter={props.filter}
        filterToQuery={filterToQuery}
        perPage={25}
        allowEmpty={props.allowEmpty}
        fullWidth={props.fullWidth}
        isRequired={props.isRequired}
        enableGetChoices={enableGetChoices}
    >
        <AutocompleteInput
            optionText={optionText}
            options={{ disabled: !!props.disabled }}
            validate={props.validate}
            matchSuggestion={props.matchSuggestion}
        />
    </ReferenceInput>
);

export default UsersInput;
