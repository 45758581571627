import React, { useEffect } from 'react';
import { AutocompleteInput, useMutation } from 'react-admin';
import { CircularProgress, FormHelperText } from '@material-ui/core';
import { useFormState, useForm } from 'react-final-form';
import ProjectParticipationRole from '../../../interfaces/ProjectParticipationRole';
import ProjectParticipationItem from '../../../interfaces/ProjectParticipationItem';

type ParticipationParentInputProps = {
    projectId: string;
    validate?: any;
    source: string;
    label: string;
    fullWidth?: boolean;
};

const optionText = (participation: ProjectParticipationItem) =>
    `${participation.site.mainReference.value}: ${participation.site.company.name}`;

type LabelProps = { loading: boolean; label: string };

const Label = ({ loading, label }: LabelProps) => {
    return loading ? (
        <>
            Loading parents <CircularProgress color="inherit" size={15} />
        </>
    ) : (
        <>{label}</>
    );
};

const ParticipationParentInput = ({
    projectId,
    validate,
    source,
    label,
    fullWidth,
}: ParticipationParentInputProps) => {
    const {
        values: { bundleId, [source]: value },
    } = useFormState();
    const { change } = useForm();

    const [approve, { loading, data }] = useMutation({
        type: 'getList',
        resource: `projects/${projectId}/participations`,
        payload: {
            filter: {
                role: ProjectParticipationRole.CONTRACTOR,
                bundleId: bundleId,
            },
        },
    });

    useEffect(() => {
        if (!bundleId) {
            if (value) {
                change(source, undefined);
            }

            return;
        }

        approve();
    }, [approve, bundleId, change, source, value]);

    return (
        <>
            {data?.length === 0 && (
                <FormHelperText error>
                    No parent existing for this bundle
                </FormHelperText>
            )}

            <AutocompleteInput
                label={<Label loading={loading} label={label} />}
                disabled={loading || !data}
                source={source}
                optionText={optionText}
                validate={validate}
                fullWidth={fullWidth}
                allowEmpty={false}
                choices={data ?? []}
            />
        </>
    );
};

export default ParticipationParentInput;
