import React, { useState } from 'react';
import {
    PartnershipStatus,
    PartnershipStatusActions,
} from '../enums/PartnershipStatus';
import { useNotify, Confirm, useRefresh, useDataProvider } from 'react-admin';
import Button from '../../ui/atoms/inputs/Button';

const getPartnershipsStatus = (status: PartnershipStatus) =>
    status === PartnershipStatus.ACTIVE
        ? PartnershipStatus.ARCHIVED
        : PartnershipStatus.ACTIVE;

const PartnershipsListActionsButton = (props: any) => {
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const partnershipStatus = props.record.status;
    const isPartnershipStatusActive =
        partnershipStatus === PartnershipStatus.ACTIVE;

    const archiveOrActivePartnership = async () => {
        setLoading(true);

        await dataProvider
            .update('partnerships', {
                id: props.record.id,
                data: { status: getPartnershipsStatus(partnershipStatus) },
                previousData: {
                    id: props.record.id,
                    status: props.record.status,
                },
            })
            .then(() => {
                notify(
                    isPartnershipStatusActive
                        ? 'Partnership archived'
                        : 'Partnership actived'
                );
                refresh();
            })
            .catch(() => {
                notify('Error: partnership not archived', 'warning');
            })
            .finally(() => {
                setLoading(false);
                setOpen(false);
            });
    };

    return (
        <>
            <Button loading={loading} onClick={() => setOpen(true)} {...props}>
                {isPartnershipStatusActive
                    ? PartnershipStatusActions.ARCHIVE
                    : PartnershipStatusActions.ACTIVATE}
            </Button>

            <Confirm
                isOpen={open}
                onClose={() => setOpen(false)}
                onConfirm={archiveOrActivePartnership}
                confirm="Yes"
                cancel="No"
                title={`Do you really want to ${
                    isPartnershipStatusActive ? 'archive' : 'activate'
                } this partnership ?`}
            />
        </>
    );
};

export default PartnershipsListActionsButton;
