import React, { forwardRef, useEffect, useState } from 'react';
import { useNotify, useRecordContext } from 'react-admin';
import { useField } from 'react-final-form';
import { ReactSortable } from 'react-sortablejs';
import { isEqual } from 'lodash';
import BundleAutoComplete, {
    Bundle,
    OrderedBundle,
} from './BundleAutoComplete';
import {
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    List,
    Chip,
    ListItemIcon,
    makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';

const isDuplicate = (newBundle: Bundle, bundles: OrderedBundle[]): boolean => {
    const duplicated = bundles.find((bundle) => {
        if (bundle.id && newBundle.id && bundle.id === newBundle.id) {
            return true;
        }

        return (
            newBundle.code === 'CUS' &&
            bundle.code === 'CUS' &&
            bundle.name === newBundle.name
        );
    });

    return !!duplicated;
};

const CustomComponent = forwardRef<any, any>((props, ref) => {
    return (
        <List dense ref={ref}>
            {props.children}
        </List>
    );
});

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    avatar: {
        minWidth: 0,
        marginRight: '5px',
    },
}));

const BundleInput = ({
    name = 'bundles',
    label = 'bundles',
    ...props
}: any) => {
    const classes = useStyles();
    const { source } = props;
    const notify = useNotify();

    const record = useRecordContext(props);

    const [bundles, setBundles] = useState<OrderedBundle[]>(
        record[source].sort((a: OrderedBundle, b: OrderedBundle) =>
            a.order > b.order ? 1 : -1
        )
    );

    const {
        input: { onChange },
    } = useField(name);

    const addBundle = (newBundle: Bundle) => {
        if (isDuplicate(newBundle, bundles)) {
            notify('This bundle already exists', 'warning');

            return;
        }
        setBundles((oldBundles: OrderedBundle[]) => [
            ...oldBundles,
            { ...newBundle, order: oldBundles.length },
        ]);
    };

    const removeBundle = (bundleIndex: number) => () => {
        const newBundles = [...(bundles || [])];
        newBundles.splice(bundleIndex, 1);
        setBundles(newBundles);
    };

    useEffect(() => {
        if (!isEqual(record[source], bundles)) {
            onChange(bundles);
        }
    }, [bundles, onChange, record, source]);

    return (
        <div>
            {bundles.length === 0 && (
                <Alert severity="info">No Bundle Yet!</Alert>
            )}
            <ReactSortable
                tag={CustomComponent}
                list={bundles}
                setList={setBundles}
            >
                {bundles.map((bundle: Bundle, i: number) => (
                    <ListItem key={i}>
                        <ListItemIcon className={classes.avatar}>
                            <Chip label={i + 1} />
                        </ListItemIcon>
                        <ListItemIcon className={classes.avatar}>
                            <Chip label={bundle.code} />
                        </ListItemIcon>
                        <ListItemText primary={bundle.name} />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={removeBundle(i)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </ReactSortable>
            <BundleAutoComplete onNewValue={addBundle} />
        </div>
    );
};

export default BundleInput;
