import ProjectCreate from './ProjectCreate';
import ProjectsList from './ProjectsList';
import GavelIcon from '@material-ui/icons/Gavel';
import ProjectShow from './show/ProjectShow';

export default {
    create: ProjectCreate,
    list: ProjectsList,
    show: ProjectShow,
    icon: GavelIcon,
};
