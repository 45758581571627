import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import { createTheme } from '@material-ui/core/styles';
import typography from './utils/typography';
import palette from './utils/palette';
import theme, { breakpoint } from './utils/theme';

const muiTheme = createTheme(
    merge({}, defaultTheme, {
        palette,
        typography: {
            fontFamily: typography.fontFamily,
            fontSize: typography.fontSize,
        },
        overrides: {
            // template
            MuiAppBar: {
                colorSecondary: {
                    background:
                        'linear-gradient(45deg, #158C80 20%, #4C3E72 90%)',
                },
            },
            MuiTooltip: {
                tooltip: {
                    padding: '5px 10px',
                    borderRadius: '2px',
                    backgroundColor: `${theme.colors.graydarker}`,
                    fontSize: '12px',
                },
            },
            MuiMenuItem: {
                root: {
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    wordBreak: 'breakWord',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                },
            },
            RaSidebar: {
                drawerPaper: {
                    width: 'fit-content',
                    color: palette.primary.main,
                    height: '100%',
                    boxShadow:
                        '2px 0px 10px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)',
                    backgroundColor: 'white',
                },
            },
            RaMenu: {
                open: {
                    width: '100%',
                },
            },
            RaMenuItemLink: {
                active: {
                    borderLeftStyle: 'none',
                    borderRightStyle: 'solid',
                    color: palette.primary.main,
                    fontWeight: typography.fontWeightBold,
                    backgroundColor: theme.colors.graylighter,
                },
            },
            RaLayout: {
                content: {
                    'overflow-y': 'auto',
                    width: '0px',
                    backgroundColor: 'white',
                    marginLeft: '8px',

                    [`@media (min-width: ${breakpoint.mini}px)`]: {
                        padding: '8px 8px 8px 5px',
                    },
                },
            },
            RaList: {
                main: {
                    overflow: 'auto',
                },
            },
            RaToolbar: {
                desktopToolbar: {
                    marginTop: 0,
                },
                toolbar: {
                    backgroundColor: 'white',
                },
            },
            RaTreeMenu: {
                open: {
                    width: '300px',
                },
                closed: {
                    width: '55px',
                },
            },
            MuiToolbar: {
                gutters: {
                    '@media (min-width: 600px)': {
                        padding: 0,
                    },
                },
            },
            MuiCardContent: {
                root: {
                    '&:last-child': {
                        paddingBottom: 0,
                    },
                    padding: 0,
                },
            },
            RaCardContentInner: {
                root: {
                    '&:first-child': {
                        paddingTop: 0,
                    },
                },
            },
            MuiTab: {
                wrapper: {
                    flexDirection: 'row',
                },
                labelIcon: {
                    '& > .MuiTab-wrapper > *:first-child': {
                        marginBottom: 0,
                        marginRight: 6,
                    },
                },
            },

            // list
            RaDatagrid: {
                row: {
                    border: `solid ${theme.colors.graylight} 0.1px`,
                },
                rowCell: {
                    border: 'none',
                    height: '30px',
                    maxWidth: 'fit-content',
                    whiteSpace: 'nowrap',
                },
                headerCell: {
                    border: 'none',
                    backgroundColor: theme.colors.graylighter,
                    color: theme.colors.graydark,
                    height: '32px',
                    maxWidth: 'fit-content',
                    whiteSpace: 'nowrap',
                },
            },
            RaBulkActionsToolbar: {
                toolbar: {
                    height: '43px',
                    minHeight: '43px',
                },
            },
            MuiTableCell: {
                paddingCheckbox: {
                    border: 'none',
                },
            },
            MuiPaper: {
                elevation1: {
                    boxShadow: 'none',
                },
                root: {
                    color: palette.primary.main,
                },
            },
            MuiTablePagination: {
                toolbar: {
                    right: '5px',
                },
            },
        },
    })
);

export default muiTheme;
