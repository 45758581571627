import React, { useMemo } from 'react';
import ProjectParticipationItem from '../../../interfaces/ProjectParticipationItem';
import { OrderedBundle } from '../bundles/BundleAutoComplete';
import ParticipationLine from './ParticipationLine';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, List, createStyles } from '@material-ui/core';

type ParticipationListByBundleProps = {
    participations: ProjectParticipationItem[];
    bundle: OrderedBundle;
};

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    })
);

type RecursiveLineProps = {
    participations: ProjectParticipationItem[];
    participation: ProjectParticipationItem;
};

const RecursiveLine = ({
    participation,
    participations,
}: RecursiveLineProps) => {
    const classes = useStyles();
    const children = useMemo(
        () => participations.filter((p) => p.parentId === participation.id),
        [participation, participations]
    );

    return (
        <>
            <ParticipationLine participation={participation} />
            {children.length ? (
                <List component="div" disablePadding className={classes.nested}>
                    {children.map((child) => (
                        <RecursiveLine
                            participation={child}
                            participations={participations}
                            key={child.id}
                        />
                    ))}
                </List>
            ) : null}
        </>
    );
};

const ParticipationListByBundle = ({
    participations,
    bundle,
}: ParticipationListByBundleProps) => {
    const participationsOfCurrentBundle = useMemo(() => {
        return participations
            .filter((participation) => participation?.bundle?.id === bundle.id)
            .sort((a, b) =>
                a.site.company.name > b.site.company.name ? 1 : -1
            );
    }, [participations, bundle]);

    const participationsWithoutParents = useMemo(
        () => participationsOfCurrentBundle.filter((p) => !p.parentId),
        [participationsOfCurrentBundle]
    );

    if (participationsOfCurrentBundle.length === 0) {
        return <Divider />;
    }

    return (
        <>
            <Divider />
            {participationsWithoutParents.map((p) => (
                <RecursiveLine
                    key={p.id}
                    participation={p}
                    participations={participationsOfCurrentBundle}
                />
            ))}
        </>
    );
};

export default ParticipationListByBundle;
