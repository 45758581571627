import WorkingTimeSlot, {
    generateEmptyTimeSlotValue,
    TimeSlotValue,
} from './WorkingTimeSlot';
import React from 'react';
import BusinessHoursDay from './BusinessHoursDay';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Tooltip } from '@material-ui/core';

export type BusinessDayValue = TimeSlotValue[];

const useStyles = makeStyles({
    titleContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',
    },
    title: {
        'text-transform': 'capitalize',
        opacity: (disabled) => (disabled ? 0.5 : 1),
    },
    root: {
        marginBottom: 12,
        padding: 5,
    },
});

type WorkingDayProps = {
    day: BusinessHoursDay;
    value?: BusinessDayValue;
    onChange?: (value: BusinessDayValue) => void;
    copyPrevious?: () => void;
    disabled?: boolean;
    displayEmpty: boolean;
};

const WorkingDay = ({
    displayEmpty,
    day,
    value: timeSlots,
    onChange,
    disabled,
    copyPrevious,
}: WorkingDayProps) => {
    const classes = useStyles(disabled);
    const addTime = () => {
        onChange?.([...(timeSlots || []), generateEmptyTimeSlotValue()]);
    };

    const onTimeSlotChanged =
        (slotIndex: number) => (timeSlotValue: TimeSlotValue) => {
            const newTimeSlots = [...(timeSlots || [])];
            newTimeSlots[slotIndex] = timeSlotValue;
            onChange?.(newTimeSlots);
        };

    const removeTimeSlot = (slotIndex: number) => () => {
        const newTimeSlots = [...(timeSlots || [])];
        newTimeSlots.splice(slotIndex, 1);
        onChange?.(newTimeSlots);
    };

    if (!displayEmpty && (!timeSlots || timeSlots.length === 0)) {
        return null;
    }

    return (
        <Card elevation={3} className={classes.root}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    {day}: {timeSlots?.length === 0 && <span>Off</span>}
                </div>
                <div>
                    {copyPrevious && (
                        <Tooltip title="Copy previous day" aria-label="copy">
                            <span>
                                <IconButton
                                    aria-label="copy"
                                    onClick={copyPrevious}
                                    color="primary"
                                    size="small"
                                    disabled={disabled}
                                >
                                    <FileCopyIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    <IconButton
                        aria-label="add"
                        onClick={addTime}
                        color="primary"
                        size="small"
                        disabled={disabled}
                    >
                        <AddIcon />
                    </IconButton>
                </div>
            </div>
            {timeSlots?.map((timeSlot, index) => (
                <WorkingTimeSlot
                    key={index}
                    timeSlot={timeSlot}
                    onChange={onTimeSlotChanged(index)}
                    onDelete={removeTimeSlot(index)}
                    disabled={disabled}
                />
            ))}
        </Card>
    );
};

export default WorkingDay;
