import React from 'react';
import ResourceInputProps from '../utils/interfaces/ResourceInputProps';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { ResourceName } from '../ResourceName';

const filterToQuery = (name: string) => ({ name });

const ProjectsInput: React.FC<ResourceInputProps> = (props) => (
    <ReferenceInput
        label={props.label}
        source={props.source}
        reference={ResourceName.PROJECTS}
        filter={props.filter}
        filterToQuery={filterToQuery}
        perPage={25}
        allowEmpty={props.allowEmpty}
        fullWidth={props.fullWidth}
        isRequired={props.isRequired}
    >
        <AutocompleteInput
            options={{ disabled: !!props.disabled }}
            validate={props.validate}
            matchSuggestion={props.matchSuggestion}
        />
    </ReferenceInput>
);

export default ProjectsInput;
