import flatten from 'flat';

export const toFormData = (data: Record<string, any>): FormData => {
    const formData = new FormData();

    Object.entries(flatten(data)).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            formData.append(`${key}[]`, '');
        } else {
            if (value !== undefined) {
                formData.append(key, value as string | Blob);
            }
        }
    });

    return formData;
};
