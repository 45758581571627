enum ProjectParticipationRole {
    PROJECT_OWNERSHIP = 'PROJECT_OWNERSHIP', // maître d'ouvrage
    PROJECT_MANAGER = 'PROJECT_MANAGER', // maître d’œuvre, bureau étude
    PROJECT_ASSISTANT = 'PROJECT_ASSISTANT', // Assistant Maitre d’ouvrage, Contrôle, Coordinateur SPS
    PLANNER = 'PLANNER', // aménageur
    CONTRACTANTS = 'CONTRACTANTS',
    GENERAL_COMPANY = 'GENERAL_COMPANY', // entreprise générale
    CONTRACTOR = 'CONTRACTOR', // entreprise de travaux et prestations
    SUPPLIER = 'SUPPLIER', // fournisseur
    STATE_BODY = 'STATE_BODY', // state body
    PARTNER = 'PARTNER', // Investisseur, Partenaire ou Sponsor
    PROMOTER = 'PROMOTER',
}

export const orderedRoles: ProjectParticipationRole[] = [
    ProjectParticipationRole.PROJECT_OWNERSHIP,
    ProjectParticipationRole.PROMOTER,
    ProjectParticipationRole.CONTRACTANTS,
    ProjectParticipationRole.PLANNER,
    ProjectParticipationRole.PROJECT_MANAGER,
    ProjectParticipationRole.PROJECT_ASSISTANT,
    ProjectParticipationRole.GENERAL_COMPANY,
    ProjectParticipationRole.CONTRACTOR,
    ProjectParticipationRole.SUPPLIER,
    ProjectParticipationRole.PARTNER,
    ProjectParticipationRole.STATE_BODY,
];

export default ProjectParticipationRole;
