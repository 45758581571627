import { AuthProvider } from 'ra-core';
import { apiUrl } from './dataProvider';

const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        const params = new URLSearchParams({
            client_id: 'react-app',
            grant_type: 'password',
            username,
            password,
        });

        const request = new Request(`${apiUrl}/auth`, {
            method: 'POST',
            body: params,
            headers: new Headers({
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8',
            }),
        });

        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then((resp) => {
                // set more info token
                /*        const decodeToken = jwt_decode(resp.access_token);
        localStorage.setItem('user_id', decodeToken.user_id);*/
                localStorage.setItem('token', resp.access_token);

                return Promise.resolve();
            });
    },

    logout: () => {
        localStorage.removeItem('token');

        return Promise.resolve();
    },
    checkError: ({ status }) => {
        return status === 401 || status === 403
            ? Promise.reject()
            : Promise.resolve();
    },
    checkAuth: async () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => {
        return Promise.reject('Unknown method');
    },
};

export default authProvider;
