import React, { useState } from 'react';
import {
    Button,
    Confirm,
    useDataProvider,
    useNotify,
    useRefresh,
} from 'react-admin';

const AnonymizeUserButton = (props: {
    record?: { id: string; lastName: string; firstName: string };
}) => {
    const { record: user } = props;
    const [open, setIsOpen] = useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    if (!user) {
        return null;
    }

    const handleUpdate = () => {
        dataProvider
            .anonymizeUser(user.id)
            .then(() => {
                notify('User anonymized', 'success');
                refresh();
            })
            .catch(() => {
                notify('Error: user was not anonymized', 'warning');
            });
    };

    return (
        <>
            <Confirm
                isOpen={open}
                title="Anonymize User"
                content={`User ${user.firstName} ${user.lastName} will be anonymize`}
                onClose={() => setIsOpen(false)}
                onConfirm={() => handleUpdate()}
            />
            <Button label="Anonymize" onClick={() => setIsOpen(true)}></Button>
        </>
    );
};

export default AnonymizeUserButton;
