export enum ResourceName {
    WORKPACKAGES = 'bundles',
    COMPANIES = 'companies',
    CONTRACTS = 'contracts',
    FORMS = 'prequalifications',
    INVITATION = 'invitations-parent',
    MOVEMENTS = 'movements',
    ORGANISATIONS = 'organisations',
    PREQUALIFICATIONS = 'prequalifications-parent',
    PARTNERSHIPS = 'partnerships',
    PREQUALIFICATIONS_INVITATION = 'partnership-invitations/sent',
    PROJECTS = 'projects',
    PROJECTS_BUILDING_TAGS = 'projects/building-tags',
    PROJECTS_SITE_TAGS = 'projects/site-tags',
    SITES = 'sites',
    SITE_JOIN = 'v1/invitations/site-join',
    USERS = 'v1/users',
    USER_PROVISIONING = 'user-provisioning',
    USER_PROVISIONING_CSV = 'user-provisioning/csv',
    USER_PROVISIONG_ERROR = 'user-provisioning-error',
    PROJECTS_SEARCH = '/projects/search',
    RECOMMENDATIONS = 'workpackage-recommendations/recommendations',
    REGISTRATION_COUNTRIES = 'registration-countries',
    REFERENCE_TYPES = 'reference-types',
    REFERENCE_ORDERS = 'reference-orders',
    REFERENCES = 'references',
}
