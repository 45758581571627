import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { ResourceName } from '../ResourceName';

interface Props {
    disabled?: boolean;
    filter?: Record<string, unknown>;
    label: string;
    fullWidth?: boolean;
}

const optionText = (record: any) =>
    record?.id ? `${record.mainReference.value}: ${record.name}` : '';

const SourceCompanyInput: React.FC<Props> = (props) => (
    <ReferenceInput
        label={props.label}
        source="sourceCompanyId"
        reference={ResourceName.COMPANIES}
        filter={props.filter}
        filterToQuery={(searchText: string) => ({ search: searchText })}
        perPage={25}
        allowEmpty
        fullWidth={props.fullWidth}
    >
        <AutocompleteInput
            optionText={optionText}
            options={{ disabled: !!props.disabled }}
        />
    </ReferenceInput>
);

export default SourceCompanyInput;
