import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import classnames from 'classnames';
import { useDatagridStyles } from 'react-admin';

type RecordType = Record<string, any>;

type MyDatagridRowProps = {
    source: string;
    record?: RecordType;
    children: any;
    id?: string;
    className?: string;
};

const MyDatagrid = (props: MyDatagridRowProps) => {
    const classes = useDatagridStyles(props);
    const { source, record, children, id, className } = props;
    if (!record) {
        return null;
    }
    const data = record[source];
    if (!data) {
        return null;
    }

    if (data.length === 0) {
        return (
            <Card elevation={0}>
                <CardContent>
                    These aren't the {source} you are looking for
                </CardContent>
            </Card>
        );
    }

    return (
        <Table className={classnames(classes.table, className)}>
            <TableHead className={classes.thead}>
                <TableRow
                    className={classnames(classes.row, classes.headerRow)}
                >
                    {React.Children.map(children, (field) => (
                        <TableCell
                            key={`head-${id}-${field.props.source}`}
                            padding="none"
                            className={classnames(
                                classes.headerCell,
                                classes.expandHeader
                            )}
                        >
                            {field.props.label || field.props.source || ''}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody className={classes.tbody}>
                {data.map((elem: RecordType) => {
                    return (
                        <TableRow key={elem.id} className={classes.row}>
                            {React.Children.map(children, (field) => (
                                <TableCell
                                    key={`${id}-${field.props.source}`}
                                    padding="none"
                                    className={classes.rowCell}
                                >
                                    {React.cloneElement(field, {
                                        record: elem,
                                        recordparent: record,
                                    })}
                                </TableCell>
                            ))}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default MyDatagrid;
