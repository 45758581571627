import { createRef, useCallback, useEffect, useState } from 'react';
import useLocationToCoordinates from './useLocationToCoordinates';
import useExplore, { ExploreCoordinate, ExploreProject } from './useExplore';
import ArrayOption from '../../interfaces/ArrayOption';
import { uniq } from 'lodash';

interface useExploreReturn {
    exploreItem: ExploreProject[];
    loading: boolean;
    onChangeSearch: (newSearch: string) => void;
    coordinates: ExploreCoordinate;
    onCoordinateChange: (coordinates: ExploreCoordinate) => void;
    setProjectOwner: (projectOwner: string) => void;
    projectOwnerOptions: ArrayOption[];
}

const defaultCoordinates = {
    longitude: 4.8559034,
    latitude: 45.7613283,
    radius: 45,
};

const getProjectOwnerOptions = (
    exploreProjects: ExploreProject[],
    projectOwner: string
): ArrayOption[] => {
    const options = uniq(exploreProjects.flatMap((p) => p.projectOwner));
    if (projectOwner !== '' && !options.includes(projectOwner)) {
        options.push(projectOwner);
    }

    return options.sort().map((option) => {
        return { id: option, name: option };
    });
};

const useOpenStreetMapCoordinates = (): useExploreReturn => {
    const locationToCoordinates = useLocationToCoordinates();
    const getExploreProjects = useExplore();
    const [projectOwner, setProjectOwner] = useState('');
    const [projectOwnerOptions, setProjectOwnerOptions] = useState<
        ArrayOption[]
    >([]);
    const [exploreProject, setExploreProject] = useState<ExploreProject[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [coordinates, setCoordinates] =
        useState<ExploreCoordinate>(defaultCoordinates);

    useEffect(() => {
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition((e) =>
                setCoordinates({
                    longitude: e.coords.longitude,
                    latitude: e.coords.latitude,
                    radius: defaultCoordinates.radius,
                })
            );
        }
    }, []);

    const triggerExploreFetch = useCallback(
        (coordinates: ExploreCoordinate) => {
            setLoading(true);
            getExploreProjects(coordinates)
                .then((exploreProjects) => {
                    const projects = exploreProjects.map((exploreProject) => ({
                        ...exploreProject,
                        ref: createRef(),
                    }));
                    setProjectOwnerOptions(
                        getProjectOwnerOptions(exploreProjects, projectOwner)
                    );
                    const filteredProject = projects.filter(
                        (data) =>
                            (projectOwner &&
                                data.projectOwner.includes(projectOwner)) ||
                            !projectOwner
                    );
                    setExploreProject(filteredProject);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [projectOwner]
    );

    const onCoordinateChange = (newCoordinates: ExploreCoordinate) => {
        triggerExploreFetch(newCoordinates);
    };

    const onChangeSearch = (newSearch: string) => {
        if (!newSearch) {
            return;
        }

        setLoading(true);
        locationToCoordinates(newSearch).then(
            (newCoordinates: ExploreCoordinate) => {
                setCoordinates(newCoordinates);
            }
        );
    };

    useEffect(() => {
        triggerExploreFetch(coordinates);
    }, [coordinates, triggerExploreFetch]);

    return {
        exploreItem: exploreProject,
        loading,
        onChangeSearch,
        coordinates,
        onCoordinateChange,
        setProjectOwner,
        projectOwnerOptions,
    };
};

export default useOpenStreetMapCoordinates;
