import { Bundle } from '../../Projects/show/bundles/BundleAutoComplete';
import { useEffect, useMemo, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { ResourceName } from '../../ResourceName';

type Distance = {
    value: string;
    workpackage: Bundle;
};

type Recommendation = {
    id: string;
    workpackage: {
        id: string;
        preprocessName: string[];
    };
    count: number;
    distances: Distance[];
    error?: string;
};

type RecommendationMetadata = {
    preprocessName?: string[];
    count?: number;
    distances?: Distance[];
    error?: string;
};

type BundleWithRecommendations = Bundle & RecommendationMetadata;

const useRecommendations = (
    ids: string[]
): {
    isLoading: boolean;
    data: any;
} => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Recommendation[]>([]);
    const { getList } = useDataProvider();

    useEffect(() => {
        if (!ids?.length) {
            return;
        }
        setLoading(true);
        getList(ResourceName.RECOMMENDATIONS, {
            pagination: {
                page: 1,
                perPage: ids.length,
            },
            filter: { ids },
        })
            .then((result: { data: Recommendation[] }) => {
                setData(result.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [ids]);

    return { isLoading, data };
};

const useDataWithRecommendations = (
    ids: string[],
    originalData: Record<string, Bundle>
): { data: Record<string, BundleWithRecommendations>; isLoading: boolean } => {
    const { data: dataFromRecommendation, isLoading } = useRecommendations(ids);

    const data = useMemo(() => {
        if (!ids.length || isLoading) {
            return {};
        }

        return Object.entries(originalData ?? {}).reduce(
            (acc, [key, value]) => {
                const matchingRecommendation = dataFromRecommendation.find(
                    (reco: Recommendation) => reco.id === value.id
                );

                const metaData: RecommendationMetadata = {
                    preprocessName:
                        matchingRecommendation?.workpackage.preprocessName,
                    count: matchingRecommendation?.count ?? 0,
                    distances: matchingRecommendation?.distances ?? [],
                    error: matchingRecommendation?.error,
                };

                return { ...acc, [key]: { ...value, ...metaData } };
            },
            {} as Record<string, BundleWithRecommendations>
        );
    }, [originalData, dataFromRecommendation, isLoading]);

    return {
        data,
        isLoading,
    };
};

export default useDataWithRecommendations;
