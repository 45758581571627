import React, { useEffect, useState } from 'react';
import { apiUrl, httpClientJson } from '../dataProvider';

interface Options {
    [k: string]: unknown;
}

const useHttpClientJson = <T>(
    url: string,
    options: Options = {},
    dependencies: React.DependencyList = []
): { data: T | undefined; error: Error | undefined; loading: boolean } => {
    const [data, setData] = useState<T>();
    const [error, setError] = useState<Error>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        httpClientJson(`${apiUrl}/${url}`, options)
            .then(({ json }: { json: T }) => {
                setData(json);
            })
            .catch((error: Error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, dependencies);

    return { data, error, loading };
};

export default useHttpClientJson;
