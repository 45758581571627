import { TopToolbar, CreateButton, Button, useNotify } from 'react-admin';
import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import { apiUrl, httpClient } from '../dataProvider';
import { Link } from 'react-router-dom';
import { ResourceName } from '../ResourceName';
import SearchIcon from '@material-ui/icons/Search';

const ListActions = () => {
    const notify = useNotify();

    const regenerateTotems = () => {
        httpClient(`${apiUrl}/projects/totems`, {
            method: 'POST',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('');
                }
                notify(`Totems will soon be regenerated`, 'success');
            })
            .catch(() => {
                notify(`Totems could not be regenerated`, 'warning');
            });
    };

    return (
        <TopToolbar>
            <Button onClick={regenerateTotems} label="Regenerate All Totems">
                <RefreshIcon />
            </Button>
            <CreateButton />
            <Button
                label="Search"
                component={Link}
                to={{ pathname: ResourceName.PROJECTS_SEARCH }}
            >
                <SearchIcon />
            </Button>
        </TopToolbar>
    );
};

export default ListActions;
