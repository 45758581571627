import React from 'react';
import { Show, TabbedShowLayout } from 'react-admin';
import ProjectSummaryTab from './ProjectSummaryTab';
import ProjectBundlesTab from './ProjectBundlesTab';
import ProjectParticipationsTab from './ProjectParticipationsTab';
import ProjectQrTab from './ProjectQrTab';
import ProjectAdminTab from './administration/ProjectAdminTab';
import ParticipationInvitationTab from './ParticipationInvitationTab';
import ParticipationRequestTab from './ParticipationsRequestTab';

const ProjectTitle = ({ record }: { record?: { name?: string } }) => {
    return <span>Project {record?.name ?? 'view'}</span>;
};

const ProjectShow = (props: any) => (
    <Show title={<ProjectTitle />} {...props}>
        <TabbedShowLayout>
            <ProjectSummaryTab {...props} />
            <ProjectBundlesTab {...props} />
            <ProjectParticipationsTab />
            <ProjectAdminTab />
            <ParticipationInvitationTab />
            <ParticipationRequestTab />
            <ProjectQrTab />
        </TabbedShowLayout>
    </Show>
);

export default ProjectShow;
