import React from 'react';
import { SelectInput, Tab, TabProps, TextField, TextInput } from 'react-admin';
import SubResource from '../../utils/SubResource';
import ConcatField from '../../ui/atoms/fields/ConcatField';
import { ResourceName } from '../../ResourceName';
import UsersInput from './UsersInput';

export enum MembershipStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

const SiteMembersTab = ({ ...props }: typeof TabProps): React.ReactElement => {
    const { resource, record } = props;

    return (
        <Tab {...props} label="Members">
            <SubResource
                target="memberships"
                name="Members"
                rowClick={(id: string, basePath: string, record: any) => {
                    return `/${ResourceName.USERS}/${record.userId}/show`;
                }}
                resource={resource}
                record={record}
                deleteButton
                disabledDelete={(_site, membership) => {
                    return membership.status === MembershipStatus.INACTIVE;
                }}
                deleteIdentificator="userId"
                addInput={<UsersInput fullWidth label="User" source="userId" />}
                filters={[
                    <TextInput
                        label="Search"
                        source="search"
                        size="small"
                        alwaysOn
                        resettable
                    />,
                    <SelectInput
                        source="status"
                        label="Status"
                        resettable
                        alwaysOn
                        choices={Object.values(MembershipStatus).map(
                            (status) => ({
                                id: status,
                                name: status,
                            })
                        )}
                    />,
                ]}
            >
                <TextField sortable={false} source="user.id" label="Id" />
                <TextField sortable={false} source="user.email" label="Email" />
                <ConcatField
                    sortable={false}
                    sources={['user.firstName', 'user.lastName']}
                    label="Fullname"
                />
                <TextField
                    sortable={false}
                    source="status"
                    label="Membership status"
                />
                <TextField
                    sortable={false}
                    source="user.status"
                    label="User status"
                />
            </SubResource>
        </Tab>
    );
};

export default SiteMembersTab;
